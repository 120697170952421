import React, { Fragment, FC, useState } from "react"
import "../AdminConsole.scss"
import { Formik, FieldArray } from "formik"
import useAdminService from "Services/AdminService"

interface IProps {
	context: any
	show: any
	disable: any
	row: any
}

const RateSheetForm: FC<IProps> = ({ context, show, disable, row }) => {
	const [saving, setSaving] = useState<boolean>(false)

	const adminService = useAdminService()

	return (
		<Formik
			initialValues={{
				id: row ? row.id : undefined,
				order: row ? row.order : undefined,
				header: row ? row.header : "",
				lines: row.lines.length ? row.lines : [""],
			}}
			enableReinitialize
			onSubmit={async (values) => {
				setSaving(true)
				const lines = values.lines.filter((l) => l !== "")
				const v = {
					id: values.id,
					order: values.order,
					header: values.header,
					lines: lines,
				}
				adminService.updateRateSheetContent(v).then(() => {
					context.fetchAdminTableFn(context.tableName)
					show(!show)
				})
				disable(!disable)
				setSaving(false)
			}}
			onReset={() => {
				show(!show)
				disable(!disable)
			}}
		>
			{(props) => {
				const { values, handleChange, handleBlur, handleSubmit, handleReset } = props
				return (
					<Fragment>
						<fieldset disabled={saving} className="rate-content-form">
							<form onSubmit={handleSubmit} onReset={handleReset}>
								<div className="form-col">
									<div className="form-group col-md-4 header-group">
										<label>Header</label>
										<input
											type="text"
											name="header"
											className="form-control"
											disabled={true}
											value={values.header}
										/>
									</div>
									<div className="form-group">
										<label>Lines &nbsp;</label>
										<FieldArray
											name="lines"
											render={({ remove, push }) => (
												<div>
													{values.lines.length > 0 &&
														values.lines.map((l, index) => (
															<div key={index}>
																<div className="input-group line-section">
																	<input
																		type="text"
																		name={`lines.${index}`}
																		maxLength={250}
																		className="form-control"
																		placeholder={`Line ${index + 1}`}
																		value={values.lines[index]}
																		onChange={handleChange}
																		onBlur={handleBlur}
																	/>
																	<div className="input-group-append">
																		<span className="input-group-text">
																			<i className="fa fa-minus" onClick={() => remove(index)}></i>
																		</span>
																	</div>
																</div>
																<small className="char-counter">
																	({values.lines[index].length}/250)
																</small>
															</div>
														))}
													<button type="button" onClick={() => push("")} className="new-line-btn">
														<i className="fa fa-plus"></i>
													</button>
												</div>
											)}
										/>
									</div>
								</div>
								<div className="form-action-btns">
									<button type="submit" className="btn btn-success">
										Update
									</button>
									<button type="button" className="btn btn-danger" onClick={handleReset}>
										CANCEL
									</button>
								</div>
							</form>
						</fieldset>
					</Fragment>
				)
			}}
		</Formik>
	)
}
export default RateSheetForm
