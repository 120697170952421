import React, { Fragment, useState } from "react"
import { trackPromise } from "react-promise-tracker"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import useBuyRateService from "Services/BuyRateService"
import DragDrop from "Shared/DragDrop/DragDrop"

function RateImporter({ open, dispatcher }) {
	const [importFile, setImportFile] = useState({ file: null, filename: null })
	const buyRateService = useBuyRateService()

	function handleFileChange(file, filename) {
		setImportFile({ file: file, filename: filename })
	}

	function runImport() {
		trackPromise(
			buyRateService.importDrayBuyRates(importFile).then(() => {
				dispatcher({ type: "CLOSE_RATE_IMPORTER" })
			}),
		)
	}

	return (
		<Fragment>
			<button
				type="button"
				className="btn btn-primary"
				onClick={() => dispatcher({ type: "IMPORT_RATES" })}
			>
				Import Excel File
			</button>
			<Modal isOpen={open} toggle={() => dispatcher({ type: "CLOSE_RATE_IMPORTER" })}>
				<ModalHeader toggle={() => dispatcher({ type: "CLOSE_RATE_IMPORTER" })}>
					Import Buy Rate(s)
				</ModalHeader>
				<ModalBody className="pt-0 pb-0">
					<DragDrop handleFileChange={handleFileChange} base64={false}></DragDrop>
				</ModalBody>
				<ModalFooter>
					<button
						type={"button"}
						className={"btn btn-secondary"}
						onClick={() => dispatcher({ type: "CLOSE_RATE_IMPORTER" })}
					>
						Cancel
					</button>
					<button
						type={"button"}
						className={"btn btn-primary"}
						onClick={() => runImport()}
						disabled={!importFile}
					>
						Import
					</button>
				</ModalFooter>
			</Modal>
		</Fragment>
	)
}

export default RateImporter
