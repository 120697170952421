import "./ControlTower.scss"
import React, { Fragment, useEffect, useState } from "react"
import Helmet from "react-helmet"
import CreateGRIGRD from "./GRI-GRD/CreateGRIGRD"
import CreatePSS from "./PSS/CreatePSS"
import CreateBAF from "./BAF/CreateBAF"
import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"
import { useAlert } from "react-alert"
import useControlTowerService from "Services/ControlTowerService"
import Tabs from "Shared/Tabs/Tabs"

function ControlTower({ setHeaderTitle }) {
	const alert = useAlert()
	const controlTowerService = useControlTowerService()

	const tabs = [{ label: "GRI/GRD" }, { label: "PSS" }, { label: "BAF" }]
	const [activeTab, setActiveTab] = useState(tabs[0])
	const [dropdowns, setDropdowns] = useState<any>([])

	useEffect(() => {
		setHeaderTitle("Control Tower")
		controlTowerService.getControlTowerDropdowns().then(
			(dropdowns) => {
				setDropdowns(dropdowns)
			},
			() => {
				alert.error("Failed to retrieve dropdowns", { timeout: 10000 })
			},
		)
		// eslint-disable-next-line
	}, [setHeaderTitle])

	function handleTabChange(tab) {
		setActiveTab(tab)
	}

	return (
		<Fragment>
			<Helmet>
				<title>Control Tower | UWL Pricing Tool</title>
			</Helmet>
			<LoadingIndicator />
			<div id="control-tower" className="card">
				<div id="control-body" className="card-body">
					<Tabs tabs={tabs} activeTab={activeTab} changeTab={handleTabChange} />
					{dropdowns.length !== 0 && (
						<div>
							{activeTab.label === "GRI/GRD" && (
								<section className="panel">
									<CreateGRIGRD dropdowns={dropdowns} />
								</section>
							)}
							{activeTab.label === "PSS" && (
								<section className="panel">
									<CreatePSS dropdowns={dropdowns} />
								</section>
							)}
							{activeTab.label === "BAF" && (
								<section className="panel">
									<CreateBAF dropdowns={dropdowns} />
								</section>
							)}
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default ControlTower
