import React, { useRef } from "react"
import uniqid from "uniqid"
import { useField } from "formik"

function NumberInput({ label, ...props }) {
	const id = useRef(uniqid())
	const [field, meta] = useField(props.name)
	return (
		<div className={"form-input-group"}>
			{
				<label
					htmlFor={id.current}
					className={`${meta.touched && meta.error ? "has-error" : null}`}
				>
					{label}
				</label>
			}
			{label !== "Fuel Surcharge" ? (
				<div className={"input-group"}>
					<div className="input-group-prepend">
						<div className="input-group-text">$</div>
					</div>
					<input
						id={id.current}
						className={`form-control ${meta.touched && meta.error ? "has-error" : null}`}
						{...field}
						{...props}
					/>
				</div>
			) : (
				<div className={"input-group"}>
					<input
						id={id.current}
						className={`form-control ${meta.touched && meta.error ? "has-error" : null}`}
						{...field}
						{...props}
					/>
					<div className="input-group-append">
						<div className="input-group-text">%</div>
					</div>
				</div>
			)}
			{meta.touched && meta.error && <div className={"input-errors"}></div>}
		</div>
	)
}

export default NumberInput
