import { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { dateFromString, formatDateString } from "Utilities/DateUtils"
import RatePusher from "./BuyRates/RatePusher"
import { OceanRateRowType, OceanRateChangeLog } from "Types/OceanRateTypes"

function customSort(a, b, id) {
	let aValue = a.values[id]
	let bValue = b.values[id]
	if (aValue) aValue = aValue.replace(/[$,]/g, "")
	if (bValue) bValue = bValue.replace(/[$,]/g, "")
	a = aValue === null || aValue === undefined || aValue === "" ? -Infinity : aValue
	b = bValue === null || bValue === undefined || bValue === "" ? -Infinity : bValue
	a = parseInt(a)
	b = parseInt(b)
	if (a > b) return 1
	if (a < b) return -1
	return 0
}

function dateSort(format: "ISO" | "locale") {
	return function dateSort(a, b, id) {
		let valueA: string
		let valueB: string
		if (a?.values) {
			valueA = a.values[id]
			valueB = b.values[id]
		} else {
			valueA = a.getValue(id)
			valueB = b.getValue(id)
		}
		const aDate = dateFromString(valueA, format)
		const bDate = dateFromString(valueB, format)
		if (aDate > bDate) return 1
		if (aDate < bDate) return -1
		return 0
	}
}

function ActionsCell(props) {
	const cellIndex = props.cell.row.index
	const data = props.data[cellIndex]

	const [showRatePusher, setShowRatePusher] = useState(false)

	function openRatePusher() {
		setShowRatePusher(true)
	}

	return (
		<div>
			<button type={"button"} className={"btn btn-link"} onClick={openRatePusher}>
				Push
			</button>
			{showRatePusher ? (
				<RatePusher type={"OCEAN"} rateData={data} close={() => setShowRatePusher(false)} />
			) : null}
		</div>
	)
}

export const buyRatesTableHeaders = () => {
	return [
		{
			Header: "Agent",
			accessor: "agent",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "POL",
			accessor: "pol",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "POD",
			accessor: "pod",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Destination",
			accessor: "destination",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Ocean Carrier",
			accessor: "carrier",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Routing",
			accessor: "routing",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "20'",
			accessor: "gp20",
			sortType: customSort,
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "40'",
			accessor: "gp40",
			sortType: customSort,
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "40'HC",
			accessor: "hq40",
			sortType: customSort,
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "45'",
			accessor: "hq45",
			sortType: customSort,
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "40'NOR",
			accessor: "nor40",
			sortType: customSort,
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Effective Date",
			accessor: "effectiveDate",
			sortType: dateSort("locale"),
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Expiring Date",
			accessor: "expiringDate",
			sortType: dateSort("locale"),
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Commodity",
			accessor: "commodity",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Remarks",
			accessor: "remark",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Cut Date",
			accessor: "cyClosing",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "ETD",
			accessor: "etd",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Actions",
			Cell: (props) => <ActionsCell {...props} />,
		},
	]
}

const oceanRateColumnHelper = createColumnHelper<OceanRateRowType>()

export const oceanRatesTableHeaders = () => {
	return [
		oceanRateColumnHelper.accessor("quoteNumber", {
			header: "Quote #",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("pol.name", {
			id: "pol",
			header: "Port of Load",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("pod.name", {
			id: "pod",
			header: "Port of Discharge",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("destinationCy.name", {
			id: "destinationCy",
			header: "Destination CY",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("destinationDoor", {
			id: "destinationDoor",
			header: "Destination Door",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("commodity", {
			header: "Commodity",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("type", {
			header: "Rate Type",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("agent", {
			header: "Agent",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("rate20", {
			header: "20'",
			cell: (props) => <CurrencyCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("rate40", {
			header: "40'",
			cell: (props) => <CurrencyCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("rate40hc", {
			header: "40'HC",
			cell: (props) => <CurrencyCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("rate45", {
			header: "45'",
			cell: (props) => <CurrencyCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("nor40", {
			header: "40'NOR",
			cell: (props) => <CurrencyCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("re40", {
			header: "40'RE",
			cell: (props) => <CurrencyCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("trade", {
			header: "Trade",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("serviceType", {
			header: "Service Type",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("routing", {
			header: "Routing",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("carrier", {
			header: "Carrier",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("incoTerm", {
			header: "Inco Term",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("effectiveDate", {
			header: "Eff. Date",
			sortingFn: dateSort("ISO"),
			cell: (props) => <DateCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("expirationDate", {
			header: "Exp. Date",
			sortingFn: dateSort("ISO"),
			cell: (props) => <DateCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("acceptedUser", {
			header: "Accepted User",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		oceanRateColumnHelper.accessor("acceptedDate", {
			header: "Accepted Date",
			sortingFn: dateSort("ISO"),
			cell: (props) => <DateCell value={props.getValue()} />,
		}),
	]
}

const pastOceanRateColumnHelper = createColumnHelper<OceanRateChangeLog>()
export const pastOceanRatesTableHeaders = () => {
	return [
		pastOceanRateColumnHelper.accessor("event_date", {
			header: "Date",
			sortingFn: dateSort("locale"),
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		pastOceanRateColumnHelper.accessor("username", {
			header: "User",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		pastOceanRateColumnHelper.accessor("event_type", {
			header: "Event Type",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		pastOceanRateColumnHelper.accessor("property_name", {
			header: "Field",
			cell: (props) => <DefaultCell value={props.getValue()} />,
		}),
		pastOceanRateColumnHelper.accessor("original_value", {
			header: "Original Value",
			cell: (props) => <ValueCell value={props.getValue()} row={props.row.original} />,
			enableColumnFilter: false,
		}),
		pastOceanRateColumnHelper.accessor("new_value", {
			header: "New Value",
			cell: (props) => <ValueCell value={props.getValue()} row={props.row.original} />,
			enableColumnFilter: false,
		}),
	]
}

export const LaneRateTableHeaders = () => {
	return [
		{
			Header: "Port of Load",
			accessor: "pol",
			Cell: (props) => <LocationCell {...props} />,
		},
		{
			Header: "Port of Discharge",
			accessor: "pod",
			Cell: (props) => <LocationCell {...props} />,
		},
		{
			Header: "Destination CY",
			accessor: "destinationCy",
			Cell: (props) => <LocationCell {...props} />,
		},
		{
			Header: "Rate Type",
			accessor: "type",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "20'",
			accessor: "rate20",
			Cell: (props) => <CurrencyCell {...props} />,
		},
		{
			Header: "40'",
			accessor: "rate40",
			Cell: (props) => <CurrencyCell {...props} />,
		},
		{
			Header: "40'HC",
			accessor: "rate40hc",
			Cell: (props) => <CurrencyCell {...props} />,
		},
		{
			Header: "45'",
			accessor: "rate45",
			Cell: (props) => <CurrencyCell {...props} />,
		},
		{
			Header: "Service Type",
			accessor: "serviceType",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Exp. Date",
			accessor: "expirationDate",
			sortType: dateSort("ISO"),
			Cell: (props) => <DateCell {...props} />,
		},
	]
}

export const marginTableHeaders = () => {
	return [
		{
			Header: "Buy Rates",
			columns: [
				{
					Header: "Carrier",
					accessor: "carrier",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "Service",
					accessor: "routing",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "T/T",
					accessor: "ttToPod",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "POD",
					accessor: "pod",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "Dest. CY",
					accessor: "destination",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "Commodity",
					accessor: "commodity",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "Eff Date",
					accessor: "effectiveDate",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "Exp Date",
					accessor: "expiringDate",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "20'",
					accessor: "gp20",
					sortType: customSort,
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "40'",
					accessor: "gp40",
					sortType: customSort,
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "40'HC",
					accessor: "hq40",
					sortType: customSort,
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "45'",
					accessor: "hq45",
					sortType: customSort,
					Cell: (props) => <DefaultCell {...props} />,
				},
			],
		},
		{
			Header: "Margins",
			columns: [
				{
					Header: "20'",
					accessor: "rate20",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "40'",
					accessor: "rate40",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "40'HC",
					accessor: "rate40hc",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "45'",
					accessor: "rate45",
					Cell: (props) => <DefaultCell {...props} />,
				},
			],
		},
		{
			Header: "Markup %",
			columns: [
				{
					Header: "20'",
					accessor: "markupRate20",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "40'",
					accessor: "markupRate40",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "40'HC",
					accessor: "markupRate40hc",
					Cell: (props) => <DefaultCell {...props} />,
				},
				{
					Header: "45'",
					accessor: "markupRate45",
					Cell: (props) => <DefaultCell {...props} />,
				},
			],
		},
	]
}

export function DefaultCell(props) {
	const stringValue = props.value ? String(props.value) : ""
	const displayValue = stringValue.length > 22 ? `${stringValue.slice(0, 22)}...` : stringValue
	return (
		<span title={stringValue} className="text-truncate">
			{displayValue}
		</span>
	)
}

export function CurrencyCell(props) {
	const stringValue = props.value ? String(props.value) : ""
	return <span>{"$" + stringValue}</span>
}

export function LocationCell(props) {
	const stringValue = props.value ? String(props.value.name) : ""
	return <span>{stringValue}</span>
}

export function DateCell(props) {
	const stringValue = props.value ? formatDateString(props.value) : ""
	return <span>{stringValue}</span>
}

export function ValueCell({ value, row }: { value: string; row: OceanRateChangeLog }) {
	if (["Port of Load", "Port of Discharge", "Destination CY"].includes(row.property_name)) {
		return LocationCell({ value: JSON.parse(value) })
	}
	return <span>{value}</span>
}

export function SetFieldName(value: string) {
	let stringValue = ""
	switch (value) {
		case "pol":
			stringValue = "Port of Load"
			break
		case "pod":
			stringValue = "Port of Discharge"
			break
		case "destination_cy":
			stringValue = "Destination CY"
			break
		case "commodity":
			stringValue = "Commodity"
			break
		case "effective_date":
			stringValue = "Eff. Date"
			break
		case "expiration_date":
			stringValue = "Exp. Date"
			break
		case "service_type":
			stringValue = "Service Type"
			break
		case "carrier":
			stringValue = "Carrier"
			break
		case "routing":
			stringValue = "Routing"
			break
		case "type":
			stringValue = "Rate Type"
			break
		case "rate_20":
			stringValue = "20'"
			break
		case "rate_40":
			stringValue = "40'"
			break
		case "rate_40hc":
			stringValue = "40'HC"
			break
		case "rate_45":
			stringValue = "45'"
			break
		case "nor40":
			stringValue = "40'NOR"
			break
		case "re40":
			stringValue = "40'RE"
			break
		default:
			stringValue = value
	}
	return stringValue
}
