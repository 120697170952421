import React, { Fragment } from "react"
import { useTable, useSortBy } from "react-table"
import { InputCell, ToggleCell } from "./AccessorialTableCell"

function TableView({ accessorials, updateAccessorial }) {
	const data = React.useMemo(() => accessorials, [accessorials])
	const columns = React.useMemo(
		() => [
			{
				Header: "id",
				accessor: "id",
				show: false,
			},
			{
				Header: "Visible",
				accessor: "isVisible",
				disableSortBy: true,
				Cell: (props) => <ToggleCell {...props} />,
			},
			{
				Header: "Charge Name",
				accessor: "name",
			},
			{
				Header: "Location",
				accessor: "location",
			},
			{
				Header: "Default Amount",
				accessor: "amount",
			},
			{
				Header: "Custom Price",
				accessor: "custom",
				Cell: (props) => <InputCell {...props} />,
			},
			{
				Header: "Per",
				accessor: "per",
			},
		],
		[],
	)

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data, initialState: { hiddenColumns: ["id"] } },
		useSortBy,
	)

	return (
		<table {...getTableProps()} className={"table table-bordered"}>
			<thead className={"thead-light"}>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())}>
								{column.render("Header")}
								<span>
									{column.isSorted ? (
										column.isSortedDesc ? (
											<Fragment>
												{" "}
												<i
													className="sort-icon fa fa-sort-amount-desc fa-md"
													aria-hidden="true"
												></i>
											</Fragment>
										) : (
											<Fragment>
												{" "}
												<i className="sort-icon fa fa-sort-amount-asc fa-md" aria-hidden="true"></i>
											</Fragment>
										)
									) : null}
								</span>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
					prepareRow(row)
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => {
								return (
									<td {...cell.getCellProps()}>
										{cell.render("Cell", {
											updateAccessorial: updateAccessorial,
										})}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}

export default TableView
