/* eslint-disable array-callback-return */
import "../ControlTower.scss"
import React, { Fragment, useEffect, useState } from "react"
import RateTable from "../RateTable/RateTable"
import ControlDropdowns from "../DropdownsSection/ControlDropdowns"
import useControlTowerService from "Services/ControlTowerService"
import { trackPromise } from "react-promise-tracker"

export interface IProps {
	dropdowns: any
}

const TableRows = [
	{ routing: "EC", rate20: null, rate40: null, rate40hc: null, rate45: null, listId: Date.now() },
	{ routing: "WC", rate20: null, rate40: null, rate40hc: null, rate45: null, listId: Date.now() },
	{ routing: "IPI", rate20: null, rate40: null, rate40hc: null, rate45: null, listId: Date.now() },
	{ routing: "RIPI", rate20: null, rate40: null, rate40hc: null, rate45: null, listId: Date.now() },
	{ routing: "GC", rate20: null, rate40: null, rate40hc: null, rate45: null, listId: Date.now() },
]

const CreateBAF: React.FC<IProps> = ({ dropdowns }) => {
	const [applyTo] = React.useState<string>("All Fixed")
	const [trade, setTrade] = React.useState<string>("")
	const [bafTableRows, setBafTableRows] = useState([])
	const [rateTypeRows, setRateTypeRows] = useState([])
	const [tradeRows, setTradeRows] = useState([])
	const [bafTable, setBafTable] = useState()
	const [bafId, setBafId] = useState<number>(undefined)

	const controlTowerService = useControlTowerService()

	useEffect(() => {
		const tableRows = TableRows.map((item, index) => {
			return { ...item, listId: Date.now() + index }
		})
		const rateTypeRows = dropdowns.rateTypes.map((item) => {
			return { ...item, listId: item.id }
		})
		const tradeRows = dropdowns.trades.map((item) => {
			return { ...item, listId: item.id }
		})
		setBafTableRows(tableRows)
		setRateTypeRows(rateTypeRows)
		setTradeRows(tradeRows)
		// eslint-disable-next-line
	}, [])

	function tradeTypeSelected(event: any) {
		setTrade(event.target.value)
		getBAFByTradeFn(event.target.value)
	}

	function handleChange(event: any, key: any, index: number) {
		const value = parseInt(event.target.value, 10)
		const cleanValue = isNaN(value) ? "" : value
		const tableRows = [...bafTableRows]
		bafTableRows[index][key] = cleanValue
		setBafTableRows(tableRows)
	}

	function getBAFByTradeFn(trade: string) {
		trackPromise(
			controlTowerService.getBAFByTrade(trade).then((data) => {
				setBafTable(data.rates)
				setBafId(data.id)
			}),
		)
	}

	function apply() {
		const baf = formatBAF()

		trackPromise(
			controlTowerService.applyBAF(bafId, baf).then(() => {
				getBAFByTradeFn(trade)
				resetBAF()
			}),
		)
	}

	function resetBAF() {
		const tableRows = TableRows.map((item, index) => {
			return { ...item, listId: Date.now() + index }
		})
		setBafTableRows(tableRows)
	}

	function formatBAF() {
		const rateChanges = JSON.parse(JSON.stringify([...bafTableRows]))
		rateChanges.map((row: any, index: number) => {
			Object.keys(row).map((key: any) => {
				if (key === "listId") {
					delete rateChanges[index][key]
				}
			})
		})
		const temp = {
			trade: trade,
			newRates: rateChanges,
		}
		return temp
	}

	return (
		<Fragment>
			<form id="gri-form">
				<ControlDropdowns
					rateSelected={null}
					rateDropdown={rateTypeRows}
					applyTo={applyTo}
					disabled={true}
					tradeSelected={tradeTypeSelected}
					tradeDropdown={tradeRows}
					trade={trade}
				></ControlDropdowns>
				{trade ? (
					<Fragment>
						<RateTable
							rateTable={bafTableRows}
							bafTable={bafTable}
							updateTable={handleChange}
							base={true}
						></RateTable>
						<button className="btn btn-primary" onClick={apply} type="button">
							Apply
						</button>
					</Fragment>
				) : null}
			</form>
		</Fragment>
	)
}

export default CreateBAF
