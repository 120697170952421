import React, { Fragment, useState } from "react"

interface IProps {
	record: any
	rowIndex: any
	newEntry: boolean
	selectedIndex: any
	otherField?: any
	removeRecordFn: Function
	updateRecordFn: Function
	selectedIndexFn: Function
}

const AdminCell: React.FC<IProps> = ({
	record,
	rowIndex,
	newEntry,
	selectedIndex,
	otherField,
	updateRecordFn,
	removeRecordFn,
	selectedIndexFn,
}) => {
	const [rowData, setRowData] = useState({
		id: undefined,
		text: "",
		value: "",
		[otherField]: null,
	})

	const editRow = (rec: any, index) => {
		selectedIndexFn(index)
		setRowData(rec)
	}

	const checkHandler = (event: any) => {
		const name = event.target.name
		const value = event.target.checked
		updateInput(name, value)
	}

	const inputHandler = (event: any) => {
		const name = event.target.name
		const value = event.target.value
		updateInput(name, value)
	}

	const updateInput = (key: string, data: string) => {
		const temp = { ...rowData, [key]: data }
		setRowData(temp)
	}

	return (
		<Fragment>
			<tr>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input
							type="text"
							value={rowData.text}
							name="text"
							onChange={(event) => inputHandler(event)}
						/>
					) : (
						record.text
					)}
				</td>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input
							type="text"
							value={rowData.value}
							name="value"
							onChange={(event) => inputHandler(event)}
						/>
					) : (
						record.value
					)}
				</td>
				{otherField && (
					<td>
						{" "}
						{selectedIndex === rowIndex ? (
							<input
								type="checkbox"
								checked={rowData[otherField]}
								name={otherField}
								onChange={(event) => checkHandler(event)}
							/>
						) : record[otherField] ? (
							"YES"
						) : (
							"NO"
						)}
					</td>
				)}
				<td>
					<div className="action-cell">
						<button
							type="button"
							disabled={rowData.text === "" || rowData.value === ""}
							hidden={!(selectedIndex === rowIndex)}
							className="save-btn"
							onClick={() => updateRecordFn(rowData)}
						>
							<i className="fa fa-floppy-o"></i>
						</button>
						<button
							type="button"
							hidden={!(selectedIndex === rowIndex)}
							className="trash-cancel-btn"
							onClick={() => selectedIndexFn(-1)}
						>
							<i className="fa fa-ban"></i>
						</button>
						<button
							type="button"
							disabled={newEntry}
							hidden={selectedIndex === rowIndex}
							className="edit-btn"
							onClick={() => editRow(record, rowIndex)}
						>
							<i className="fa fa-pencil"></i>
						</button>
						<button
							type="button"
							disabled={newEntry}
							hidden={selectedIndex === rowIndex}
							className="trash-cancel-btn"
							onClick={() => removeRecordFn(record)}
						>
							<i className="fa fa-trash"></i>
						</button>
					</div>
				</td>
			</tr>
		</Fragment>
	)
}

export default AdminCell
