import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"
import useClientService from "Services/ClientService"
import { useNavigate } from "react-router-dom"

function Clients({ setHeaderTitle }) {
	const navigate = useNavigate()
	const clientService = useClientService()
	const [clients, setClients] = useState([])
	const [clientsFiltered, setClientsFiltered] = useState([])
	const [prospectives, setProspectives] = useState([])
	const [prospectivesFiltered, setProspectivesFiltered] = useState([])

	useEffect(() => {
		return () => {
			clientService.abortRequests()
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		getClientList()
		// eslint-disable-next-line
	}, [])

	const getClientList = async () => {
		const clientList = await clientService.getClients()
		setClients(clientList.filter((client) => client.prospective === false).sort(sortByName))
		setProspectives(clientList.filter((client) => client.prospective === true).sort(sortByName))
		setClientsFiltered(clientList.filter((client) => client.prospective === false).sort(sortByName))
		setProspectivesFiltered(
			clientList.filter((client) => client.prospective === true).sort(sortByName),
		)
	}

	function sortByName(a, b) {
		if (a.name > b.name) {
			return 1
		}
		if (b.name > a.name) {
			return -1
		}
		return 0
	}

	function filterClients(event) {
		setClientsFiltered(
			clients.filter((client) =>
				client.name.toLowerCase().includes(event.target.value.toLowerCase()),
			),
		)
		setProspectivesFiltered(
			prospectives.filter((client) =>
				client.name.toLowerCase().includes(event.target.value.toLowerCase()),
			),
		)
	}

	useEffect(() => {
		setHeaderTitle("All Clients")
	}, [setHeaderTitle])

	function navigateTo(clientId: number) {
		navigate(`/app/client-profile/${clientId}`)
	}

	return (
		<div className="clients">
			<Helmet>
				<title>Home | UWL Pricing Tool</title>
			</Helmet>
			<LoadingIndicator />
			<input key="search" onChange={filterClients} placeholder="Search..."></input>
			<div className="widget-layout">
				<div className="card widget">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th>Clients</th>
							</tr>
						</thead>
						<tbody>
							{clientsFiltered.map((item) => (
								<tr>
									<td key={item.id}>
										<button className={"btn btn-link"} onClick={() => navigateTo(item.id)}>
											{item.name}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="card widget">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th>Prospectives</th>
							</tr>
						</thead>
						<tbody>
							{prospectivesFiltered.map((item) => (
								<tr>
									<td key={item.id}>
										<button className={"btn btn-link"} onClick={() => navigateTo(item.id)}>
											{item.name}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default Clients
