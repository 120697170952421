import React from "react"
import { Filterable } from "."
import BooleanFilter from "./Filters/BooleanFilter"
import CityStateZipFilter from "./Filters/CityStateZip/CityStateZipFilter"
import ClientFilter from "./Filters/ClientFilter"
import DateFilter from "./Filters/DateFilter"
import NumberFilter from "./Filters/NumberFilter"
import SelectFilter from "./Filters/SelectFilter"
import SelectOneOfFilter from "./Filters/SelectOneOfFilter"
import StringFilter from "./Filters/StringFilter"

interface FilterProps {
	filter: Filterable
	updateFilter: Function
}

function SearchFilterMap({ filter, updateFilter }: FilterProps) {
	switch (filter.type) {
		case "client": {
			return <ClientFilter filter={filter} updateFilter={updateFilter} />
		}
		case "string": {
			return <StringFilter filter={filter} updateFilter={updateFilter} />
		}
		case "number": {
			return <NumberFilter filter={filter} updateFilter={updateFilter} />
		}
		case "boolean": {
			return <BooleanFilter filter={filter} updateFilter={updateFilter} />
		}
		case "select": {
			return <SelectFilter filter={filter} updateFilter={updateFilter} />
		}
		case "select-one-of": {
			return <SelectOneOfFilter filter={filter} updateFilter={updateFilter} />
		}
		case "date": {
			return <DateFilter filter={filter} updateFilter={updateFilter} />
		}
		case "city-state-zip": {
			return <CityStateZipFilter filter={filter} updateFilter={updateFilter} />
		}
		case "locodes": {
			return <StringFilter filter={filter} updateFilter={updateFilter} />
		}
		default: {
			return <StringFilter filter={filter} updateFilter={updateFilter} />
		}
	}
}

export default SearchFilterMap
