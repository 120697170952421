import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"

const useLocodeService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		lookupLocodes: (lookupUrl) => {
			return apiContext.get(lookupUrl, source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
	}

	return API
}

export default useLocodeService
