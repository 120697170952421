import React from "react"
import "./Layouts.scss"
import Sidebar from "Layouts/Sidebar"
import { Outlet } from "react-router-dom"
import Toolbar from "./Toolbar"

function SidebarLayout({ pageTitle }) {
	return (
		<div className={"sbl"}>
			<Sidebar />
			<div className={"sbl-content-flexwrap"}>
				<div className={"sbl-main-content"}>
					<div className={"sbl-header"}>
						<h2 className={"page-title"}>{pageTitle}</h2>
						<Toolbar />
					</div>
					<div className={"sbl-content"}>
						<main>
							<Outlet />
						</main>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SidebarLayout
