import React, { useState, useRef, useEffect } from "react"
import "./DragDrop.scss"

function DragDrop({ handleFileChange, base64 }) {
	const [state, setState] = useState({
		dropDepth: 0,
		inDropZone: false,
		file: undefined,
		fileName: "",
	})

	const fileInputRef = useRef(null)

	function handleDragEnter(e) {
		e.preventDefault()
		e.stopPropagation()

		setState({ ...state, dropDepth: state.dropDepth + 1 })
	}
	function handleDragLeave(e) {
		e.preventDefault()
		e.stopPropagation()

		const dropDepth = state.dropDepth - 1
		const inDropZone = dropDepth === 0 ? false : state.inDropZone
		setState({ ...state, dropDepth: dropDepth, inDropZone: inDropZone })
	}
	function handleDragOver(e) {
		e.preventDefault()
		e.stopPropagation()

		e.dataTransfer.dropEffect = "copy"
		setState({ ...state, inDropZone: true })
	}
	function handleDrop(e) {
		e.preventDefault()
		e.stopPropagation()

		const file = [...e.dataTransfer.files][0]
		setFile(file)
	}

	function handleSelect(e) {
		const file = e.target.files[0]
		setFile(file)
	}

	function openFileBrowser(e) {
		fileInputRef.current.click()
	}

	function setFile(file) {
		const blob = file

		const fileName = file.name
		const reader = new FileReader()

		if (base64) {
			reader.readAsDataURL(blob)
		} else {
			reader.readAsArrayBuffer(blob)
		}

		reader.onloadend = function () {
			if (base64) {
				setState({
					...state,
					file: (reader.result as string).split(",")[1],
					fileName: fileName,
					dropDepth: 0,
					inDropZone: false,
				})
			} else {
				setState({
					...state,
					file: reader.result,
					fileName: fileName,
					dropDepth: 0,
					inDropZone: false,
				})
			}
		}
	}

	useEffect(() => {
		if (state.file) {
			handleFileChange({ file: state.file, filename: state.fileName })
		}
	}, [state.file])

	return (
		<div className="file-upload-wrapper">
			<input
				ref={fileInputRef}
				type="file"
				multiple={false}
				style={{ display: "none" }}
				onChange={(e) => handleSelect(e)}
			/>
			<div className="file-upload-area">
				<div
					className="file-upload-zone"
					onDrop={(e) => handleDrop(e)}
					onDragOver={(e) => handleDragOver(e)}
					onDragEnter={(e) => handleDragEnter(e)}
					onDragLeave={(e) => handleDragLeave(e)}
					onClick={(e) => openFileBrowser(e)}
				>
					<p>Drag file here or click to browse files.</p>
				</div>
				<p>File: {state.fileName}</p>
			</div>
		</div>
	)
}

export default DragDrop
