import { OceanRateChangeLog } from "Types/OceanRateTypes"

export interface IReducerAction {
	type: string
	payload?: any
}

export interface RateSheetItems {
	oceanRates: number[]
	drayRates: number[]
}

// RATE PAGE HELPERS
export interface IRatePageState {
	selectedClient: any
	rateSheetItems: RateSheetItems
	selectedDate: Date
	drayRates: any[]
	oceanRates: any[]
	rateBuffer: any
	editing: boolean
	editingDray: boolean
	editingOcean: boolean
	showHistoricalRates: boolean
	dropdowns: []
	clientDetails: any
	showFormModal: boolean
	showHistoryModal: boolean
	oceanRateHistoryData: OceanRateChangeLog[]
	oceanBuyRateId: number
	drayBuyRateId: number
}

export const InitialRatePageState: IRatePageState = {
	selectedClient: null,
	rateSheetItems: null,
	selectedDate: null,
	drayRates: [],
	oceanRates: [],
	rateBuffer: null,
	editing: false,
	editingDray: false,
	editingOcean: false,
	showHistoricalRates: false,
	dropdowns: [],
	clientDetails: {
		client: null,
		masterAccessorials: [],
	},
	showFormModal: false,
	showHistoryModal: false,
	oceanRateHistoryData: [],
	oceanBuyRateId: null,
	drayBuyRateId: null,
}

export interface IPageReducerAction extends IReducerAction {
	type:
		| "SELECT_CLIENT"
		| "SET_SELECTED_OCEAN_RATE_IDS"
		| "SET_SELECTED_DRAY_RATE_IDS"
		| "SELECT_DATE"
		| "SET_RATES"
		| "NEW_OCEAN_RATE"
		| "NEW_DRAY_RATE"
		| "COPY_OCEAN_RATE"
		| "COPY_DRAY_RATE"
		| "EDIT_OCEAN_RATE"
		| "EDIT_DRAY_RATE"
		| "PUSH_OCEAN_RATE"
		| "PUSH_DRAY_RATE"
		| "EXIT_EDIT_MODE"
		| "MARGIN"
		| "TOGGLE_HISTORICAL_RATES"
		| "SET_DROPDOWNS"
		| "SET_RATE_BUFFER"
		| "CLEAR_RATE_BUFFER"
		| "SET_CLIENT_DETAILS"
		| "OPEN_ACCEPT_FORM_MODAL"
		| "CLOSE_ACCEPT_FORM_MODAL"
		| "OPEN_HISTORY_MODAL"
		| "CLOSE_HISTORY_MODAL"
}

export const RatePageReducer = (
	state: IRatePageState,
	action: IPageReducerAction,
): IRatePageState => {
	switch (action.type) {
		case "SET_SELECTED_OCEAN_RATE_IDS":
			return {
				...state,
				rateSheetItems: { ...state.rateSheetItems, oceanRates: action.payload },
			}
		case "SET_SELECTED_DRAY_RATE_IDS":
			return {
				...state,
				rateSheetItems: { ...state.rateSheetItems, drayRates: action.payload },
			}
		case "SELECT_CLIENT":
			return {
				...state,
				selectedClient: action.payload,
				editing: false,
				rateBuffer: null,
			}
		case "SET_RATES":
			return {
				...state,
				rateSheetItems: { oceanRates: [], drayRates: [] },
				oceanRates: action.payload.oceanRates,
				drayRates: action.payload.drayRates,
			}
		case "SELECT_DATE":
			return {
				...state,
				selectedDate: action.payload,
			}
		case "COPY_OCEAN_RATE":
			return {
				...state,
				rateBuffer: action.payload,
				editing: true,
				editingOcean: true,
				editingDray: false,
			}
		case "COPY_DRAY_RATE":
			return {
				...state,
				rateBuffer: action.payload,
				editing: true,
				editingDray: true,
				editingOcean: false,
			}
		case "EDIT_OCEAN_RATE":
			return {
				...state,
				rateBuffer: action.payload,
				editing: true,
				editingOcean: true,
				editingDray: false,
			}
		case "EDIT_DRAY_RATE":
			return {
				...state,
				rateBuffer: action.payload,
				editing: true,
				editingDray: true,
				editingOcean: false,
			}
		case "PUSH_OCEAN_RATE":
			return {
				...state,
				rateBuffer: action.payload.rate,
				editing: true,
				editingOcean: true,
				editingDray: false,
				oceanBuyRateId: action.payload.buyRateId,
			}
		case "PUSH_DRAY_RATE":
			return {
				...state,
				rateBuffer: action.payload.rate,
				editing: true,
				editingDray: true,
				editingOcean: false,
				drayBuyRateId: action.payload.buyRateId,
			}
		case "NEW_OCEAN_RATE":
			return {
				...state,
				editing: true,
				editingOcean: true,
				editingDray: false,
				rateBuffer: null,
			}
		case "NEW_DRAY_RATE":
			return {
				...state,
				editing: true,
				editingDray: true,
				editingOcean: false,
				rateBuffer: null,
			}
		case "EXIT_EDIT_MODE":
			return {
				...state,
				editing: false,
				editingOcean: false,
				editingDray: false,
				rateBuffer: null,
				oceanBuyRateId: null,
				drayBuyRateId: null,
			}
		case "MARGIN":
			return {
				...state,
				rateBuffer: action.payload,
				editing: true,
				editingOcean: true,
				editingDray: false,
			}
		case "TOGGLE_HISTORICAL_RATES":
			return {
				...state,
				showHistoricalRates: !state.showHistoricalRates,
				selectedDate: action.payload,
			}
		case "SET_DROPDOWNS":
			return {
				...state,
				dropdowns: action.payload,
			}
		case "SET_RATE_BUFFER":
			return {
				...state,
				rateBuffer: action.payload,
			}
		case "CLEAR_RATE_BUFFER":
			return {
				...state,
				rateBuffer: null,
			}
		case "OPEN_ACCEPT_FORM_MODAL":
			return {
				...state,
				rateBuffer: action.payload,
				showFormModal: true,
			}
		case "CLOSE_ACCEPT_FORM_MODAL":
			return {
				...state,
				rateBuffer: null,
				showFormModal: false,
			}
		case "OPEN_HISTORY_MODAL":
			return {
				...state,
				showHistoryModal: true,
				oceanRateHistoryData: action.payload,
			}
		case "CLOSE_HISTORY_MODAL":
			return {
				...state,
				showHistoryModal: false,
			}
		default:
			return {
				...state,
			}
	}
}
