import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"

const useControlTowerService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		getClients: () => {
			return apiContext.get("client/", source.token).then((clients) => {
				let clientOptions = []
				if (clients.length) {
					clientOptions = clients.map((c: any) => {
						return { type: c.name, value: c }
					})
				}
				return clientOptions
			})
		},
		getPSSLogs: () => {
			return apiContext.get("pss/log", source.token).then((logs) => {
				let pssLogs = []
				if (logs.length) {
					pssLogs = logs.map((l: any) => {
						return {
							appliedBy: l.appliedBy,
							appliedDate: l.appliedDate,
							clientName: l.clientName,
							effectiveDate: l.effectiveDate,
							expirationDate: l.expirationDate,
							id: l.id,
							rate20: l.rate20,
							rate40: l.rate40,
							rate40hc: l.rate40hc,
							rate45: l.rate45,
							rateType: l.rateType,
							trade: l.trade,
							reversedBy: l.reversedBy,
							reversedDate: l.reversedDate,
							oceanRateId: l.oceanRateId,
							type: l.type,
						}
					})
				}
				return pssLogs
			})
		},
		applyPSS: (pss: any) => {
			return apiContext.post("pss/", pss, source.token)
		},
		updatePSS: (id: number, date: any) => {
			return apiContext.post("pss/" + id, date, source.token)
		},
		getBAF: () => {
			return apiContext.get("baf/", source.token)
		},
		getBAFByTrade: (trade: string) => {
			return apiContext.get("baf/" + trade, source.token)
		},
		applyBAF: (id: number, baf: any) => {
			return apiContext.post("baf/" + id, baf, source.token)
		},
		updateBAF: (id: number, baf: any) => {
			return apiContext.post("baf/" + id, baf, source.token)
		},
		getBuyRates: (params: any) => {
			return apiContext.post("buy-rate/", params, source.token)
		},
		getControlTowerDropdowns: () => {
			return apiContext.get("dropdown/control-tower", source.token)
		},
		approveChanges: (payload: { rateChanges: any }) => {
			return apiContext.post("gri-grd/", payload, source.token)
		},
		getGriPreview: (sentGriObject) => {
			return apiContext.post("gri-grd/preview", sentGriObject, source.token)
		},
		createNewDraft: (draft: any) => {
			return apiContext.post("gri-grd/draft", draft, source.token)
		},
		saveDraft: (id: number, draft: any) => {
			return apiContext.post("gri-grd/draft/" + id, draft, source.token)
		},
		deleteDraft: (id: number) => {
			return apiContext.del("gri-grd/draft/" + id, source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
	}

	return API
}

export default useControlTowerService
