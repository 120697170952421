import React, { Fragment, useState } from "react"

interface IProps {
	record: any
	rowIndex: any
	newEntry: boolean
	selectedIndex: any
	removeRecordFn: Function
	updateRecordFn: Function
	selectedIndexFn: Function
}

const BuyRatePortsCell: React.FC<IProps> = ({
	record,
	rowIndex,
	newEntry,
	selectedIndex,
	updateRecordFn,
	removeRecordFn,
	selectedIndexFn,
}) => {
	const [rowData, setRowData] = useState({
		id: undefined,
		name: "",
		code: "",
	})

	const editRow = (rec: any, index) => {
		selectedIndexFn(index)
		setRowData(rec)
	}

	const inputHandler = (event: any) => {
		const name = event.target.name
		const value = event.target.value.toUpperCase()
		updateInput(name, value)
	}

	const updateInput = (key: string, data: string) => {
		const temp = { ...rowData, [key]: data }
		setRowData(temp)
	}

	return (
		<Fragment>
			<tr>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input
							type="text"
							value={rowData.name}
							name="name"
							onChange={(event) => inputHandler(event)}
						/>
					) : (
						record.name
					)}
				</td>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input
							type="text"
							value={rowData.code}
							name="code"
							onChange={(event) => inputHandler(event)}
						/>
					) : (
						record.code
					)}
				</td>
				<td>
					<div className="action-cell">
						<button
							type="button"
							disabled={rowData.name === "" || rowData.code === ""}
							hidden={!(selectedIndex === rowIndex)}
							className="save-btn"
							onClick={() => updateRecordFn(rowData)}
						>
							<i className="fa fa-floppy-o"></i>
						</button>
						<button
							type="button"
							hidden={!(selectedIndex === rowIndex)}
							className="trash-cancel-btn"
							onClick={() => selectedIndexFn(-1)}
						>
							<i className="fa fa-ban"></i>
						</button>
						<button
							type="button"
							disabled={newEntry}
							hidden={selectedIndex === rowIndex}
							className="edit-btn"
							onClick={() => editRow(record, rowIndex)}
						>
							<i className="fa fa-pencil"></i>
						</button>
						<button
							type="button"
							disabled={newEntry}
							hidden={selectedIndex === rowIndex}
							className="trash-cancel-btn"
							onClick={() => removeRecordFn(record)}
						>
							<i className="fa fa-trash"></i>
						</button>
					</div>
				</td>
			</tr>
		</Fragment>
	)
}

export default BuyRatePortsCell
