import { usePromiseTracker } from "react-promise-tracker"
import React from "react"
import Loader from "react-loader-spinner"
import Modal from "Shared/Modal/Modal"

const LoadingIndicator = (props) => {
	const { promiseInProgress } = usePromiseTracker()
	return (
		promiseInProgress && (
			<Modal>
				<div
					style={{
						width: "100%",
						height: "100",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader type="ThreeDots" color="#006241" height={100} width={100} />
				</div>
			</Modal>
		)
	)
}

export default LoadingIndicator
