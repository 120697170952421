import React, { useState, useEffect } from "react"
import ClientProfileContext from "./ClientProfileContext"
import { useParams } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"
import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"
import useClientService from "Services/ClientService"
import { useNavigate } from "react-router-dom"
import NavigationBlocker from "../../Shared/NavigationBlocker/NavigationBlocker"

const ClientProfileProvider: React.FC = ({ children }) => {
	const navigate = useNavigate()
	const promptMessage =
		"The current profile has unsaved changes. Would you like to stay to keep current changes, or leave and discard unsaved profile changes? "
	const [confirm, setConfirm] = useState(false)
	const [client, setClient] = useState(null)
	const [clients, setClients] = useState([])
	const [formState, setFormState] = useState({
		formChanged: false,
		isSaving: false,
		isValid: false,
		errorList: [],
	})
	const [dropdowns, setDropdowns] = useState({
		usStates: [],
		countries: [],
		agents: [],
		commodities: [],
		masterAccessorials: [],
		internalContacts: [],
	})

	const { id }: any = useParams()

	const clientService = useClientService()

	useEffect(() => {
		return () => {
			clientService.abortRequests()
		}
		// eslint-disable-next-line
	}, [])

	// OnInit
	useEffect(() => {
		trackPromise(
			fetchClients().then((clients) => {
				setClients(clients)
			}),
		)

		if (id) {
			trackPromise(
				fetchClient(id)
					.then((client) => {
						setClient(client)
					})
					.then(() => {
						return fetchDropDowns()
					})
					.then((dropdowns: any) => {
						setDropdowns({
							masterAccessorials: dropdowns.accessorials,
							usStates: dropdowns.states,
							countries: dropdowns.countries,
							commodities: dropdowns.commodities,
							agents: dropdowns.agents,
							internalContacts: dropdowns.internalContacts,
						})
					}),
			)
		} else {
			const clientObj = {
				id: undefined,
				name: "",
				country: "",
				address: "",
				city: "",
				state: "",
				zipCode: "",
				logo: "",
				cwOrgCode: "",
				marginMin: 0,
				marginMax: 0,
				primaryAgent: "",
				genCommodity: "FAK",
				rateStructure: "Both",
				contacts: {
					internal: [],
					external: [],
				},
				accessorials: [],
				notes: {
					internal: [],
					external: [],
				},
				prospective: false,
				prospectiveMargin: 0,
				emailFrequency: "",
			}
			trackPromise(
				fetchDropDowns().then((dropdowns: any) => {
					clientObj.accessorials = setDefaultAccessorials(dropdowns.accessorials)
					setClient(clientObj)
					setDropdowns({
						masterAccessorials: dropdowns.accessorials,
						usStates: dropdowns.states,
						countries: dropdowns.countries,
						commodities: dropdowns.commodities,
						agents: dropdowns.agents,
						internalContacts: dropdowns.internalContacts,
					})
				}),
			)
		}
		// eslint-disable-next-line
	}, [id])

	useEffect(() => {
		if (client !== null) {
			validateClient()
		}
		// eslint-disable-next-line
	}, [client])

	const updateClientFn = (key: string, data: any) => {
		const updateClient = { ...client, [key]: data }
		if (key === "prospective" && data === false) {
			updateClient.prospectiveMargin = 0
			updateClient.emailFrequency = ""
		}

		if (formState.formChanged === false) {
			setFormState({ ...formState, formChanged: true })
		}
		setClient(updateClient)
	}

	const setDefaultAccessorials = (accessorials) => {
		const acc = [...accessorials]
		return acc
			.filter((a) => a.isDefault)
			.map((a) => {
				return {
					id: a.id,
					name: a.name,
					amount: a.amount,
					location: a.location,
					per: a.per,
				}
			})
	}

	const fetchClient = (id) => {
		return clientService.getClientProfile(id)
	}

	const fetchDropDowns = () => {
		return clientService.getClientDropdowns()
	}

	const fetchClients = () => {
		return clientService.getClients().then((data) => {
			return data.map((c) => {
				return {
					type: c.name,
					value: c,
				}
			})
		})
	}

	const validateClient = () => {
		const errors = []
		if (client.name === "") errors.push("Missing client name")
		if (client.emailFrequency === "" && client.prospective) errors.push("Missing Email Frequency")
		if (client.prospectiveMargin === 0 && client.prospective)
			errors.push("Missing Prospective margin")
		if (client.country === "") errors.push("Missing country")
		if (client.address === "") errors.push("Missing address")
		if (client.city === "" && client.country === "United States") errors.push("Missing city")
		if (client.state === "" && client.country === "United States") errors.push("Missing state")
		if (client.zipCode === "" && client.country === "United States") errors.push("Missing zip code")
		if (client.cwOrgCode === "" && !client.prospective) errors.push("Missing CW Org Code")
		if (client.marginMin === 0) errors.push("Missing Margin Min")
		if (client.marginMax === 0) errors.push("Missing Margin Max")
		if (client.marginMax < client.marginMin)
			errors.push("Margin max cannot be less than margin min")
		if (client.contacts.external.length < 1) errors.push("Missing client contact")

		if (client.country === "United States") {
			if (client.prospective) {
				if (
					client.name !== "" &&
					client.country !== "" &&
					client.address !== "" &&
					client.city !== "" &&
					client.state !== "" &&
					client.zipCode !== "" &&
					client.marginMin !== 0 &&
					client.marginMax !== 0 &&
					client.marginMax > client.marginMin &&
					client.contacts.external.length > 0 &&
					client.emailFrequency !== "" &&
					client.prospectiveMargin !== null
				) {
					setFormState({ ...formState, isValid: true, errorList: errors })
				} else {
					setFormState({ ...formState, isValid: false, errorList: errors })
				}
			} else {
				if (
					client.name !== "" &&
					client.country !== "" &&
					client.address !== "" &&
					client.city !== "" &&
					client.state !== "" &&
					client.zipCode !== "" &&
					client.cwOrgCode !== "" &&
					client.marginMin !== 0 &&
					client.marginMax !== 0 &&
					client.marginMax > client.marginMin &&
					client.contacts.external.length > 0
				) {
					setFormState({ ...formState, isValid: true, errorList: errors })
				} else {
					setFormState({ ...formState, isValid: false, errorList: errors })
				}
			}
		} else {
			if (client.prospective) {
				if (
					client.name !== "" &&
					client.country !== "" &&
					client.address !== "" &&
					client.marginMin !== 0 &&
					client.marginMax !== 0 &&
					client.marginMax > client.marginMin &&
					client.contacts.external.length > 0 &&
					client.emailFrequency !== "" &&
					client.prospectiveMargin !== null
				) {
					setFormState({ ...formState, isValid: true, errorList: errors })
				} else {
					setFormState({ ...formState, isValid: false, errorList: errors })
				}
			} else {
				if (
					client.name !== "" &&
					client.country !== "" &&
					client.address !== "" &&
					client.cwOrgCode !== "" &&
					client.marginMin !== 0 &&
					client.marginMax !== 0 &&
					client.marginMax > client.marginMin &&
					client.contacts.external.length > 0
				) {
					setFormState({ ...formState, isValid: true, errorList: errors })
				} else {
					setFormState({ ...formState, isValid: false, errorList: errors })
				}
			}
		}
	}

	const updateContactsFn = (contacts: any, type: string) => {
		const obj = { ...client.contacts }

		if (type === "internal") {
			obj.internal = contacts
		} else {
			obj.external = contacts
		}

		updateClientFn("contacts", obj)
	}

	const updateNotesFn = (notes: any, type: string) => {
		const obj = { ...client.notes }

		if (type === "internal") {
			obj.internal = notes
		} else {
			obj.external = notes
		}

		updateClientFn("notes", obj)
	}

	const saveClientProfileFn = () => {
		setFormState({ ...formState, isSaving: true })
		if (client.id === undefined) {
			clientService.createClientProfile(client).then((data) => {
				setFormState({ ...formState, formChanged: false })
				navigate(`/app/client-profile/${data.id.id}`)
			})
			setFormState({ ...formState, isSaving: false })
		} else {
			clientService.updateClientProfile(client, parseInt(id)).then(() => {
				setFormState({ ...formState, formChanged: false })
			})
			setFormState({ ...formState, isSaving: false })
		}
	}

	const removeClientProfileFn = (id: number) => {
		setFormState({ ...formState, isSaving: true })
		clientService.deleteClientProfile(id).then(
			() => {
				toggleConfirmModal()
				setFormState({ ...formState, formChanged: false })
				navigate("/app/client-profile/")
			},
			(_) => {
				toggleConfirmModal()
			},
		)
		setFormState({ ...formState, isSaving: false })
	}

	const toggleConfirmModal = () => {
		window.scrollTo(0, 0)
		setConfirm(!confirm)
	}

	const selectFn = (value) => {
		navigate(`/app/client-profile/${value.id}`)
	}

	const clientSellRateFn = () => {
		navigate(`/app/client-sell-rates/${client.id}`)
	}

	return (
		<ClientProfileContext.Provider
			value={{
				client: client,
				masterAccessorials: dropdowns.masterAccessorials,
				usStates: dropdowns.usStates,
				countries: dropdowns.countries,
				commodities: dropdowns.commodities,
				agents: dropdowns.agents,
				internalContacts: dropdowns.internalContacts,
				isSaving: formState.isSaving,
				isValid: formState.isValid,
				errorList: formState.errorList,
				confirm: confirm,
				clients: clients,
				updateClientFn: updateClientFn,
				updateContactsFn: updateContactsFn,
				updateNotesFn: updateNotesFn,
				saveClientProfileFn: saveClientProfileFn,
				removeClientProfileFn: removeClientProfileFn,
				toggleConfirmModal: toggleConfirmModal,
				select: selectFn,
				clientSellRateFn: clientSellRateFn,
			}}
		>
			<LoadingIndicator />
			<NavigationBlocker
				blockWhen={formState.formChanged}
				message={promptMessage}
				confirmButtonText={"LEAVE"}
				cancelButtonText={"STAY"}
			/>
			{client ? children : null}
		</ClientProfileContext.Provider>
	)
}

export default ClientProfileProvider
