import { FC, useState, useEffect, useMemo, ChangeEvent } from "react"
import {
	ColumnDef,
	useReactTable,
	getCoreRowModel,
	flexRender,
	RowData,
	Row,
	RowSelectionState,
} from "@tanstack/react-table"
import "../AdminConsole.scss"
import useAdminService from "Services/AdminService"
import { editableColumn } from "Shared/ReactTableUtils/TableUtils"

interface Carrier {
	id: number
	text: string
	value: string
	scac: string
	cw1CarrierCode: string
	active: boolean
}

declare module "@tanstack/react-table" {
	interface TableMeta<TData extends RowData> {
		updateData: (rowIndex: number, columnId: string, value: unknown) => void
	}
}

interface IProps {
	context: {
		tableName: string
		tableData: Carrier[]
		selectedTableFn: (event: React.ChangeEvent<HTMLSelectElement>) => void
		fetchAdminTableFn: (tableName: string) => void
	}
}

export const AdminCarriersTable: FC<IProps> = ({ context }) => {
	const [newEntry, setNewEntry] = useState<boolean>(false)
	const [newCarrier, setNewCarrier] = useState<Carrier>({
		id: 0,
		text: "",
		value: "",
		scac: "",
		cw1CarrierCode: "",
		active: false,
	})
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
	const adminService = useAdminService()
	const [data, setData] = useState<Carrier[]>(context.tableData)
	const isSelected = useMemo<boolean>(() => Object.keys(rowSelection).length > 0, [rowSelection])
	const columns = useMemo<ColumnDef<Carrier>[]>(
		() => [
			{
				accessorKey: "text",
				header: "Name",
			},
			{
				accessorKey: "scac",
				header: "SCAC",
			},
			{
				accessorKey: "cw1CarrierCode",
				header: "CW1 Carrier Code",
			},
			{
				accessorKey: "active",
				header: "Active",
			},
		],
		[],
	)
	useEffect(() => {
		setData([...context.tableData])
	}, [context.tableData])
	const table = useReactTable({
		data,
		columns,
		defaultColumn: editableColumn,
		state: {
			rowSelection,
		},
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		// Provide our updateData function to our table meta
		meta: {
			updateData: (rowIndex, columnId, value) => {
				setData((old) =>
					old.map((row, index) => {
						if (index === rowIndex) {
							return {
								...old[rowIndex],
								[columnId]: value,
							}
						}
						return row
					}),
				)
			},
		},
	})

	const updateRow = (rowIndex: number, rowData: Carrier): void => {
		setData((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...rowData,
					}
				}
				return row
			}),
		)
	}

	const cancel = (row: Row<Carrier>): void => {
		updateRow(row.index, context.tableData[row.index])
		row.toggleSelected()
	}
	const update = (row: Row<Carrier>): void => {
		const record = { ...row.original, cw1_carrier_code: row.original.cw1CarrierCode }
		delete record["cw1CarrierCode"]
		adminService.updateRecord(context.tableName, record).then(() => {
			context.fetchAdminTableFn(context.tableName)
			row.toggleSelected()
		})
	}

	const remove = (record: Carrier): void => {
		adminService.deleteRecord(context.tableName, record.id).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
	}

	const add = () => {
		const record = { ...newCarrier, cw1_carrier_code: newCarrier.cw1CarrierCode }
		delete record["cw1CarrierCode"]
		adminService
			.addRecord(context.tableName, record)
			.then(() => {
				context.fetchAdminTableFn(context.tableName)
			})
			.finally(() => {
				reset()
			})
	}

	const validateNewCarrier = (): boolean => {
		if (!newCarrier.text || !newCarrier.scac || !newCarrier.cw1CarrierCode) {
			return false
		}
		return true
	}

	const validateRow = (original: Carrier, current: Carrier): boolean => {
		if (!current.cw1CarrierCode || !current.text || !current.scac) {
			return false
		}
		if (
			original.cw1CarrierCode !== current.cw1CarrierCode ||
			original.text !== current.text ||
			original.scac !== current.scac ||
			original.active !== current.active
		) {
			return true
		}
		return false
	}

	const reset = (): void => {
		setNewCarrier({ id: 0, text: "", value: "", scac: "", cw1CarrierCode: "", active: false })
		setNewEntry(false)
	}

	const updateInput = (key: string, data: string | boolean) => {
		const temp = { ...newCarrier, [key]: data }
		setNewCarrier(temp)
	}

	const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name
		const value = event.target.value
		if (name === "text") {
			setNewCarrier({ ...newCarrier, text: value, value: value })
		} else {
			updateInput(name, value)
		}
	}

	const checkHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name
		const value = event.target.checked
		updateInput(name, value)
	}

	return (
		<div className="card other-admin-table">
			<div className="card-header admin-table-title">
				{context.tableName}
				<button
					type="button"
					className="add-btn"
					onClick={() => setNewEntry(true)}
					disabled={newEntry || isSelected}
				>
					<i className="fa fa-plus"></i>
				</button>
			</div>
			<div className="card-body">
				<table className="table table-bordered">
					<thead className="thead-light">
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
									</th>
								))}
								<th>Actions</th>
							</tr>
						))}
						<tr hidden={!newEntry} className="newEntry">
							<td>
								<input
									name="text"
									type="text"
									className="form-control"
									placeholder="Name"
									onChange={inputHandler}
									value={newCarrier.text}
								/>
							</td>
							<td>
								<input
									name="scac"
									type="text"
									className="form-control"
									placeholder="SCAC"
									onChange={inputHandler}
									value={newCarrier.scac}
								/>
							</td>
							<td>
								<input
									name="cw1CarrierCode"
									type="text"
									className="form-control"
									placeholder="CW1 Carrier Code"
									onChange={inputHandler}
									value={newCarrier.cw1CarrierCode}
								/>
							</td>
							<td>
								<input
									name="active"
									type="checkbox"
									checked={newCarrier.active}
									onChange={checkHandler}
								/>
							</td>
							<td>
								<div className="action-cell">
									<button
										type="button"
										className="save-btn"
										onClick={() => add()}
										disabled={!validateNewCarrier()}
									>
										<i className="fa fa-floppy-o"></i>
									</button>
									<button type="button" className="trash-cancel-btn" onClick={() => reset()}>
										<i className="fa fa-ban"></i>
									</button>
								</div>
							</td>
						</tr>
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => {
							return (
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<td key={cell.id}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										)
									})}
									<td>
										<div className="action-cell">
											<button
												type="button"
												hidden={!row.getIsSelected()}
												className="save-btn"
												onClick={() => update(row)}
												disabled={
													!validateRow(
														context.tableData.find((x) => x.id === row.original.id),
														row.original,
													)
												}
											>
												<i className="fa fa-floppy-o"></i>
											</button>
											<button
												type="button"
												hidden={!row.getIsSelected()}
												className="trash-cancel-btn"
												onClick={() => cancel(row)}
											>
												<i className="fa fa-ban"></i>
											</button>
											<button
												type="button"
												hidden={isSelected || newEntry}
												className="edit-btn"
												onClick={row.getToggleSelectedHandler()}
											>
												<i className="fa fa-pencil"></i>
											</button>
											<button
												type="button"
												hidden={isSelected || newEntry}
												className="trash-cancel-btn"
												onClick={() => remove(row.original)}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}
