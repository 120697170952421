import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"

const useBuyRateService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		lookupRates: (params) => {
			return apiContext.post("buy-rate/", params, source.token)
		},
		getBuyRateDataset: () => {
			return apiContext.get("buy-rate/dataset", source.token)
		},
		getDrayBuyRateDataset: () => {
			return apiContext.get("dray-buy-rate/dataset", source.token)
		},
		getAgents: () => {
			return apiContext.get("buy-rate/agents", source.token)
		},
		uploadBuyRate: (selectedAgent, payload) => {
			return apiContext.upload(`buy-rate/import/${selectedAgent}`, payload, source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
		downloadDrayAttachment: (id: number) => {
			return apiContext.download("dray-buy-rate/attachment/" + id, source.token)
		},
		lookupDrayRates: (params) => {
			return apiContext.post("dray-buy-rate/", params, source.token)
		},
		importDrayBuyRates: (file) => {
			return apiContext.upload("dray-buy-rate/import", file, source.token)
		},
		saveDrayBuyRate: (buyRate) => {
			return apiContext.post("dray-buy-rate/save", buyRate, source.token)
		},
	}

	return API
}

export default useBuyRateService
