import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"
import { RateSheetItems } from "Pages/SellRate/Helpers"

const useRateSheetService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		sendRateSheets: (clientIds: number[], message: any, selectedRates: RateSheetItems = null) => {
			return apiContext.post("rate-sheet/send", { clientIds, message, selectedRates }, source.token)
		},
		downloadRateSheet: (id: number, rateSheetItems: RateSheetItems = null) => {
			return apiContext.downloadWithPayload(
				"rate-sheet/excel/" + id,
				{ rateSheetItems },
				source.token,
			)
		},
		searchRateSheet: (serialNumber: string) => {
			return apiContext.get("rate-sheet/data/" + serialNumber, source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
	}

	return API
}

export default useRateSheetService
