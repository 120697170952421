import React, { useState, useEffect, useMemo } from "react"
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	useReactTable,
	getSortedRowModel,
	SortingState,
	Row,
	RowSelectionState,
	ColumnFiltersState,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	ColumnOrderState,
} from "@tanstack/react-table"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import "../Page.scss"
import { oceanRatesTableHeaders } from "Pages/TableHeaders"
import { OceanRateRowType } from "Types/OceanRateTypes"
import { Filter, DebouncedInput, fuzzyFilter } from "Shared/ReactTableUtils/TableUtils"
import { IndeterminateCheckbox } from "Shared/ReactTableUtils/TableUtils"

export interface IProps {
	rates: OceanRateRowType[]
	readonly: boolean
	column_state?: { visibility: Record<string, boolean>; order: string[] }
	copyHandler?: (rate: OceanRateRowType) => void
	editHandler?: (rate: OceanRateRowType) => void
	selectHandler?: (rateIds: number[]) => void
	acceptHandler?: (rate: OceanRateRowType) => void
	historyHandler?: (rateID: number) => void
	saveLayoutHandler?: (visibility: Record<string, boolean>, order: string[]) => void
}

const OceanRateTable: React.FC<IProps> = ({
	rates,
	readonly,
	column_state,
	copyHandler,
	editHandler,
	selectHandler,
	acceptHandler,
	historyHandler,
	saveLayoutHandler,
}) => {
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
	const [sorting, setSorting] = React.useState<SortingState>([
		{ id: "destinationCy", desc: false },
		{ id: "quoteNumber", desc: false },
	])
	const [columnVisibility, setColumnVisibility] = React.useState(
		column_state?.visibility ? column_state.visibility : {},
	)
	const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>(
		column_state?.order ? column_state.order : [],
	)
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const toggle = () => setDropdownOpen((prevState) => !prevState)
	const [globalFilter, setGlobalFilter] = React.useState("")
	const columns = useMemo(() => oceanRatesTableHeaders(), [])

	const oceanTable = useReactTable({
		data: rates,
		columns,
		state: {
			rowSelection,
			sorting,
			columnFilters,
			globalFilter,
			columnVisibility,
			columnOrder,
		},
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		onColumnFiltersChange: setColumnFilters,
		onSortingChange: setSorting,
		onGlobalFilterChange: setGlobalFilter,
		onColumnVisibilityChange: setColumnVisibility,
		onColumnOrderChange: setColumnOrder,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		globalFilterFn: fuzzyFilter,
	})

	useEffect(() => {
		if (!selectHandler) {
			return
		}
		const selectedIds = oceanTable.getSelectedRowModel().rows.map((row) => row.original.id)
		selectHandler(selectedIds)
	}, [rowSelection])

	function moveExpiredRatesToBottom(rows: Row<OceanRateRowType>[]): Row<OceanRateRowType>[] {
		const expiredRates = []
		const reorderedTemp = []
		rows.forEach((r) => {
			r.original.expired ? expiredRates.push(r) : reorderedTemp.push(r)
		})
		expiredRates.forEach((row) => {
			reorderedTemp.push(row)
		})
		return [...reorderedTemp]
	}

	function getRowClass(row: OceanRateRowType): string {
		if (!row) return ""
		if (row.expiringSoon) return "expiring"
		if (row.expired) return "expired"
		return ""
	}

	const renderActionColumnHeader = (): JSX.Element => {
		if (readonly) {
			return null
		}
		return (
			<th scope="col" style={{ textAlign: "center" }}>
				Actions
			</th>
		)
	}

	const renderActionColumn = (row: Row<OceanRateRowType>): JSX.Element => {
		if (readonly) {
			return null
		}
		return (
			<td className="table-td">
				<div className="table-row-action-buttons">
					<button className="btn btn-link" onClick={() => copyHandler(row.original)}>
						Copy
					</button>
					{row.original && !row.original.expired && !row.original.acceptedUser && (
						<>
							<button className="btn btn-link" onClick={() => editHandler(row.original)}>
								Edit
							</button>
							<button className="btn btn-link" onClick={() => acceptHandler(row.original)}>
								Accept
							</button>
						</>
					)}
					{row.original && row.original.hasHistory && (
						<button className="btn btn-link" onClick={() => historyHandler(row.original.id)}>
							History
						</button>
					)}
				</div>
			</td>
		)
	}

	const renderSelectionHeader = (): JSX.Element => {
		return (
			<th scope="col" style={{ textAlign: "center" }}>
				<IndeterminateCheckbox
					{...{
						checked: oceanTable.getIsAllRowsSelected(),
						indeterminate: oceanTable.getIsSomeRowsSelected(),
						onChange: oceanTable.getToggleAllRowsSelectedHandler(),
					}}
				/>
			</th>
		)
	}

	const renderSelectionColumn = (row: Row<OceanRateRowType>): JSX.Element => {
		return (
			<td className="table-td" style={{ textAlign: "center" }}>
				{!row.original.expired && (
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				)}
			</td>
		)
	}

	const moveColumn = (direction: "left" | "right", id: string): void => {
		const finalOrder = oceanTable.getAllLeafColumns().map((c) => c.id)
		const idx = finalOrder.indexOf(id)
		if (direction === "left") {
			if (idx === 0) {
				finalOrder.push(finalOrder.shift())
			} else {
				finalOrder.splice(idx - 1, 0, finalOrder.splice(idx, 1)[0])
			}
		} else {
			if (idx === finalOrder.length - 1) {
				finalOrder.unshift(finalOrder.pop())
			} else {
				finalOrder.splice(idx + 1, 0, finalOrder.splice(idx, 1)[0])
			}
		}
		oceanTable.setColumnOrder(finalOrder)
	}

	return (
		<>
			<div className="form-column" style={{ paddingBottom: "0.5rem" }}>
				<div className="form-row">
					<DebouncedInput
						value={globalFilter ?? ""}
						onChange={(value) => setGlobalFilter(String(value))}
						className="p-2 font-lg border border-block"
						placeholder="Search all columns..."
						style={{ height: "100%" }}
					/>
					<Dropdown
						isOpen={dropdownOpen}
						toggle={toggle}
						direction="down"
						color="primary"
						style={{ height: "100%" }}
					>
						<DropdownToggle color="primary" caret>
							Column Visibility
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem
								active={!oceanTable.getIsAllColumnsVisible()}
								onClick={oceanTable.getToggleAllColumnsVisibilityHandler()}
							>
								{oceanTable.getIsAllColumnsVisible() ? "Hide All" : "Show All"}
							</DropdownItem>
							{oceanTable.getAllLeafColumns().map((column) => (
								<DropdownItem
									key={column.id}
									active={!column.getIsVisible()}
									onClick={column.getToggleVisibilityHandler()}
								>
									{column.columnDef.header}
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
					<button
						className="btn btn-primary"
						style={{ height: "100%" }}
						onClick={() => {
							oceanTable.resetColumnOrder(true)
						}}
					>
						Reset Column Order
					</button>
					<button
						className="btn btn-primary"
						style={{ height: "100%" }}
						onClick={() => {
							oceanTable.resetColumnOrder(true)
							oceanTable.resetColumnVisibility(true)
						}}
					>
						Reset Layout
					</button>
					{saveLayoutHandler && (
						<button
							className="btn btn-primary"
							onClick={() => saveLayoutHandler(columnVisibility, columnOrder)}
							style={{ height: "100%" }}
						>
							Save Layout
						</button>
					)}
				</div>
			</div>
			<table className="table table-bordered">
				<thead className="thead-light">
					{oceanTable.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{renderActionColumnHeader()}
							{renderSelectionHeader()}
							{headerGroup.headers.map((header) => (
								<th key={header.id} colSpan={header.colSpan}>
									{header.isPlaceholder ? null : (
										<>
											<div
												{...{
													className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
													onClick: header.column.getToggleSortingHandler(),
												}}
											>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{{
													asc: (
														<i
															className="sort-icon fa fa-sort-amount-asc fa-md"
															aria-hidden="true"
														></i>
													),
													desc: (
														<i
															className="sort-icon fa fa-sort-amount-desc fa-md"
															aria-hidden="true"
														></i>
													),
												}[header.column.getIsSorted() as string] ?? null}
											</div>
											{header.column.getCanFilter() ? (
												<div>
													<Filter column={header.column} table={oceanTable} />
												</div>
											) : null}
											<div className="flex gap-1 justify-center py-1">
												<button
													className="border rounded px-2"
													onClick={() => {
														moveColumn("left", header.column.id)
													}}
												>
													{"<="}
												</button>
												<button
													className="border rounded px-2"
													onClick={() => {
														moveColumn("right", header.column.id)
													}}
												>
													{"=>"}
												</button>
											</div>
										</>
									)}
								</th>
							))}
							{renderSelectionHeader()}
							{renderActionColumnHeader()}
						</tr>
					))}
				</thead>
				<tbody>
					{moveExpiredRatesToBottom(oceanTable.getRowModel().rows).map((row) => {
						return (
							<tr key={row.id} className={!readonly ? getRowClass(row.original) : ""}>
								{renderActionColumn(row)}
								{renderSelectionColumn(row)}
								{row.getVisibleCells().map((cell) => {
									return (
										<td key={cell.id} className="table-td">
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									)
								})}
								{renderSelectionColumn(row)}
								{renderActionColumn(row)}
							</tr>
						)
					})}
				</tbody>
			</table>
		</>
	)
}

export default OceanRateTable
