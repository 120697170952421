import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"

export const useAdminService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		getAdminTable: (tableName: string) => {
			tableName = tableName.replace(" ", "_")
			return apiContext.get(`admin-table/${tableName}`, source.token)
		},
		addRecord: (tableName: string, data: any) => {
			tableName = tableName.replace(" ", "_")
			return apiContext.post(`admin-table/${tableName}`, data, source.token)
		},
		updateRecord: (tableName: string, data: any) => {
			tableName = tableName.replace(" ", "_")
			return apiContext.post(`admin-table/${tableName}/${data.id}`, data, source.token)
		},
		deleteRecord: (tableName: string, id: any) => {
			tableName = tableName.replace(" ", "_")
			return apiContext.del(`admin-table/${tableName}/${id}`, source.token)
		},
		getUsersTable: () => {
			return apiContext.get("user/", source.token)
		},
		addUser: (data: any) => {
			return apiContext.post("user/", data, source.token)
		},
		updateUser: (data: any) => {
			return apiContext.post(`user/${data.id}`, data, source.token)
		},
		deleteUser: (id: any) => {
			return apiContext.del(`user/${id}`, source.token)
		},
		getRateSheetTable: () => {
			return apiContext.get("rate-sheet/content/", source.token)
		},
		updateRateSheetContent: (data: any) => {
			return apiContext.post(`rate-sheet/content/${data.id}`, data, source.token)
		},
		locodeSearch: (lookupString: string) => {
			const queryParams = { filterString: lookupString.toLowerCase() }
			const queryString = new URLSearchParams(queryParams).toString()
			return apiContext.get(`locodes/?${queryString}`, source.token)
		},
		getInternalContactsDropdowns: () => {
			return apiContext.get("dropdown/admin-internal-contacts", source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
	}

	return API
}

export default useAdminService
