/* eslint-disable array-callback-return */
import "./CreateGRI_GRD.scss"
import "../ControlTower.scss"
import React, { Fragment, useEffect, useState } from "react"
import RateTable from "../RateTable/RateTable"
import ClientLookup from "../ClientLookup/ClientLookup"
import ControlDropdowns from "../DropdownsSection/ControlDropdowns"
import { trackPromise } from "react-promise-tracker"
import useControlTowerService from "Services/ControlTowerService"
import { useNavigate } from "react-router-dom"

export interface IInclClientName {
	inclusionClients: {
		show: boolean
		client: string
	}[]
	disable: boolean
	[key: string]: any
}
export interface IExclClientName {
	exclusionClients: {
		show: boolean
		client: string
	}[]
	disable: boolean
	[key: string]: any
}
export interface IInclClientID {
	inclusions: {
		id: string
		rateType: string
		listId: number
	}[]
	[key: string]: any
}
export interface IExclClientID {
	exclusions: {
		id: string
		rateType: string
		listId: number
	}[]
	[key: string]: any
}
export interface IGRIObject {
	object: any
	[key: string]: any
}
const TableRows = [
	{ routing: "EC", rate20: 0, rate40: 0, rate40hc: 0, rate45: 0, listId: Date.now() },
	{ routing: "WC", rate20: 0, rate40: 0, rate40hc: 0, rate45: 0, listId: Date.now() },
	{ routing: "IPI", rate20: 0, rate40: 0, rate40hc: 0, rate45: 0, listId: Date.now() },
	{ routing: "RIPI", rate20: 0, rate40: 0, rate40hc: 0, rate45: 0, listId: Date.now() },
	{ routing: "GC", rate20: 0, rate40: 0, rate40hc: 0, rate45: 0, listId: Date.now() },
]

export interface IProps {
	dropdowns: any
}

const CreateGRIGRD: React.FC<IProps> = ({ dropdowns }) => {
	const navigate = useNavigate()

	let inclusionClient = false
	const [applyTo, setApplyTo] = React.useState<string>("")
	const [trade, setTrade] = React.useState<string>("")
	const [customerInclusions, setCustomerInclusions] = useState<IInclClientID>({
		inclusions: [
			{
				id: "",
				rateType: "Variable",
				listId: Date.now(),
			},
		],
	})
	const [customerExclusions, setCustomerExclusions] = useState<IExclClientID>({
		exclusions: [
			{
				id: "",
				rateType: "Variable",
				listId: Date.now(),
			},
		],
	})
	const [griTableRows, setGriTableRows] = useState([])
	const [rateTypeRows, setRateTypeRows] = useState([])
	const [tradeRows, setTradeRows] = useState([])
	const [inclusionShow, setInclusionShow] = useState<IInclClientName>({
		inclusionClients: [
			{
				show: false,
				client: "",
			},
		],
		disable: false,
	})
	const [exclusionShow, setExclusionShow] = useState<IExclClientName>({
		exclusionClients: [
			{
				show: false,
				client: "",
			},
		],
		disable: false,
	})
	const [disableToggle, setDisableToggle] = useState<boolean>(false)
	const [griFormDisable, setGriFormDisable] = useState<boolean>(false)

	const controlTowerService = useControlTowerService()

	useEffect(() => {
		const tableRows = TableRows.map((item, index) => {
			return { ...item, listId: Date.now() + index }
		})
		const rateTypeRows = dropdowns.applyToOptions.map((item) => {
			return { ...item, listId: item.id }
		})
		const tradeRows = dropdowns.trades.map((item) => {
			return { ...item, listId: item.id }
		})
		setGriTableRows(tableRows)
		setRateTypeRows(rateTypeRows)
		setTradeRows(tradeRows)
		// eslint-disable-next-line
	}, [])

	function rateTypeSelected(event: any) {
		const rateType = event.target.value
		setApplyTo(rateType)
		if (rateType === "All Fixed" || rateType === "All Variable") {
			defaultRateType(rateType)
			setDisableToggle(true)
			setInclusionShow({ ...inclusionShow, disable: true })
			setExclusionShow({ ...exclusionShow, disable: false })
		} else if (rateType === "All Customers") {
			defaultRateType(rateType)
			setDisableToggle(false)
			setInclusionShow({ ...inclusionShow, disable: true })
			setExclusionShow({ ...exclusionShow, disable: false })
		} else {
			setDisableToggle(false)
			setInclusionShow({ ...inclusionShow, disable: false })
			setExclusionShow({ ...exclusionShow, disable: true })
		}
	}

	function tradeTypeSelected(event: any) {
		setTrade(event.target.value)
	}

	function addRow(type: string) {
		if (type === "inclusion") {
			setCustomerInclusions({
				inclusions: customerInclusions.inclusions.concat([
					{ id: "", rateType: "Variable", listId: Date.now() },
				]),
			})
			setInclusionShow({
				inclusionClients: inclusionShow.inclusionClients.concat([{ show: false, client: "" }]),
				disable: false,
			})
		} else {
			applyTo === "All Fixed"
				? setCustomerExclusions({
						exclusions: customerExclusions.exclusions.concat([
							{ id: "", rateType: "Fixed", listId: Date.now() },
						]),
				  })
				: setCustomerExclusions({
						exclusions: customerExclusions.exclusions.concat([
							{ id: "", rateType: "Variable", listId: Date.now() },
						]),
				  })
			setExclusionShow({
				exclusionClients: exclusionShow.exclusionClients.concat([{ show: false, client: "" }]),
				disable: false,
			})
		}
	}

	function deleteRow(index: number, type: string) {
		if (type === "inclusion") {
			customerInclusions.inclusions.splice(index, 1)
			inclusionShow.inclusionClients.splice(index, 1)
		} else {
			customerExclusions.exclusions.splice(index, 1)
			exclusionShow.exclusionClients.splice(index, 1)
		}
		setCustomerInclusions({ inclusions: customerInclusions.inclusions })
		setCustomerExclusions({ exclusions: customerExclusions.exclusions })
		setInclusionShow({
			inclusionClients: inclusionShow.inclusionClients,
			disable: inclusionShow.disable,
		})
		setExclusionShow({
			exclusionClients: exclusionShow.exclusionClients,
			disable: exclusionShow.disable,
		})
	}

	function setClientName(status: any, type: string) {
		if (type === "inclusion") setInclusionShow(status)
		else setExclusionShow(status)
	}

	function updateClient(status: any, type: string) {
		if (type === "inclusion") setCustomerInclusions(status)
		else setCustomerExclusions(status)
	}

	function checkInclusions() {
		if (applyTo === "Inclusions Only") {
			customerInclusions.inclusions.some((row) => {
				if (row.id) return (inclusionClient = true)
				else inclusionClient = false
			})
		}
	}

	function handleChange(event: any, key: any, index: number) {
		const prevValue = griTableRows[index][key]
		const value = event.target.value
		const cleanValue = !value.match(/^[-]?[0-9]*$/) ? prevValue : value
		const tableRows = [...griTableRows]
		griTableRows[index][key] = cleanValue
		setGriTableRows(tableRows)
	}

	function defaultRateType(type: string) {
		customerExclusions.exclusions.map(({ id, rateType }, index: number) => {
			const status = { ...customerExclusions }
			if (type === "All Fixed") {
				status.exclusions[index].rateType = "Fixed"
				return setCustomerExclusions(status)
			} else {
				status.exclusions[index].rateType = "Variable"
				return setCustomerExclusions(status)
			}
		})
	}

	async function apply() {
		setGriFormDisable(true)
		let sentGriObject
		const clientType =
			applyTo === "Inclusions Only" ? customerInclusions.inclusions : customerExclusions.exclusions
		const clients = JSON.parse(JSON.stringify([...clientType]))
		const rateChanges = JSON.parse(JSON.stringify([...griTableRows]))
		rateChanges.map((row: any, index: number) => {
			Object.keys(row).map((key: any) => {
				if (key === "listId") {
					delete rateChanges[index][key]
				}
			})
		})
		clients.map((row: any, index: number) => {
			Object.keys(row).map((key: any) => {
				if (key === "listId") {
					delete clients[index][key]
				}
			})
		})
		if (applyTo === "All Customers") sentGriObject = { applyTo, trade, clients, rateChanges }
		else if (applyTo === "Inclusions Only") sentGriObject = { applyTo, trade, clients, rateChanges }
		else sentGriObject = { applyTo, trade, clients, rateChanges }
		trackPromise(
			controlTowerService
				.getGriPreview(sentGriObject)
				.then((griGrdObject) => {
					setGriFormDisable(false)
					navigate("/app/control-tower/review", {
						state: { griObject: griGrdObject.data, applyTo: applyTo, trade: trade },
					})
				})
				.catch((error) => {
					setGriFormDisable(false)
				}),
		)
	}

	return (
		<Fragment>
			<form id="gri-form">
				<fieldset disabled={griFormDisable}>
					<ControlDropdowns
						rateSelected={rateTypeSelected}
						rateDropdown={rateTypeRows}
						applyTo={applyTo}
						disabled={false}
						tradeSelected={tradeTypeSelected}
						tradeDropdown={tradeRows}
						trade={trade}
					></ControlDropdowns>
					{applyTo && !inclusionShow.disable && (
						<fieldset disabled={inclusionShow.disable}>
							<div className="form-inline control-label">
								<label id="label-incl">Inclusions</label>
								<button
									className="btn-add"
									disabled={inclusionShow.disable}
									onClick={() => addRow("inclusion")}
									type="button"
								>
									<i className="fa fa-plus" aria-hidden="true"></i>
								</button>
							</div>
							{customerInclusions.inclusions.map(({ id, rateType, listId }, index: number) => (
								<div className="form-inline" key={listId}>
									<ClientLookup
										inclClientID={customerInclusions}
										exclClientID={customerExclusions}
										inclClientName={inclusionShow}
										exclClientName={exclusionShow}
										setClientName={setClientName}
										updateClient={updateClient}
										disable={null}
										index={index}
										type={"inclusion"}
									></ClientLookup>
									<button
										className="btn-remove"
										disabled={customerInclusions.inclusions.length === 1 || inclusionShow.disable}
										onClick={() => deleteRow(index, "inclusion")}
										type="button"
									>
										<i className="fa fa-trash" aria-hidden="true"></i>
									</button>
								</div>
							))}
						</fieldset>
					)}
					{applyTo && !exclusionShow.disable && (
						<fieldset disabled={exclusionShow.disable}>
							<div className="form-inline">
								<label id="label-incl">Exclusions</label>
								<button
									className="btn-add"
									disabled={exclusionShow.disable}
									onClick={() => addRow("exclusion")}
									type="button"
								>
									<i className="fa fa-plus" aria-hidden="true"></i>
								</button>
							</div>
							{customerExclusions.exclusions.map(({ id, rateType, listId }, index: number) => (
								<div className="form-inline" key={listId}>
									<ClientLookup
										inclClientID={customerInclusions}
										exclClientID={customerExclusions}
										inclClientName={inclusionShow}
										exclClientName={exclusionShow}
										setClientName={setClientName}
										updateClient={updateClient}
										disable={disableToggle}
										index={index}
										type={"exclusion"}
									></ClientLookup>
									<button
										className="btn-remove"
										disabled={customerExclusions.exclusions.length === 1 || exclusionShow.disable}
										onClick={() => deleteRow(index, "exclusion")}
										type="button"
									>
										<i className="fa fa-trash" aria-hidden="true"></i>
									</button>
								</div>
							))}
						</fieldset>
					)}
					<RateTable
						rateTable={griTableRows}
						bafTable={null}
						updateTable={handleChange}
						base={false}
					></RateTable>
					{checkInclusions()}
					<button
						disabled={!applyTo || !trade || (applyTo === "Inclusions Only" && !inclusionClient)}
						className="btn btn-primary"
						onClick={apply}
						type="button"
					>
						Apply
					</button>
				</fieldset>
			</form>
		</Fragment>
	)
}

export default CreateGRIGRD
