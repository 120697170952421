import React, { FC, useState, Fragment, useEffect } from "react"
import "../ClientProfile.scss"
import { Contact } from "../ClientProfile"

export interface IProps {
	clientContext
}

const InternalContacts: FC<IProps> = ({ clientContext }) => {
	const [contact, setContact] = useState({
		name: "",
		email: "",
		position: "",
		rateSheet: false,
	})

	const type = "internal"
	const [selectedIndex, setSelectedIndex] = useState(-1)
	const [newEntry, setNewEntry] = useState<boolean>(false)
	const [contactIndex, setContactIndex] = useState<number>(undefined)
	const [errors, setErrors] = useState<string>("")

	useEffect(() => {
		resetFields()
		selectedIndexFn(-1)
		// eslint-disable-next-line
	}, [clientContext.internalContacts])

	const contactDropdownHandler = (event: any) => {
		const contactEmail = event.target.value
		const selectedContact = clientContext.internalContacts.find((c) => c.email === contactEmail)
		if (!selectedContact) {
			setContact({ ...contact, name: "", email: "", position: "" })
		} else {
			delete selectedContact.id
			setContact({ ...contact, ...selectedContact })
		}
	}

	const inputHandler = (event: any) => {
		const newVal = { ...contact, [event.target.name]: event.target.value }
		if (event.target.name === "rateSheet") {
			newVal.rateSheet = event.target.checked
		}
		setContact(newVal)
	}

	const updateContacts = (contacts: any) => {
		clientContext.updateContactsFn(contacts, type)
	}

	const addContact = (event: any) => {
		event.preventDefault()
		const newCont = [...clientContext.client.contacts.internal, contact]
		updateContacts(newCont)
		resetFields()
	}

	const removeContact = (i: number) => {
		let arr = [...clientContext.client.contacts.internal]
		arr = arr.filter((_, index) => index !== i)
		updateContacts(arr)
		resetFields()
	}

	const editContact = (
		index: number,
		name: string,
		email: string,
		position: string,
		rate: boolean,
	) => {
		setSelectedIndex(index)
		setContact({ name: name, email: email, position: position, rateSheet: rate })
		setContactIndex(index)
	}

	const updateContact = () => {
		const arr = [...clientContext.client.contacts.internal]
		arr[contactIndex] = contact
		updateContacts(arr)
		resetFields()
	}

	const resetFields = () => {
		setContact({ name: "", email: "", position: "", rateSheet: false })
		setNewEntry(false)
		setErrors("")
	}

	const display = () => {
		setNewEntry(true)
	}

	const selectedIndexFn = (index) => {
		if (index !== -1) {
			setSelectedIndex(index)
		} else {
			resetFields()
			setSelectedIndex(-1)
		}
	}

	const clientContactOptions = () => {
		if (
			Array.isArray(clientContext.client.contacts.internal) &&
			clientContext.client.contacts.internal.length
		) {
			const optionList = clientContext.internalContacts.filter(
				(contactItem) =>
					clientContext.client.contacts.internal.filter(
						(selectedItem) => contactItem.email === selectedItem.email,
					).length === 0,
			)
			return (
				<Fragment>
					{optionList.map((opt) => (
						<option key={opt.email} value={opt.email}>
							{opt.name}
						</option>
					))}
				</Fragment>
			)
		}

		return (
			<Fragment>
				{clientContext.internalContacts.map((opt) => (
					<option key={opt.email} value={opt.email}>
						{opt.name}
					</option>
				))}
			</Fragment>
		)
	}

	const contactCells = () => {
		if (clientContext.client.contacts.internal.length) {
			return clientContext.client.contacts.internal.map((c: Contact, index) => (
				<tr key={index}>
					<td>
						{selectedIndex === index ? (
							<select
								name="name"
								value={contact.email}
								className="form-control"
								onChange={contactDropdownHandler}
							>
								<option>-- Select Contact --</option>
								{clientContactOptions()}
							</select>
						) : (
							c.name
						)}
					</td>
					<td>{selectedIndex === index ? contact.email : c.email}</td>
					<td>{selectedIndex === index ? contact.position : c.position}</td>
					<td>
						{selectedIndex === index ? (
							<input
								type="checkbox"
								name="rateSheet"
								checked={contact.rateSheet}
								onChange={(event) => {
									inputHandler(event)
								}}
							/>
						) : c.rateSheet ? (
							"YES"
						) : (
							"NO"
						)}
					</td>
					<td className="act-col">
						<div className="action-cell">
							<button
								type="button"
								disabled={contact.name === "" || contact.email === "" || errors !== ""}
								hidden={!(selectedIndex === index)}
								className="save-btn"
								onClick={updateContact}
							>
								<i className="fa fa-floppy-o"></i>
							</button>
							<button
								type="button"
								hidden={!(selectedIndex === index)}
								className="trash-cancel-btn"
								onClick={() => selectedIndexFn(-1)}
							>
								<i className="fa fa-ban"></i>
							</button>
							<button
								type="button"
								className="edit-btn"
								disabled={newEntry}
								hidden={selectedIndex === index}
								onClick={() =>
									editContact(
										index,
										c.name,
										c.email,
										c.position ? c.position : "",
										c.rateSheet ? c.rateSheet : false,
									)
								}
							>
								<i className="fa fa-pencil"></i>
							</button>
							<button
								type="button"
								className="trash-cancel-btn"
								disabled={newEntry}
								hidden={selectedIndex === index}
								onClick={() => removeContact(index)}
							>
								<i className="fa fa-trash"></i>
							</button>
						</div>
					</td>
				</tr>
			))
		} else {
			return (
				<tr key={-1}>
					<td colSpan={5} className="no-contacts">
						{" "}
						No Internal Contacts{" "}
					</td>
				</tr>
			)
		}
	}

	return (
		<Fragment>
			<div className="card contact-wrapper">
				<div className="card-header">
					Internal Contacts
					<button
						type="button"
						className="add-btn"
						onClick={display}
						disabled={newEntry || selectedIndex !== -1}
					>
						<i className="fa fa-plus"></i>
					</button>
				</div>
				<div className="card-body">
					<div className="col contacts-table">
						<table className="table table-bordered">
							<thead className="thead-light">
								<tr>
									<th scope="col">Name</th>
									<th scope="col">Email</th>
									<th scope="col">Position</th>
									<th scope="col">Email Rate Sheet</th>
									<th scope="col"></th>
								</tr>
								<tr hidden={!newEntry} className="newEntry">
									<th>
										<select
											name="name"
											value={contact.email}
											className="form-control"
											onChange={contactDropdownHandler}
										>
											<option>-- Select Contact --</option>
											{clientContactOptions()}
										</select>
									</th>
									<th>{contact.email}</th>
									<th>{contact.position}</th>
									<th>
										<input
											type="checkbox"
											name="rateSheet"
											checked={contact.rateSheet}
											onChange={inputHandler}
										/>
									</th>
									<th>
										<div className="action-cell">
											<button
												type="button"
												disabled={
													contact.name === "" ||
													contact.email === "" ||
													contact.position === "" ||
													errors !== ""
												}
												className="save-btn"
												onClick={addContact}
											>
												<i className="fa fa-floppy-o"></i>
											</button>
											<button type="button" className="trash-cancel-btn" onClick={resetFields}>
												<i className="fa fa-ban"></i>
											</button>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>{contactCells()}</tbody>
						</table>
					</div>
					<p hidden={errors === ""} className="errors">
						{errors}
					</p>
				</div>
			</div>
		</Fragment>
	)
}

export default InternalContacts
