import React, { useState } from "react"
import { useTable, useSortBy } from "react-table"
import useBuyRateService from "Services/BuyRateService"
import { DefaultCell } from "Pages/TableHeaders"
import RatePusher from "../RatePusher"

export interface IProps {
	data
	dispatcher
}

const Table: React.FC<IProps> = ({ data, dispatcher }) => {
	const initialTableState = { sortBy: [{ id: "total", asc: true }] }
	const tableColumns = [
		{
			Header: "Provider",
			accessor: "provider",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Destination CY",
			accessor: "destinationCy",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Delivery City",
			accessor: "deliveryCity",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Delivery State",
			accessor: "deliveryState",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Delivery Zip",
			accessor: "deliveryZip",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Base Rate FSC",
			accessor: "fsc",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Total",
			accessor: "total",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Notes",
			accessor: "notes",
			Cell: (props) => <DefaultCell {...props} />,
		},
		{
			Header: "Attachment",
			Cell: (props) => <AttachmentCell {...props} />,
		},
		{
			Header: "Actions",
			Cell: (props) => <ActionsCell {...props} dispatcher={dispatcher} />,
		},
	]

	const columns = React.useMemo(() => tableColumns, [])

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: initialTableState,
		},
		useSortBy,
	)

	return (
		<div className={"buy-rate-table-wrapper"}>
			<div className={"table-overflow-wrapper"}>
				<table {...getTableProps()} className={"table table-bordered"}>
					<thead className={"thead-light"}>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										{column.isSorted ? (
											column.isSortedDesc ? (
												<i
													className="sort-icon fa fa-sort-amount-desc fa-md"
													aria-hidden="true"
												></i>
											) : (
												<i className="sort-icon fa fa-sort-amount-asc fa-md" aria-hidden="true"></i>
											)
										) : null}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td className={"table-td"} {...cell.getCellProps()}>
												{cell.render("Cell")}
											</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

function AttachmentCell(props) {
	const buyRateService = useBuyRateService()
	const cellIndex = props.cell.row.index
	const attachment = props.data[cellIndex]
	const noAttachment = !attachment.attachmentId

	function downloadAttachment() {
		if (noAttachment) return

		buyRateService.downloadDrayAttachment(attachment.attachmentId).then((data) => {
			const url = window.URL.createObjectURL(new Blob([data]))
			const link = document.createElement("a")
			link.href = url
			link.setAttribute(
				"download",
				attachment.attachmentName ? attachment.attachmentName : "attachment",
			)
			document.body.appendChild(link)
			link.click()
			link.parentNode.removeChild(link)
		})
	}

	return (
		<button
			className={"btn btn-link download-btn"}
			type={"button"}
			onClick={() => downloadAttachment()}
			disabled={noAttachment}
		>
			Download
		</button>
	)
}

function ActionsCell(props) {
	const cellIndex = props.cell.row.index
	const data = props.data[cellIndex]

	const [showRatePusher, setShowRatePusher] = useState(false)

	function openRatePusher() {
		setShowRatePusher(true)
	}

	return (
		<div>
			<button
				type={"button"}
				className={"btn btn-link"}
				onClick={() => props.dispatcher({ type: "EDIT_RATE", payload: data })}
			>
				Edit
			</button>
			<button type={"button"} className={"btn btn-link"} onClick={openRatePusher}>
				Push
			</button>
			{showRatePusher ? (
				<RatePusher type={"DRAY"} rateData={data} close={() => setShowRatePusher(false)} />
			) : null}
		</div>
	)
}

export default Table
