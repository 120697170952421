import React, { useContext } from "react"
import { marginTableHeaders } from "Pages/TableHeaders"
import Table from "Shared/Table/Table"
import MarginAnalysisTable from "Contexts/MarginAnalysis/MarginAnalysisPopout/MarginAnalysisTable"
import MarginAnalysisRateInputs from "Contexts/MarginAnalysis/MarginAnalysisPopout/MarginAnalysisRateInputs"
import MarginAnalysisContext from "Contexts/MarginAnalysis/Context"
import Modal from "Shared/Modal/Modal"

import "./MarginAnalysisPopout.scss"

const MarginAnalysisPopout: React.FC = () => {
	const maContext = useContext(MarginAnalysisContext)
	const columns = React.useMemo(() => marginTableHeaders(), [])
	const data = React.useMemo(() => maContext.buyRates, [maContext.buyRates])

	return (
		maContext.showMarginAnalysis && (
			<Modal>
				<div className="buy-rate-page-wrapper">
					<h1>Margin Analysis Details</h1>
					<hr id="hr-m" />
					<div className="container">
						<div className="row">
							<div className="init col-lg-3 col-md-2">
								<table className="initTable table table-bordered table-sm">
									<thead className="initHeader thead-light">
										<tr>
											<th scope="col">POL</th>
											<th scope="col">POD</th>
											<th scope="col">Dest. CY</th>
										</tr>
									</thead>
									<tbody>
										<tr className="initHeader">
											<td className="initTd">
												<span>{maContext.rate.portOfLoad.name}</span>
											</td>
											<td className="initTd">
												<span>{maContext.rate.portOfDischarge.name}</span>
											</td>
											<td className="initTd">
												<span>{maContext.rate.destinationCy.name}</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<MarginAnalysisRateInputs rate={maContext.rate} handleInput={maContext.handleInput} />

							<div className="minMax col-lg-3 col-md-2">
								<table className="minMaxTable table table-bordered table-sm">
									<thead className="minMaxHeader thead-light">
										<tr>
											<th scope="col">Min</th>
											<th scope="col">Max</th>
										</tr>
									</thead>
									<tbody>
										<tr className="minMaxHeader">
											<td className="minMaxTd">
												<div className="input-group input-group-sm">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="fa fa-dollar" aria-hidden="true"></i>
														</span>
													</div>
													<span className="minMaxSpan">{maContext.marginMin}</span>
												</div>
											</td>
											<td className="minMaxTd">
												<div className="input-group input-group-sm">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="fa fa-dollar" aria-hidden="true"></i>
														</span>
													</div>
													<span className="minMaxSpan">{maContext.marginMax}</span>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<MarginAnalysisTable data={maContext.marginAnalysisData} />

					<div className="container">
						<div className="row">
							<div className="table-div col-12">
								<Table columns={columns} data={data} state={""} gri={true} download={null} />
							</div>
						</div>
					</div>

					<div className="container">
						<div className="row">
							<div className="col-md-5"></div>
							<div className="buttonDiv col-md-3">
								<button
									className="btn btn-danger col-12 pull-right"
									onClick={() => maContext.close()}
								>
									Cancel
								</button>
							</div>
							<div className="col-md-1"></div>
							<div className="buttonDiv col-md-3">
								<button
									className="btn btn-primary col-12 pull-right"
									onClick={() => maContext.save()}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	)
}

export default MarginAnalysisPopout
