import React from "react"
import { Input } from "reactstrap"

function SelectFilter({ filter, updateFilter }) {
	function updateValue(e: any) {
		const updatedFilter = { ...filter }
		updateFilter.value = e.target.value
		updateFilter(updatedFilter)
	}

	return (
		<div>
			<Input type="select" name={filter.field} multiple onChange={updateValue}>
				{filter.options && filter.options.length
					? filter.options.map((o) => (
							<option value={o.value} key={o.label}>
								{o.label}
							</option>
					  ))
					: null}
			</Input>
		</div>
	)
}

export default SelectFilter
