import React from "react"

function MarginAnalysisRateInputs({ rate, handleInput }) {
	return (
		<div className="sellRates col-lg-6 col-md-8">
			<table className="sellTable table table-bordered table-sm">
				<thead className="sellHeader thead-light">
					<tr>
						<th className="sellTh" scope="col">
							Sell Rates
						</th>
						<th scope="col">20&apos;</th>
						<th scope="col">40&apos;</th>
						<th scope="col">40&apos;HC</th>
						<th scope="col">45&apos;</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td></td>
						<td className="sellTd">
							<RateInput
								name={"rate20"}
								value={rate.rate20.sellAmount}
								handleInput={handleInput}
								marginStatus={rate.rate20.status}
							/>
						</td>
						<td className="sellTd">
							<RateInput
								name={"rate40"}
								value={rate.rate40.sellAmount}
								handleInput={handleInput}
								marginStatus={rate.rate40.status}
							/>
						</td>
						<td className="sellTd">
							<RateInput
								name={"rate40hc"}
								value={rate.rate40hc.sellAmount}
								handleInput={handleInput}
								marginStatus={rate.rate40hc.status}
							/>
						</td>
						<td className="sellTd">
							<RateInput
								name={"rate45"}
								value={rate.rate45.sellAmount}
								handleInput={handleInput}
								marginStatus={rate.rate45.status}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

function RateInput({ handleInput, value, name, marginStatus }) {
	return (
		<div className="input-group input-group-sm">
			<div className="input-group-prepend">
				<span className="input-group-text">
					<i className="fa fa-dollar" aria-hidden="true"></i>
				</span>
			</div>
			<input
				autoComplete="off"
				className="form-control"
				style={{ borderWidth: 2, borderColor: marginStatus === "ok" ? "green" : "red" }}
				name={name}
				onChange={(event) => handleInput(event, name)}
				placeholder="0"
				type="number"
				value={value}
				key={`mga-rate-input-${name}`}
			></input>
		</div>
	)
}

export default MarginAnalysisRateInputs
