import React, { useState, useEffect, useContext, Fragment } from "react"

import { trackPromise } from "react-promise-tracker"
import Table from "Shared/Table/Table"
import Modal from "Shared/Modal/Modal"
import "Pages/BuyRates/Page.scss"
import { buyRatesTableHeaders } from "Pages/TableHeaders"
import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"

import useBuyRateService from "Services/BuyRateService"
import UserContext from "../../../Contexts/User/Context"
import AdvancedSearch from "Shared/DynamicSearch/DynamicSearch"
import { Filterable } from "../../../Shared/DynamicSearch/index"

const initialState = {
	rates: [],
}

function BuyRateSearchPage({ setHeaderTitle }) {
	const user = useContext(UserContext)
	const columns = React.useMemo(() => buyRatesTableHeaders(), [])
	const [state, setState] = useState(initialState)
	const [dataset, setDataset] = useState(null)
	const tableIntialState = { sortBy: [{ id: "hq40", asc: true }] }
	const buyRateService = useBuyRateService()

	useEffect(() => {
		setHeaderTitle("Ocean Rate Search")
		trackPromise(
			buyRateService.getBuyRateDataset().then((dataset) => {
				setDataset(dataset)
			}),
		)

		return () => {
			buyRateService.abortRequests()
		}
		// eslint-disable-next-line
	}, [setHeaderTitle])

	function setDataFN(filters) {
		trackPromise(
			buyRateService.lookupRates(filters).then((rates) => {
				setState({ rates: rates })
			}),
		)
	}

	const disableIfNoFilters = (currentFilters: Filterable[]): boolean => {
		if (currentFilters.length === 0) {
			return true
		}
		const appliedFilters = currentFilters.filter((f) => f.applied === true)
		if (appliedFilters.length) {
			return !appliedFilters.every((f) => (f.value ? true : false))
		}
		return true
	}

	const data = React.useMemo(() => state.rates, [state.rates])

	return (
		<div className="buy-rate-page-wrapper">
			<div className="buy-rate-search-wrapper">
				<LoadingIndicator />
				<div className="button-row">
					<AdvancedSearch dataset={dataset} onApply={setDataFN} disableApply={disableIfNoFilters} />
					{user.details.role === "Admin" ? <RateImporter></RateImporter> : null}
				</div>
			</div>
			{data.length ? (
				<Table columns={columns} data={data} state={tableIntialState} gri={false} download={null} />
			) : null}
		</div>
	)
}

function RateImporter() {
	const user = useContext(UserContext)
	const buyRateService = useBuyRateService()

	const [state, setState] = useState({ agentOptions: [], selectedAgent: null })
	const [modal, setModal] = useState<boolean>(false)
	const toggle = () => setModal((prevState) => !prevState)

	useEffect(() => {
		buyRateService.getAgents().then((agents) => {
			setState({ ...state, agentOptions: agents })
		})
		return () => {
			buyRateService.abortRequests()
		}
		// eslint-disable-next-line
	}, [])

	function sendImportFile(file: any) {
		trackPromise(
			buyRateService.uploadBuyRate(state.selectedAgent, file).then((res) => {
				toggle()
				setState({ ...state, selectedAgent: "" })
			}),
		)
	}

	const fileSelected = (event: any) => {
		const blob = event.target.files[0]
		const reader = new FileReader()
		reader.readAsArrayBuffer(blob)
		reader.onloadend = function () {
			sendImportFile(reader.result)
		}
	}

	function onSelectAgent(event: any) {
		setState({ ...state, selectedAgent: event.target.value })
	}

	return (
		<Fragment>
			{user.details.role === "Admin" ? (
				<button id="openImportModal" type="button" className="btn btn-primary" onClick={toggle}>
					Import Excel File
				</button>
			) : null}
			{modal ? (
				<Modal>
					<div>
						{" "}
						Please select the Agent and file to be imported.
						<br />
						Import process will start when user selects file
					</div>
					<br />
					Agent*:
					<select onChange={onSelectAgent} defaultValue={state.selectedAgent}>
						<option disabled value="">
							-- Select Agent --
						</option>
						{state.agentOptions.map((opt, index) => (
							<option key={index} value={opt}>
								{opt}
							</option>
						))}
					</select>
					<br />
					<input
						type="file"
						title=""
						accept=".xls,.xlsx"
						className="form-control-file"
						name="buyRateImport"
						disabled={!state.selectedAgent}
						onChange={fileSelected}
					/>
					<br />
					<button
						id="closeModal"
						type="button"
						className="btn btn-secondary"
						onClick={() => {
							toggle()
							setState({ ...state, selectedAgent: "" })
						}}
					>
						Cancel
					</button>
				</Modal>
			) : null}
		</Fragment>
	)
}

export default BuyRateSearchPage
