function propertyLookup(path: string | string[], obj: any) {
	const pathPartials = Array.isArray(path) ? path : path.split(".")
	const value = pathPartials.reduce(
		(previousValue, currentVal) => previousValue && previousValue[currentVal],
		obj,
	)

	return value
}

export const SET_MANAGER = {
	addItemTo: function (set: any[], item: any, comparisonKeyPath: string | string[]): any[] {
		if (!set || !item || !comparisonKeyPath) return

		const updatedSet = [...set]
		const itemCopy = { ...item }

		const matchedIndex = updatedSet.findIndex(
			(i) => propertyLookup(comparisonKeyPath, i) === propertyLookup(comparisonKeyPath, itemCopy),
		)

		if (matchedIndex > -1) {
			updatedSet.splice(matchedIndex, 1, itemCopy)
		} else {
			updatedSet.push(itemCopy)
		}

		return updatedSet
	},
	deleteItemFrom(set: any[], item: any, comparisonKeyPath: string | string[]): any[] {
		if (!set || !set.length || !item || !comparisonKeyPath) return

		const updatedSet = set.filter(
			(i) => propertyLookup(comparisonKeyPath, i) !== propertyLookup(comparisonKeyPath, item),
		)

		return updatedSet
	},
}

export interface FilterableFieldOption {
	value: string
	label: string
}

export interface FilterableDate {
	fromDate: Date
	toDate: Date
	type: string
}

export interface FilterableCityStateZip {
	city: string
	state: string
	zip: string
}

export interface FilterableNumber {
	type: string
	min: null | string | number
	max: null | string | number
}

export interface Filterable {
	field: string
	label: string
	type: string
	value: null | string | boolean | FilterableDate | FilterableCityStateZip | FilterableNumber
	options?: null | FilterableFieldOption[]
	required?: null | boolean
	applied?: boolean
}

export interface SearchFilterSet {
	name: string
	filters: Filterable[]
}
