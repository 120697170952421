import React from "react"
import { useTable, useSortBy } from "react-table"
import "./Table.scss"

export interface IProps {
	columns
	data
	state
	gri
	download
}

const Table: React.FC<IProps> = ({ columns, data, state, gri, download }) => {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: state,
		},
		useSortBy,
	)

	return (
		<div className={"buy-rate-table-wrapper"}>
			<div className={gri ? "gri-table-wrapper table-overflow-wrapper" : "table-overflow-wrapper"}>
				<table {...getTableProps()} className={"table table-bordered"}>
					<thead className={"thead-light"}>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th
										className={gri ? "gri-table" : ""}
										{...column.getHeaderProps(column.getSortByToggleProps())}
									>
										{column.render("Header")}
										{column.isSorted ? (
											column.isSortedDesc ? (
												<i
													className="sort-icon fa fa-sort-amount-desc fa-md"
													aria-hidden="true"
												></i>
											) : (
												<i className="sort-icon fa fa-sort-amount-asc fa-md" aria-hidden="true"></i>
											)
										) : null}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td className={gri ? "gri-table" : "table-td"} {...cell.getCellProps()}>
												{cell.render("Cell")}
											</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Table
