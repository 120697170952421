import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useFormik } from "formik"
import "./ForgotPasswordPage.scss"
import { useNavigate } from "react-router-dom"

interface IProps {
	user: any
}

const ForgotPasswordPage: React.FC<IProps> = ({ user }) => {
	const navigate = useNavigate()
	const [sendingEmail, setSendingEmail] = useState<boolean>(false)

	const validate = (values): any => {
		const errors = {} as any
		if (!values.email) {
			errors.email = "Required"
		} else if (!values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
			errors.email = "Invalid format"
		}
		return errors
	}

	const form = useFormik({
		initialValues: {
			email: "",
		},
		validate,
		onSubmit: (values) => {
			setSendingEmail(true)
			user
				.forgotPassword(values.email)
				.then(() => {
					form.resetForm()
				})
				.finally(() => {
					setSendingEmail(false)
				})
		},
	})

	return (
		<div id="pass-page-wrapper">
			<Helmet>
				<title>Forgot Password | UWL Pricing Tool</title>
			</Helmet>
			<div id="pass-card" className="card">
				<div className="card-header">Forgot Password</div>
				<div className="card-body">
					<div id="pass-form-header">
						<i id="pass-form-icon" className="fa fa-key fa-5x" aria-hidden="true"></i>
						<p id="pass-form-p">
							Enter your email, and we&apos;ll send you a link to reset your password.
						</p>
					</div>
					<hr id="hr-m" />
					<form onSubmit={form.handleSubmit} id="pass-form">
						<fieldset disabled={sendingEmail}>
							<div className="pass-input-wrapper">
								<label htmlFor="pass-email-input">Email</label>
								<input
									autoFocus
									className={`form-control ${
										form.touched.email && form.errors.email ? "has-error" : null
									}`}
									id="pass-email-input"
									name="email"
									onBlur={form.handleBlur}
									onChange={form.handleChange}
									type="email"
									value={form.values.email}
								/>
								{form.touched.email && form.errors.email ? (
									<div className="pass-input-errors">{form.errors.email}</div>
								) : null}
							</div>
							<button
								type="submit"
								className="btn btn-primary"
								id="pass-button"
								disabled={
									!form.touched.email || (form.touched.email && form.errors.email) ? true : false
								}
							>
								{sendingEmail ? (
									<div className="text-center spinner-border text-light" role="status"></div>
								) : (
									"Send Link"
								)}
							</button>
							<button
								type="button"
								className="btn btn-danger"
								id="pass-back-button"
								onClick={() => navigate("/login")}
							>
								Back to Login Page
							</button>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	)
}

export default ForgotPasswordPage
