import React, { useEffect, useState } from "react"
import Modal from "../../Shared/Modal/Modal"
import FilterSelect from "../../Shared/Inputs/FilterSelect/FilterSelect"
import { useNavigate } from "react-router"
import { useSellRateService } from "../../Services/SellRateService"

function RatePusher({ type, rateData, close }) {
	const sellRateService = useSellRateService()

	const [clients, setClients] = useState([])
	const navigate = useNavigate()

	useEffect(() => {
		sellRateService.getClients().then((clients) => {
			setClients(clients)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function pushRateToClientRateSheet(c) {
		const payload = {
			incomingRate: {
				type: type,
				rateData: rateData,
			},
		}

		navigate(`/app/client-sell-rates/${c.id}`, { state: payload })
	}

	function cancel() {
		close()
	}

	return (
		<Modal>
			<h3>Push Rate to Client Rate Sheet</h3>
			<div className={"rate-pusher"}>
				<FilterSelect
					label={"Client"}
					suggestions={clients}
					selectFn={pushRateToClientRateSheet}
					disabled={!clients.length}
				/>
				<button onClick={cancel}>Cancel</button>
			</div>
		</Modal>
	)
}

export default RatePusher
