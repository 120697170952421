import React, { useState, useEffect, useContext } from "react"
import UserContext from "./Context"
import useUserService from "Services/UserService"
import ApiContext from "Contexts/API/Context"

const UserProvider: React.FC = ({ children }) => {
	const userService = useUserService()
	const apiContext = useContext(ApiContext)

	const [loggedIn, setLoggedIn] = useState(JSON.parse(localStorage.getItem("auth"))?.login)
	const [details, setDetails] = useState(JSON.parse(localStorage.getItem("userDetails")))

	const login = async (username: string, password: string) => {
		const loginSuccess = await userService.login(username, password)
		const userDetails = await userService.currentUserDetails()

		if (loginSuccess && userDetails) {
			setDetails(userDetails)
			setLoggedIn(true)
		} else {
			setLoggedIn(false)
		}
		localStorage.setItem("userDetails", JSON.stringify(userDetails))
		return loginSuccess && userDetails
	}

	const resetPassword = async (token: string, password: string): Promise<any> => {
		return userService.resetPassword(token, password)
	}

	const forgotPassword = async (username: string): Promise<any> => {
		return userService.forgotPassword(username)
	}

	const logout = () => {
		localStorage.removeItem("auth")
		localStorage.removeItem("userDetails")
		return userService.logout().then((success) => {
			if (success) {
				setLoggedIn(false)
				setDetails(null)
			}
		})
	}

	useEffect(() => {
		if (apiContext.tokenRevoked) {
			setLoggedIn(false)
		}
	}, [apiContext.tokenRevoked])

	const getUserDetails = () => {
		userService.currentUserDetails().then((userDetails) => {
			if (userDetails) {
				setDetails(userDetails)
				localStorage.setItem("userDetails", JSON.stringify(userDetails))
			}
		})
	}

	const updateUserDetails = (user: any) => {
		return userService.updateUserDetails(user)
	}

	return (
		<UserContext.Provider
			value={{
				loggedIn: loggedIn,
				details: details,
				login: login,
				logout: logout,
				resetPassword: resetPassword,
				forgotPassword: forgotPassword,
				update: updateUserDetails,
				getUserDetails: getUserDetails,
			}}
		>
			{children}
		</UserContext.Provider>
	)
}

export default UserProvider
