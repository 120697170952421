import { useState, useEffect, useContext, Fragment } from "react"
import { Form, Formik, isObject } from "formik"
import * as Yup from "yup"
import { useAlert } from "react-alert"
import { trackPromise } from "react-promise-tracker"

import Select from "Shared/Formik/Select"
import TextInput from "Shared/Formik/TextInput"
import NumberInput from "Shared/Formik/NumberInput"

import LocodeSelect from "Shared/Formik/LocodeSelect/LocodeSelect"
import DatePicker from "react-date-picker"
import { dateFromString, getMinExpirationDate } from "Utilities/DateUtils"
import useSellRateService from "Services/SellRateService"
import MarginAnalysisContext from "Contexts/MarginAnalysis/Context"
import MarginAnalysisPopout from "Contexts/MarginAnalysis/MarginAnalysisPopout/MarginAnalysisPopout"

interface IProps {
	rate: any
	submitHandler: any
	discardHandler: any
	dropdowns: any
	client: any
	clearRateBuffer: any
	prospectiveClient: boolean
}

function OceanRateForm(props: IProps) {
	const sellRateService = useSellRateService()
	const alert = useAlert()

	const defaultRate = {
		effectiveDate: null,
		expirationDate: null,
		pol: null,
		pod: null,
		destinationCy: null,
		type: "",
		agent: props.client.primaryAgent,
		trade: "",
		serviceType: "",
		routing: "",
		carrier: "",
		rate20: 0,
		rate40: 0,
		rate40hc: 0,
		rate45: 0,
		commodity: "",
		nor40: 0,
		re40: 0,
	}

	function dateChange(form: any, key: string, date: Date) {
		form.setFieldValue(key, date)
	}

	function prepareRate(rate: any) {
		if (!isObject(rate.effectiveDate)) {
			const serializedEffectiveDate = rate.effectiveDate ? dateFromString(rate.effectiveDate) : null
			const serializedExpirationDate = rate.expirationDate
				? dateFromString(rate.expirationDate)
				: null
			return {
				...rate,
				effectiveDate: serializedEffectiveDate,
				expirationDate: serializedExpirationDate,
			}
		} else {
			return {
				...rate,
			}
		}
	}

	const marginAnalysisContext = useContext(MarginAnalysisContext)
	const openMarginAnalysis = async (rateFormValue: any) => {
		if (props.prospectiveClient) return

		rateFormValue.clientId = props.client.id
		const params = {
			filters: [
				{ field: "pol", value: rateFormValue.pol.value, applied: true },
				{ field: "destination", value: rateFormValue.destinationCy.value, applied: true },
				{ field: "pod", value: rateFormValue.pod.value, applied: true },
				{ field: "agent", value: rateFormValue.agent, applied: true },
			],
		}
		const analysisResult = await trackPromise(sellRateService.getMarginAnalysis(rateFormValue))
		const buyRates = await trackPromise(sellRateService.getBuyRates(params))

		if (buyRates.length) {
			const sortedRates = buyRates.sort(
				(a, b) =>
					parseFloat(a.hq40?.substring(1, a.hq40.length).replace(",", "")) -
					parseFloat(b.hq40?.substring(1, b.hq40.length).replace(",", "")),
			)
			marginAnalysisContext.openMarginAnalysisViewForSellRates(
				rateFormValue,
				analysisResult,
				0,
				0,
				sortedRates,
				handleRateUpdatesFromMarginAnalysisView,
			)
		} else {
			alert.error("No buy rates found for lane", { timeout: 10000 })
		}
	}

	const handleRateUpdatesFromMarginAnalysisView = (rate, clientIndex, rateIndex) => {
		setFormValue(rate)
	}

	const [formValue, setFormValue] = useState(
		props.rate === null ? defaultRate : prepareRate(props.rate),
	)
	useEffect(() => {
		if (props.rate) {
			setFormValue(prepareRate(props.rate))
			props.clearRateBuffer()
		}
		// eslint-disable-next-line
	}, [props.rate])

	return (
		<Fragment>
			<MarginAnalysisPopout></MarginAnalysisPopout>
			<Formik
				initialValues={formValue}
				enableReinitialize={true}
				validationSchema={Yup.object({
					effectiveDate: Yup.date().required(),
					expirationDate: Yup.date().nullable(),
					pol: Yup.object().required(),
					pod: Yup.object().required(),
					destinationCy: Yup.object().required(),
					type: Yup.string().required(),
					agent: Yup.string(),
					trade: Yup.string().nullable(),
					serviceType: Yup.string().nullable(),
					routing: Yup.string().nullable(),
					carrier: Yup.string(),
					rate20: Yup.number().min(0).integer().nullable(),
					rate40: Yup.number().min(0).integer().nullable(),
					rate40hc: Yup.number().min(0).integer().nullable(),
					rate45: Yup.number().min(0).integer().nullable(),
					commodity: Yup.string().nullable(),
					nor40: Yup.number().min(0).integer().nullable(),
					re40: Yup.number().min(0).integer().nullable(),
					incoTerm: Yup.string().nullable(),
					destinationDoor: Yup.string().nullable(),
				})}
				onSubmit={(values) => props.submitHandler("ocean", values)}
			>
				{(form) => {
					return (
						<Form id={"ocean-rate-form"} autoComplete="off">
							<div className={"form-column justify-start align-start"}>
								<div className={"form-row justify-start align-end"}>
									<div className={"date-picker-wrapper"}>
										<label
											className={
												form.submitCount > 0 && form.errors.effectiveDate ? "red-text" : ""
											}
										>
											Effective Date*
										</label>
										<DatePicker
											value={form.values.effectiveDate}
											format="MM/dd/yyyy"
											onChange={(value: Date) => dateChange(form, "effectiveDate", value)}
										/>
									</div>
									<div className={"date-picker-wrapper"}>
										<label>Expiration Date</label>
										<DatePicker
											value={form.values.expirationDate}
											format="MM/dd/yyyy"
											onChange={(value: Date) => dateChange(form, "expirationDate", value)}
											minDate={getMinExpirationDate(form.values.effectiveDate)}
										/>
									</div>
								</div>
								<div className={"form-row justify-start align-end"}>
									<LocodeSelect
										from_buy_rates={true}
										name={"pol"}
										label={"Port of Load*"}
										form={form}
									/>
									<LocodeSelect
										from_buy_rates={true}
										name={"pod"}
										label={"Port of Discharge*"}
										form={form}
									/>
									<LocodeSelect
										from_buy_rates={true}
										name={"destinationCy"}
										label={"Destination CY*"}
										form={form}
									/>
									<TextInput name="destinationDoor" label="Destination Door" type="text" />
									<Select
										name={"type"}
										label={"Rate Type*"}
										options={props.dropdowns.rateTypes}
										form={form}
									/>
									<Select
										name={"agent"}
										label={"Agent"}
										options={props.dropdowns.agents}
										form={form}
									/>
								</div>
								<div className={"form-row justify-start align-end"}>
									<Select
										name={"trade"}
										label={"Trade"}
										options={props.dropdowns.trades}
										form={form}
									/>
									<Select
										name={"serviceType"}
										label={"Service Type"}
										options={props.dropdowns.serviceTypes}
										form={form}
									/>
									<Select
										name={"routing"}
										label={"Routing"}
										options={props.dropdowns.routingOptions}
										form={form}
									/>
									<Select
										name={"carrier"}
										label={"Carrier"}
										options={props.dropdowns.carriers}
										form={form}
									/>
									<Select
										name="incoTerm"
										label="Inco Term"
										form={form}
										options={props.dropdowns.incoTerms}
									/>
									<TextInput name="commodity" label="Commodity" type="text" />
								</div>
								{!props.prospectiveClient ? (
									<div className={"form-row justify-start align-end"}>
										<div className={"rate-input"}>
											<NumberInput name={"rate20"} label={"Rate - 20'"} type={"number"} />
										</div>
										<div className={"rate-input"}>
											<NumberInput name={"rate40"} label={"Rate - 40'"} type={"number"} />
										</div>
										<div className={"rate-input"}>
											<NumberInput name={"rate40hc"} label={"Rate - 40'HC"} type={"number"} />
										</div>
										<div className={"rate-input"}>
											<NumberInput name={"rate45"} label={"Rate - 45'"} type={"number"} />
										</div>
										<div className={"rate-input"}>
											<NumberInput name={"nor40"} label={"NOR40'"} type={"number"} />
										</div>
										<div className={"rate-input"}>
											<NumberInput name={"re40"} label={"RE40'"} type={"number"} />
										</div>
									</div>
								) : null}

								<div className={"form-row justify-start align-center"}>
									<button
										type={"button"}
										className={"btn btn-secondary"}
										onClick={() => props.discardHandler()}
										disabled={form.isSubmitting}
									>
										Discard
									</button>
									<button
										type={"submit"}
										className={"btn btn-primary"}
										disabled={form.isSubmitting || !form.isValid}
									>
										Save
									</button>
									{!props.prospectiveClient ? (
										<button
											type={"button"}
											className={"btn btn-secondary"}
											disabled={
												form.isSubmitting ||
												!!form.errors.pol ||
												!!form.errors.pod ||
												!!form.errors.destinationCy ||
												!!form.errors.type ||
												!!form.errors.agent
											}
											onClick={() => openMarginAnalysis(form.values)}
										>
											Margin Analysis
										</button>
									) : null}
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		</Fragment>
	)
}

export default OceanRateForm
