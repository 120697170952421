import React, { useContext } from "react"

import { useRoutes, Navigate } from "react-router"
import LoginPage from "Pages/Login/LoginPage/LoginPage"
import ForgotPasswordPage from "Pages/Login/ForgotPasswordPage/ForgotPasswordPage"
import UserContext from "Contexts/User/Context"
import SidebarLayout from "../Layouts/SidebarLayout"
import BuyRateSearchPage from "../Pages/BuyRates/Ocean/Page"
import DashboardHome from "../Pages/DashboardHome/DashboardHome"
import AdminConsole from "../Pages/Admin/AdminConsole"
import ClientProfile from "../Pages/ClientProfile/ClientProfile"
import Clients from "../Pages/Clients/Clients"
import SellRatePage from "../Pages/SellRate/Page"
import DrayBuyRateSearchPage from "../Pages/BuyRates/Dray/Page"
import ControlTower from "../Pages/ControlTower/ControlTower"
import ReviewPage from "../Pages/ControlTower/ReviewPage/ReviewPage"
import UserProfile from "../Pages/UserProfile/UserProfile"
import ProtectedComponent from "./ProtectedComponent"
import usePageTitleService from "../Services/PageTitleService"
import ChangePasswordPage from "Pages/Login/ChangePassword/ChangePasswordPage"
import QuoteSearchPage from "Pages/QuoteSearch/Page"

function AppRouter() {
	const user = useContext(UserContext)
	const [pageTitle, updatePageTitle] = usePageTitleService()

	const routes = useRoutes([
		{
			path: "/",
			element: user.loggedIn ? <Navigate to="/app/dashboard" /> : <Navigate to="/login" />,
		},
		{
			path: "login",
			element: user.loggedIn ? <Navigate to="/app/dashboard" /> : <LoginPage user={user} />,
		},
		{
			path: "/app",
			element: <ProtectedComponent component={SidebarLayout} pageTitle={pageTitle} />,
			children: [
				{ path: "dashboard", element: <DashboardHome setHeaderTitle={updatePageTitle} /> },
				{ path: "admin-console", element: <AdminConsole setHeaderTitle={updatePageTitle} /> },
				{ path: "client-profile/:id", element: <ClientProfile setHeaderTitle={updatePageTitle} /> },
				{ path: "client-profile/", element: <ClientProfile setHeaderTitle={updatePageTitle} /> },
				{ path: "clients", element: <Clients setHeaderTitle={updatePageTitle} /> },
				{
					path: "client-sell-rates/:id",
					element: <SellRatePage setHeaderTitle={updatePageTitle} />,
				},
				{ path: "client-sell-rates", element: <SellRatePage setHeaderTitle={updatePageTitle} /> },
				{
					path: "buy-rate-search",
					element: <BuyRateSearchPage setHeaderTitle={updatePageTitle} />,
				},
				{
					path: "dray-rate-search",
					element: <DrayBuyRateSearchPage setHeaderTitle={updatePageTitle} />,
				},
				{ path: "quote-search", element: <QuoteSearchPage setHeaderTitle={updatePageTitle} /> },
				{ path: "control-tower", element: <ControlTower setHeaderTitle={updatePageTitle} /> },
				{ path: "control-tower/review", element: <ReviewPage setHeaderTitle={updatePageTitle} /> },
				{ path: "user-profile", element: <UserProfile setHeaderTitle={updatePageTitle} /> },
			],
		},
		{ path: "forgot-password/:id?", element: <ForgotPasswordPage user={user} /> },
		{
			path: "change-password/:token",
			element: user.loggedIn ? <Navigate to="/app" /> : <ChangePasswordPage user={user} />,
		},
	])

	return routes
}

export default AppRouter
