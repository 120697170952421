import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"

import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"

import TextInput from "Shared/Formik/TextInput"

import logo from "../img/uwl-icon.jpg"
import "../LoginPage/LoginPage.scss"

interface IProps {
	user: any
}
const LoginPage: React.FC<IProps> = ({ user }) => {
	const navigate = useNavigate()

	return (
		<div id="auth-page-wrapper">
			<Helmet>
				<title>Login | UWL Pricing Tool</title>
			</Helmet>
			<div id="auth-card" className="card">
				<div className="card-header">Sign In</div>
				<div className="card-body">
					<div id="auth-form-header">
						<img id="auth-form-brand-logo" src={logo} alt="" />
					</div>
					<Formik
						initialValues={{ email: "", password: "" }}
						validationSchema={Yup.object({
							email: Yup.string().email().required(),
							password: Yup.string().required(),
						})}
						onSubmit={async (values, { setSubmitting }) => {
							await user.login(values.email, values.password)
							setSubmitting(false)
						}}
					>
						{(form) => (
							<div>
								<TextInput name={"email"} label="Email*" type={"email"} />
								<TextInput
									name={"password"}
									label="Password*"
									type={"password"}
									onKeyDown={(e) => {
										if (e.keyCode === 13) form.handleSubmit()
									}}
								/>
								<button
									id="auth-button"
									type="button"
									className="btn btn-primary"
									disabled={form.isSubmitting}
									onClick={() => form.handleSubmit()}
								>
									{form.isSubmitting ? (
										<div className="text-center spinner-border text-light" role="status"></div>
									) : (
										"Log In"
									)}
								</button>
								<button
									type="button"
									className="btn btn-link"
									id="forgot-button"
									onClick={() => navigate("/forgot-password")}
								>
									Forgot Password?
								</button>
							</div>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

export default LoginPage
