import React, { Fragment, FC, useState, useEffect } from "react"
import AdminCell from "./AdminCell"
import "../AdminConsole.scss"
import useAdminService from "Services/AdminService"

interface IProps {
	context: any
}

const AdminCWTable: FC<IProps> = ({ context }) => {
	const [options, setOptions] = useState([])
	const [record, setRecord] = useState({
		id: undefined,
		text: "",
		value: "",
	})
	const [newEntry, setNewEntry] = useState<boolean>(false)
	const [selectedIndex, setSelectedIndex] = useState(-1)

	const adminService = useAdminService()

	useEffect(() => {
		const temp = [...context.tableData]
		setOptions(temp)
		reset()
		setSelectedIndex(-1)
		// eslint-disable-next-line
	}, [context.tableData])

	const display = (acc: any) => {
		setNewEntry(true)
	}

	const inputHandler = (event: any) => {
		const name = event.target.name
		const value = event.target.value
		updateInput(name, value)
	}

	const checkHandler = (event: any) => {
		const name = event.target.name
		const value = event.target.checked
		updateInput(name, value)
	}

	const updateInput = (key: string, data: string) => {
		const temp = { ...record, [key]: data }
		setRecord(temp)
	}

	const add = () => {
		adminService.addRecord(context.tableName, record).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
		reset()
	}

	const update = (record: any) => {
		adminService.updateRecord(context.tableName, record).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
		reset()
	}

	const remove = (record: any) => {
		adminService.deleteRecord(context.tableName, record.id).then((data) => {
			context.fetchAdminTableFn(context.tableName)
		})
		reset()
	}

	const reset = () => {
		if (context.other) {
			setRecord({ id: undefined, text: "", value: "", [context.other?.fieldName]: false })
		} else {
			setRecord({ id: undefined, text: "", value: "" })
		}
		setNewEntry(false)
	}

	const selectedIndexFn = (index) => {
		if (index !== -1) {
			setSelectedIndex(index)
		} else {
			setSelectedIndex(-1)
		}
	}

	return (
		<Fragment>
			<div className={"card admin-table"}>
				<div className={"card-header admin-table-title"}>
					{context.tableName}
					<button
						type="button"
						className="add-btn"
						onClick={display}
						disabled={newEntry || selectedIndex !== -1}
					>
						<i className="fa fa-plus"></i>
					</button>
				</div>
				<div className="card-body">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th scope="col">Name</th>
								<th scope="col">CW Code</th>
								{context.other && <th scope="col">{context.other.label}</th>}
								<th scope="col">Actions</th>
							</tr>
							<tr hidden={!newEntry} className="newEntry">
								<th>
									<input
										type="text"
										name="text"
										value={record.text}
										onChange={(event) => {
											inputHandler(event)
										}}
									></input>
								</th>
								<th>
									<input
										type="text"
										name="value"
										value={record.value}
										onChange={(event) => {
											inputHandler(event)
										}}
									></input>
								</th>
								{context.other && (
									<th>
										<input
											type="checkbox"
											name={context.other.fieldName}
											checked={record[context.other.fieldName]}
											onChange={(event) => {
												checkHandler(event)
											}}
										/>
									</th>
								)}
								<th>
									<div className="action-cell">
										<button
											type="button"
											disabled={record.text === "" || record.value === ""}
											className="save-btn"
											onClick={add}
										>
											<i className="fa fa-floppy-o"></i>
										</button>
										<button type="button" className="trash-cancel-btn" onClick={reset}>
											<i className="fa fa-ban"></i>
										</button>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{options
								? options.map((rec, index) => (
										<AdminCell
											record={rec}
											rowIndex={index}
											selectedIndexFn={selectedIndexFn}
											selectedIndex={selectedIndex}
											key={rec.id}
											newEntry={newEntry}
											updateRecordFn={update}
											removeRecordFn={remove}
											otherField={context.other?.fieldName}
										></AdminCell>
								  ))
								: null}
						</tbody>
					</table>
				</div>
			</div>
		</Fragment>
	)
}

export default AdminCWTable
