import React, { useState, useEffect } from "react"
import AdminConsoleContext from "./AdminConsoleContext"
import { trackPromise } from "react-promise-tracker"
import useAdminService from "Services/AdminService"

const AdminConsoleProvider: React.FC = ({ children }) => {
	const [state, setState] = useState({
		tableName: "",
		tableData: [],
		other: null,
		selectedTableFn: Function,
	})

	const adminService = useAdminService()

	const selectedTableFn = (event: any) => {
		if (event.target.value !== "") {
			fetchAdminTableFn(event.target.value)
		} else {
			setInitState()
		}
	}
	const fetchAdminTableFn = (tableName: string) => {
		if (tableName === "Users") {
			trackPromise(
				adminService.getUsersTable().then((data) => {
					setState({ ...state, tableName: tableName, tableData: data })
				}),
			)
		} else if (tableName === "Ports") {
			trackPromise(
				adminService.getAdminTable("locode_groups").then((data) => {
					setState({ ...state, tableName: tableName, tableData: data })
				}),
			)
		} else if (tableName === "Rate Sheet Content") {
			trackPromise(
				adminService.getRateSheetTable().then((data) => {
					setState({ ...state, tableName: tableName, tableData: data })
				}),
			)
		} else if (tableName === "Buy Rate Ports") {
			trackPromise(
				adminService.getAdminTable("unloco_translate").then((data) => {
					setState({ ...state, tableName: tableName, tableData: data })
				}),
			)
		} else if (tableName === "Dray Buy Rate Fuel Surcharge") {
			trackPromise(
				adminService.getAdminTable("dray_buy_rates_fsc").then((data) => {
					setState({ ...state, tableName: tableName, tableData: data[0] })
				}),
			)
		} else if (tableName === "Prospective Email Message") {
			trackPromise(
				adminService.getAdminTable("prospective_email_message").then((data) => {
					setState({ ...state, tableName: tableName, tableData: data[0] })
				}),
			)
		} else {
			trackPromise(
				adminService.getAdminTable(tableName).then((data) => {
					setState({ ...state, tableName: tableName, tableData: data, other: null })
				}),
			)
		}
	}

	const setInitState = () => {
		setState({ ...state, tableName: "", tableData: [], other: null })
	}

	return (
		<AdminConsoleContext.Provider
			value={{
				tableName: state.tableName,
				tableData: state.tableData,
				other: state.other,
				selectedTableFn: selectedTableFn,
				fetchAdminTableFn: fetchAdminTableFn,
			}}
		>
			{children}
		</AdminConsoleContext.Provider>
	)
}

export default AdminConsoleProvider
