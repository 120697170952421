import React, { useState, Fragment, useContext } from "react"
import UserContext from "../Contexts/User/Context"
import { Link, useLocation } from "react-router-dom"

function Sidebar() {
	const user = useContext(UserContext)
	const [expandedMenu, setExpandedMenu] = useState(false)
	function toggleMenu() {
		setExpandedMenu(!expandedMenu)
	}

	return (
		<div className={"sbl-sidebar"}>
			<div>
				{expandedMenu ? (
					<img
						src="https://worldgroup.blob.core.windows.net/uwllogos/logo-short.png"
						alt="UWL, Inc."
					></img>
				) : (
					<img
						src="https://worldgroup.blob.core.windows.net/uwllogos/logo-short-no-text.png"
						alt="UWL"
					></img>
				)}
				{user && user.details ? (
					<Fragment>
						{user && (user.details.role === "Admin" || user.details.role === "User") ? (
							<DefaultLinks expanded={expandedMenu} />
						) : null}
						{user && user.details.role === "Viewer" ? (
							<ViewerLinks expanded={expandedMenu} />
						) : null}
					</Fragment>
				) : null}
			</div>
			<div className={"menu-toggle-wrapper"} onClick={() => toggleMenu()}>
				<i
					className={`close-menu fa ${
						expandedMenu ? "fa-arrow-circle-o-left" : "fa-arrow-circle-o-right"
					}`}
					title={`${expandedMenu ? "Collapse Menu" : "Expand Menu"}`}
					aria-hidden="true"
				></i>
			</div>
		</div>
	)
}

function NavItem({ title, link, expanded, children }) {
	const location = useLocation()
	const isActive = location.pathname === link ? true : false
	const linkClass = isActive ? "nav-link current" : "nav-link"
	return (
		<li className="nav-item" title={title}>
			<Link to={link} className={linkClass}>
				{children}
				{expanded ? <Fragment>{title}</Fragment> : null}
			</Link>
		</li>
	)
}

function DefaultLinks({ expanded }) {
	return (
		<ul>
			<NavItem title={"Dashboard"} link={"dashboard"} expanded={expanded}>
				<i className="nav-item-icon fa fa-home" aria-hidden="true"></i>
			</NavItem>
			<NavItem title={"Client Profile"} link={"client-profile"} expanded={expanded}>
				<i className="nav-item-icon fa fa-user" aria-hidden="true"></i>
			</NavItem>
			<NavItem title={"All Clients"} link={"clients"} expanded={expanded}>
				<i className="nav-item-icon fa fa-users" aria-hidden="true"></i>
			</NavItem>
			<NavItem title={"Client Sell Rates"} link={"client-sell-rates"} expanded={expanded}>
				<i className="nav-item-icon fa fa-usd" aria-hidden="true"></i>
			</NavItem>
			<NavItem title={"Ocean Rate Search"} link={"buy-rate-search"} expanded={expanded}>
				<i className="nav-item-icon fa fa-ship" aria-hidden="true"></i>
			</NavItem>
			<NavItem title={"Dray Rate Search"} link={"dray-rate-search"} expanded={expanded}>
				<i className="nav-item-icon fa fa-truck" aria-hidden="true"></i>
			</NavItem>
			<NavItem title={"Quote Search"} link={"/app/quote-search"} expanded={expanded}>
				<i className="nav-item-icon fa fa-search" aria-hidden="true"></i>
			</NavItem>
			<NavItem title={"Control Tower"} link={"control-tower"} expanded={expanded}>
				<i className="nav-item-icon fa fa-sliders" aria-hidden="true"></i>
			</NavItem>
		</ul>
	)
}

function ViewerLinks({ expanded }) {
	return (
		<ul>
			<NavItem title={"Ocean Rate Search"} link={"buy-rate-search"} expanded={expanded}>
				<i className="nav-item-icon fa fa-ship" aria-hidden="true"></i>
			</NavItem>
			<NavItem title={"Dray Rate Search"} link={"dray-rate-search"} expanded={expanded}>
				<i className="nav-item-icon fa fa-truck" aria-hidden="true"></i>
			</NavItem>
		</ul>
	)
}

export default Sidebar
