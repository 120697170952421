import React, { Fragment } from "react"

function MarginAnalysisTable({ data }) {
	return (
		<Fragment>
			<div className="container">
				<div className="row">
					<div className="col-lg-1 col-md-0"></div>
					<div className="margin col-lg-10 col-md-12">
						<table className="marginTable table table-bordered table-sm">
							<thead className="marginHeader thead-light">
								<tr>
									<th scope="col">Margin Analysis</th>
									<th scope="col">20&apos;</th>
									<th scope="col">40&apos;</th>
									<th scope="col">40&apos;HC</th>
									<th scope="col">45&apos;</th>
								</tr>
							</thead>
							<tbody>
								<tr className="tr">
									<td>Min/Max Algorithm</td>
									<CurrencyCell cellValue={data.algorithm.rate20} />
									<CurrencyCell cellValue={data.algorithm.rate40} />
									<CurrencyCell cellValue={data.algorithm.rate40hc} />
									<CurrencyCell cellValue={data.algorithm.rate45} />
								</tr>
								<tr className="tr">
									<td>Avg. Top 3</td>
									<CurrencyCell cellValue={data.avgTop3.rate20} />
									<CurrencyCell cellValue={data.avgTop3.rate40} />
									<CurrencyCell cellValue={data.avgTop3.rate40hc} />
									<CurrencyCell cellValue={data.avgTop3.rate45} />
								</tr>
								<tr className="tr">
									<td>Avg. Top 5</td>
									<CurrencyCell cellValue={data.avgTop5.rate20} />
									<CurrencyCell cellValue={data.avgTop5.rate40} />
									<CurrencyCell cellValue={data.avgTop5.rate40hc} />
									<CurrencyCell cellValue={data.avgTop5.rate45} />
								</tr>
								<tr className="tr">
									<td>Profitable Carriers</td>
									<ProfitableCarrierCell cellValue={data.profitableCarriers.rate20} />
									<ProfitableCarrierCell cellValue={data.profitableCarriers.rate40} />
									<ProfitableCarrierCell cellValue={data.profitableCarriers.rate40hc} />
									<ProfitableCarrierCell cellValue={data.profitableCarriers.rate45} />
								</tr>
							</tbody>
						</table>
					</div>
					<div className="col-lg-1 col-md-0"></div>
				</div>
			</div>
		</Fragment>
	)
}

function CurrencyCell({ cellValue }) {
	return (
		<td className="marginTd">
			<div className="input-group input-group-sm">
				<div className="input-group-prepend">
					<span className="input-group-text">
						<i className="fa fa-dollar" aria-hidden="true"></i>
					</span>
				</div>
				<input
					autoComplete="off"
					className="input form-control"
					disabled
					type="text"
					value={cellValue}
				></input>
			</div>
		</td>
	)
}

function ProfitableCarrierCell({ cellValue }) {
	return <td className="profTd">{cellValue}</td>
}

export default MarginAnalysisTable
