import React from "react"
import { Input } from "reactstrap"

import { STATES_LIST } from "./states"
import { CITIES_LIST } from "./cities"

export interface CityStateZipFilterValue {
	city: string
	state: string
	zip: string
}

function CityStateZipFilter({ filter, updateFilter }) {
	const citiesList = CITIES_LIST
	const statesList = STATES_LIST
	if (!filter.value) {
		filter.value = {
			city: "",
			state: "",
			zip: "",
		}
	}

	// else {
	//     if (filter.value.state !== "") citiesList = [...CITIES_LIST[filter.value.state]];
	//     if (filter.value.city !== "") {
	//         if (cityInManyStates(filter.value.city)) {
	//             getStatePairsForCity(filter.value.city);
	//         }
	//     }
	// }

	// function cityInManyStates(city: string): boolean {
	//     let counter = 1;

	//     const cities = CITIES_LIST

	//     cities.forEach(c => {
	//         if (c === city) counter++;
	//     });

	//     return (counter > 1);
	// }

	// function getStatePairsForCity(city: string) {
	//     const states = [];
	//     Object.keys(CITIES).forEach(state => {
	//         if (CITIES[state].findIndex(c => c === city) > -1) states.push({label: state, value: STATES_BY_NAME[state]});
	//     });
	// }

	function updateValue(e: any) {
		const updatedFilter = { ...filter }
		updatedFilter.value = {
			...updatedFilter.value,
			[e.target.name]: e.target.value,
		}

		updateFilter(updatedFilter)
	}

	// const cities = (filter.value.state && CITIES[filter.value.state]) ? CITIES[filter.value.state] : CITIES_LIST;

	return (
		<div>
			<Input
				list="cities"
				placeholder="City"
				name="city"
				value={filter.value.city}
				onChange={updateValue}
			/>
			<datalist id="cities">
				{citiesList.map((c, i) => (
					<option value={c} key={c + i} />
				))}
			</datalist>
			<Input
				list="states"
				placeholder="State"
				name="state"
				value={filter.value.state}
				onChange={updateValue}
			/>
			<datalist id="states">
				{statesList.map((s) => (
					<option value={s.value} key={s.value}></option>
				))}
			</datalist>
			<Input name="zip" placeholder="zip" value={filter.value.zip} onChange={updateValue} />
		</div>
	)
}

export default CityStateZipFilter
