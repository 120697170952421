import React, { Fragment } from "react"
import { Input } from "reactstrap"

const OPERATORS = [
	{
		label: "less than",
		value: "less-than",
	},
	{
		label: "greater than",
		value: "greater-than",
	},
	{
		label: "between",
		value: "between",
	},
]

function NumberFilter({ filter, updateFilter }) {
	// Set default value
	if (!filter.value) {
		filter.value = {
			type: "less-than",
			min: null,
			max: 0,
		}
	}

	function UpdateOperator(e: any) {
		const updatedFilter = { ...filter }
		updatedFilter.value = {
			type: e.target.value,
			min: 0,
			max: 0,
		}

		updateFilter(updatedFilter)
	}

	function updateValue(e: any) {
		const updatedFilter = { ...filter }
		updatedFilter.value[e.target.name] = e.target.value
		updateFilter(updatedFilter)
	}

	return (
		<div>
			<Input type="select" name="type" onChange={UpdateOperator} value={filter.value.type}>
				{OPERATORS.map((o) => (
					<option value={o.value} key={o.label}>
						{o.label}
					</option>
				))}
			</Input>
			<ValueInput filter={filter} updateValue={updateValue}></ValueInput>
		</div>
	)
}

function ValueInput({ filter, updateValue }) {
	switch (filter.value.type) {
		case "less-than": {
			return (
				<Fragment>
					<Input
						type="number"
						placeholder="Max"
						name="max"
						value={filter.value.max}
						onChange={updateValue}
					/>
				</Fragment>
			)
		}
		case "greater-than": {
			return (
				<Fragment>
					<Input
						type="number"
						placeholder="Min"
						name="min"
						value={filter.value.min}
						onChange={updateValue}
					/>
				</Fragment>
			)
		}
		case "between": {
			return (
				<Fragment>
					<Input
						type="number"
						placeholder="Min"
						name="min"
						value={filter.value.min}
						onChange={updateValue}
					/>
					<Input
						type="number"
						placeholder="Max"
						name="max"
						value={filter.value.max}
						onChange={updateValue}
					/>
				</Fragment>
			)
		}
		default: {
			return <Fragment></Fragment>
		}
	}
}

export default NumberFilter
