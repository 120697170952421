import React, { Fragment } from "react"
import "./ControlDropdowns.scss"

export interface IProps {
	rateSelected: Function
	tradeSelected: Function
	applyTo
	rateDropdown
	tradeDropdown
	trade
	disabled
}

const ControlDropdowns: React.FC<IProps> = ({
	rateSelected,
	tradeSelected,
	rateDropdown,
	tradeDropdown,
	trade,
	applyTo,
	disabled,
}) => {
	function rateTypeSelected(event: any) {
		rateSelected(event)
	}
	function tradeTypeSelected(event: any) {
		tradeSelected(event)
	}

	return (
		<Fragment>
			{rateDropdown ? (
				<div>
					<label className="control-label">Apply To*</label>
					<select
						className="form-control control-inputs"
						disabled={disabled}
						name="RateType"
						onChange={rateTypeSelected}
						defaultValue={!disabled ? "-- Select Option --" : "All Fixed"}
					>
						{!disabled ? <option disabled>-- Select Option --</option> : null}
						{rateDropdown.map((key) => (
							<option key={key.listId} value={key.value}>
								{key.text}
							</option>
						))}
					</select>
				</div>
			) : null}
			<label className="control-label">Trade*</label>
			<select
				className="form-control control-inputs"
				name="Trade"
				onChange={tradeTypeSelected}
				defaultValue="-- Select Option --"
			>
				<option disabled>-- Select Option --</option>
				{tradeDropdown.map((key) => (
					<option key={key.listId} value={key.value}>
						{key.text}
					</option>
				))}
			</select>
		</Fragment>
	)
}

export default ControlDropdowns
