import { FilterSet } from "./DynamicSearch"
import { Filterable, SET_MANAGER } from "./index"

export function useDynamicSearchService(datasetId: string) {
	const UTILS = {
		addSearchFilter: function (filter: any, filterSet): FilterSet {
			const f: Filterable = { ...filter, applied: true, value: null }
			const updatedSearchFilters: FilterSet = {
				name: filterSet.name,
				filters: SET_MANAGER.addItemTo(filterSet.filters, f, "field"),
			}

			return updatedSearchFilters
		},
		removeSearchFilter: function (filter: any, filterSet): FilterSet {
			const f: Filterable = { ...filter }
			const updatedSearchFilters: FilterSet = {
				name: filterSet.name,
				filters: SET_MANAGER.deleteItemFrom(filterSet.filters, f, "field"),
			}

			delete f.applied
			delete f.value

			return updatedSearchFilters
		},
		updateSearchFilter: function (filter: any, filterSet): FilterSet {
			const updatedSearchFilters: FilterSet = {
				name: filterSet.name,
				filters: SET_MANAGER.addItemTo(filterSet.filters, filter, "field"),
			}

			return updatedSearchFilters
		},
		updateLocalStorage: function (filterSets) {
			filterSets = filterSets.filter((f) => f.name !== "Default")
			localStorage.setItem(datasetId, JSON.stringify(filterSets))
		},
		getDefaults: function () {
			const f = localStorage.getItem(datasetId)
			const filterSets = JSON.parse(f)
			return {
				defaultFilterSet: { name: "Default", filters: [] },
				filterSets: filterSets ? filterSets : [],
			}
		},
		newFilterSet: function (
			filterSetName: string,
			filterSets: FilterSet[],
		): [FilterSet, FilterSet[]] {
			const filterSet: FilterSet = { name: filterSetName, filters: [] }
			const updatedFilterSets: FilterSet[] = SET_MANAGER.addItemTo(filterSets, filterSet, "name")

			return [filterSet, updatedFilterSets]
		},
		removeFilterSet: function (filterSetName: string, filterSets: FilterSet[]): FilterSet[] {
			const filterSet = filterSets.find((f) => f.name === filterSetName)
			const updatedFilterSets = SET_MANAGER.deleteItemFrom(filterSets, filterSet, "name")

			return updatedFilterSets
		},
	}

	return UTILS
}

export default useDynamicSearchService
