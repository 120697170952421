import React from "react"
import { Input } from "reactstrap"

export function ToggleCell(props) {
	const column = props.cell.column.id
	const rowData = props.cell.row.values
	const value = rowData[column]

	function handleInput(e) {
		const value = e.target.checked

		const updatedValue = { ...rowData }
		updatedValue[e.target.name] = value
		props.updateAccessorial(updatedValue)
	}

	return (
		<div>
			<input type="checkbox" name={column} checked={value} onChange={handleInput} />
		</div>
	)
}

export function InputCell(props) {
	const column = props.cell.column.id
	const rowData = props.cell.row.values
	const value = rowData[column]

	function handleInput(e) {
		const value = e.target.value

		if (value === "" || value.match(/^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\d{0,2}|\.\d{1,2})$/)) {
			const updatedValue = { ...rowData }
			updatedValue[e.target.name] = value
			props.updateAccessorial(updatedValue)
		}
	}

	return (
		<div>
			<Input
				type="text"
				name={column}
				value={value}
				onChange={handleInput}
				disabled={!rowData.isVisible}
			></Input>
		</div>
	)
}
