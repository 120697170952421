import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"

const useUserService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		login: (username, password) => {
			return apiContext.login(username, password)
		},
		logout: () => {
			return apiContext.logout()
		},
		forgotPassword: (username) => {
			return apiContext.forgotPassword(username)
		},
		resetPassword: (token, password) => {
			return apiContext.resetPassword(token, password)
		},
		currentUserDetails: () => {
			return apiContext.get("auth/currentUserDetails", source.token)
		},
		updateUserDetails: (user) => {
			return apiContext.post("user/profile/" + user.id, user, source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
		updateUserSellRateLayout: (user) => {
			return apiContext.post("user/sell-rate-column-layout/" + user.id, user, source.token)
		},
	}

	return API
}

export default useUserService
