import { useMemo, useState, FC } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	useReactTable,
	getSortedRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	SortingState,
	ColumnFiltersState,
	PaginationState,
	getPaginationRowModel,
} from "@tanstack/react-table"
import { pastOceanRatesTableHeaders } from "Pages/TableHeaders"
import { OceanRateChangeLog } from "Types/OceanRateTypes"
import { Filter } from "Shared/ReactTableUtils/TableUtils"
import "./PastHistoryModal.scss"

interface IProps {
	showModal: boolean
	toogleModal: () => void
	data: OceanRateChangeLog[]
}

export const PastHistoryModal: FC<IProps> = ({ showModal, toogleModal, data }) => {
	const [sorting, setSorting] = useState<SortingState>([])
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})
	const columns = useMemo(() => pastOceanRatesTableHeaders(), [])
	const pastHistoryTable = useReactTable({
		data,
		columns,
		state: {
			sorting,
			columnFilters,
			pagination,
		},
		onColumnFiltersChange: setColumnFilters,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getPaginationRowModel: getPaginationRowModel(),
	})
	return (
		<Modal isOpen={showModal} toggle={toogleModal} size="lg" scrollable>
			<ModalHeader>Past History</ModalHeader>
			<ModalBody>
				<div className="modal-table-wrapper">
					<Table responsive striped>
						<thead>
							{pastHistoryTable.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<th key={header.id}>
											{header.isPlaceholder ? null : (
												<>
													<div
														{...{
															className: header.column.getCanSort()
																? "cursor-pointer select-none"
																: "",
															onClick: header.column.getToggleSortingHandler(),
														}}
													>
														{flexRender(header.column.columnDef.header, header.getContext())}
														{{
															asc: (
																<i
																	className="sort-icon fa fa-sort-amount-asc fa-md"
																	aria-hidden="true"
																></i>
															),
															desc: (
																<i
																	className="sort-icon fa fa-sort-amount-desc fa-md"
																	aria-hidden="true"
																></i>
															),
														}[header.column.getIsSorted() as string] ?? null}
													</div>
													{header.column.getCanFilter() ? (
														<div>
															<Filter column={header.column} table={pastHistoryTable} />
														</div>
													) : null}
												</>
											)}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{pastHistoryTable.getRowModel().rows.map((row) => (
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => (
										<td key={cell.id}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</Table>
				</div>
				<div className="flex items-center gap-2 my-2">
					<button
						className="border rounded p-1"
						onClick={() => pastHistoryTable.setPageIndex(0)}
						disabled={!pastHistoryTable.getCanPreviousPage()}
					>
						{"<<"}
					</button>
					<button
						className="border rounded p-1"
						onClick={() => pastHistoryTable.previousPage()}
						disabled={!pastHistoryTable.getCanPreviousPage()}
					>
						{"<"}
					</button>
					<button
						className="border rounded p-1"
						onClick={() => pastHistoryTable.nextPage()}
						disabled={!pastHistoryTable.getCanNextPage()}
					>
						{">"}
					</button>
					<button
						className="border rounded p-1"
						onClick={() => pastHistoryTable.setPageIndex(pastHistoryTable.getPageCount() - 1)}
						disabled={!pastHistoryTable.getCanNextPage()}
					>
						{">>"}
					</button>
					<span className="flex items-center gap-1">
						Page <strong>{pagination.pageIndex + 1}</strong> of{" "}
						<strong>{pastHistoryTable.getPageCount()}</strong>{" "}
					</span>
					<span className="flex items-center gap-1">
						| Go to page:
						<input
							type="number"
							defaultValue={pagination.pageIndex + 1}
							onChange={(e) => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0
								pastHistoryTable.setPageIndex(page)
							}}
							className="border p-1 rounded w-16"
						/>
					</span>
				</div>
				<div>{pastHistoryTable.getRowModel().rows.length} Rows</div>
			</ModalBody>
			<ModalFooter>
				<button type="button" className="btn btn-secondary" onClick={toogleModal}>
					Close
				</button>
			</ModalFooter>
		</Modal>
	)
}
