import { Fragment, FC, useState, ChangeEvent } from "react"
import { TableDataType } from "Types/AdminAccessoraialsTypes"

interface IProps {
	acc: TableDataType
	rowIndex: number
	newEntry: boolean
	selectedIndex: number
	errors: string
	removeAccFn: (record: TableDataType) => void
	updateAccFn: (record: TableDataType) => void
	selectedIndexFn: (index: number) => void
	validateField: (amount: string) => boolean
}

const AdminAccessorialsCell: FC<IProps> = ({
	acc,
	rowIndex,
	newEntry,
	selectedIndex,
	updateAccFn,
	removeAccFn,
	selectedIndexFn,
	validateField,
	errors,
}) => {
	const [rowData, setRowData] = useState<TableDataType>({
		id: undefined,
		isDefault: false,
		name: "",
		location: "",
		amount: 0,
		per: "",
	})

	const editRow = (rec: TableDataType, index: number) => {
		selectedIndexFn(index)
		setRowData(rec)
	}

	const checkHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name
		const value = event.target.checked
		updateInput(name, value)
	}

	const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
		let value: string | number
		if (event.target.name === "amount") {
			const isValid = validateField(event.target.value)
			value = isValid ? parseFloat(event.target.value) : 0
		} else {
			value = event.target.value
		}
		const name = event.target.name
		updateInput(name, value)
	}

	const updateInput = (key: string, data: string | boolean | number) => {
		const temp = { ...rowData, [key]: data }
		setRowData(temp)
	}

	return (
		<Fragment>
			<tr>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input
							type="checkbox"
							name="isDefault"
							checked={rowData.isDefault}
							onChange={checkHandler}
						/>
					) : acc.isDefault ? (
						"YES"
					) : (
						"NO"
					)}
				</td>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input type="text" value={rowData.name} name="name" onChange={inputHandler} />
					) : (
						acc.name
					)}
				</td>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input type="text" value={rowData.location} name="location" onChange={inputHandler} />
					) : (
						acc.location
					)}
				</td>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input
							type="text"
							value={rowData.amount !== 0 ? rowData.amount : ""}
							name="amount"
							className={errors ? "amount-error" : null}
							onChange={inputHandler}
						/>
					) : (
						acc.amount
					)}
				</td>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input type="text" value={rowData.per} name="per" onChange={inputHandler} />
					) : (
						acc.per
					)}
				</td>
				<td>
					<div className="action-cell">
						<button
							type="button"
							disabled={
								rowData.name === "" || rowData.amount === 0 || rowData.per === "" || errors !== ""
							}
							hidden={!(selectedIndex === rowIndex)}
							className="save-btn"
							onClick={() => updateAccFn(rowData)}
						>
							<i className="fa fa-floppy-o"></i>
						</button>
						<button
							type="button"
							hidden={!(selectedIndex === rowIndex)}
							className="trash-cancel-btn"
							onClick={() => selectedIndexFn(-1)}
						>
							<i className="fa fa-ban"></i>
						</button>
						<button
							type="button"
							disabled={newEntry}
							hidden={selectedIndex === rowIndex}
							className="edit-btn"
							onClick={() => editRow(acc, rowIndex)}
						>
							<i className="fa fa-pencil"></i>
						</button>
						<button
							type="button"
							disabled={newEntry}
							hidden={selectedIndex === rowIndex}
							className="trash-cancel-btn"
							onClick={() => removeAccFn(acc)}
						>
							<i className="fa fa-trash"></i>
						</button>
					</div>
					{selectedIndex === rowIndex ? (
						<p hidden={errors === ""} className="errors" style={{ fontWeight: "bold" }}>
							{errors}
						</p>
					) : (
						""
					)}
				</td>
			</tr>
		</Fragment>
	)
}

export default AdminAccessorialsCell
