import React, { useEffect, useState } from "react"
import "./ExpiringRateWidget.scss"
import { trackPromise } from "react-promise-tracker"
import useDashboardService from "Services/DashboardService"
import { useNavigate } from "react-router-dom"

function ExpiringRateWidget() {
	const navigate = useNavigate()
	const [data, setData] = useState([])

	const dashboardService = useDashboardService()

	useEffect(() => {
		trackPromise(
			dashboardService.getExpiringRateWidgetData().then((data) => {
				setData(data)
			}),
		)
		// eslint-disable-next-line
	}, [])

	function navigateTo(clientId: number) {
		navigate(`/app/client-sell-rates/${clientId}`)
	}

	return (
		<div className="card widget">
			<div className="card-header">Clients With Expiring Rates (7 Days)</div>
			<div className={"widget-table-wrapper"}>
				<table className={"table table-bordered"}>
					<thead className="thead-light">
						<tr>
							<th scope="col">Client</th>
							<th scope="col" className={"widget-action-column"}>
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						{data.map((d) => (
							<tr key={d.id}>
								<td>{d.name}</td>
								<td>
									<button className={"btn btn-link"} onClick={() => navigateTo(d.id)}>
										View Rates
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default ExpiringRateWidget
