import React, { Fragment, useEffect, useState } from "react"
import { Form, Formik } from "formik"
import { trackPromise } from "react-promise-tracker"
import TextInput from "Shared/Formik/TextInput"

import "Pages/QuoteSearch/Page.scss"
import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"
import TableWrapper from "Pages/SellRate/TableWrapper"
import useSellRateService from "Services/SellRateService"
import QuoteTable from "Pages/RateSheets/Table"
import * as Yup from "yup"

const quote_number_regex = /^(?<name>[A-Za-z]{0,3})(?<id>\d{1,8})$/s

function QuoteSearchPage(props) {
	const sellRateService = useSellRateService()

	const [state, setState] = useState({
		data: null,
		notFound: false,
	})

	useEffect(() => {
		props.setHeaderTitle("Quote Search")
	}, [])

	function searchSellRates(quoteNumber: string): void {
		const matches = quoteNumber.match(quote_number_regex)
		trackPromise(
			sellRateService.getOceanRate(matches.groups.id).then(
				(result) => {
					if (!result) {
						setState({ data: null, notFound: true })
					} else {
						setState({ data: [result], notFound: false })
					}
				},
				() => {
					setState({ data: null, notFound: true })
				},
			),
		)
	}

	return (
		<div className="quote-search-page-wrapper">
			<LoadingIndicator />
			<div className="search-wrapper">
				<Formik
					initialValues={{ quoteNumber: "" }}
					enableReinitialize={true}
					onSubmit={(values) => searchSellRates(values.quoteNumber)}
					validationSchema={Yup.object({
						quoteNumber: Yup.string()
							.matches(quote_number_regex, "Not a valid quote number.")
							.required("Required. Can't be blank."),
					})}
				>
					{(form) => (
						<Form className={"search-form"} autoComplete="off">
							<div className={"form-row justify-start align-end"}>
								<TextInput name={"quoteNumber"} label="Quote #" type={"text"} />
								<button
									className={"btn btn-primary align-self-end"}
									type={"submit"}
									disabled={!form.isValid}
								>
									Search
								</button>
							</div>
							{form.touched.quoteNumber && form.errors.quoteNumber && (
								<div className={"form-row justify-start align-end"} style={{ color: "red" }}>
									{form.errors.quoteNumber}
								</div>
							)}
						</Form>
					)}
				</Formik>
			</div>
			{state.data && (
				<Fragment>
					<TableWrapper tableLabel={"Quote"}>
						<QuoteTable quotes={state.data} />
					</TableWrapper>
				</Fragment>
			)}
			{state.notFound && <p>Quote Not Found.</p>}
		</div>
	)
}

export default QuoteSearchPage
