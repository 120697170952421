import React from "react"
import { Input } from "reactstrap"

function BooleanFilter({ filter, updateFilter }) {
	function updateValue(e: any) {
		const updatedFilter = { ...filter }
		updateFilter.value = e.target.value
		updateFilter(updatedFilter)
	}

	return (
		<div>
			<Input type="radio" name="bool" checked={filter.value} onChange={updateValue} />{" "}
		</div>
	)
}

export default BooleanFilter
