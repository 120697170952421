import React, { Fragment } from "react"

import { FilterSet } from "./DynamicSearch"
import TableCell from "./DynamicSearchTableCell"

interface TableViewProps {
	filterSet: FilterSet
	updateFilter: Function
	removeFilter: Function
}

function TableView({ filterSet, updateFilter, removeFilter }: TableViewProps) {
	return (
		<Fragment>
			{filterSet && filterSet.filters && filterSet.filters.length ? (
				<SearchFilterTable
					filterSet={filterSet}
					updateFilter={updateFilter}
					removeFilter={removeFilter}
				/>
			) : (
				<EmptyFilterTable />
			)}
		</Fragment>
	)
}

function SearchFilterTable({ filterSet, updateFilter, removeFilter }) {
	return (
		<>
			{filterSet.filters.map((f, i) => (
				<TableCell
					filter={f}
					updateFilter={updateFilter}
					removeFilter={removeFilter}
					key={f.field + i}
				/>
			))}
		</>
	)
}

function EmptyFilterTable() {
	return (
		<div>
			<p>No search filters selected.</p>
		</div>
	)
}

export default TableView
