import { Formik, Form, Field } from "formik"
import { Fragment, useEffect, useState } from "react"
import { trackPromise } from "react-promise-tracker"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import useBuyRateService from "Services/BuyRateService"
import DragDrop from "Shared/DragDrop/DragDrop"
import NumberInput from "Shared/Formik/NumberInput"
import TextInput from "Shared/Formik/TextInput"
import * as Yup from "yup"

interface IProps {
	rate: any
	open: boolean
	dispatcher: any
	refreshBuyRateTable: Function
}

function RateForm(props: IProps) {
	const buyRateService = useBuyRateService()

	const defaultRate = {
		id: null,
		destinationCy: "",
		deliveryCity: "",
		deliveryState: "",
		deliveryZip: "",
		baseRate: 0,
		lastUpdatedBy: null,
		lastUpdatedDate: null,
		provider: "",
		attachmentId: null,
		attachmentName: "",
		attachment: null,
		fsc: null,
		total: null,
		notes: "",
	}

	function handleFileChange(form, value) {
		form.setFieldValue("attachment", value.file, true)
		form.setFieldValue("attachmentName", value.filename, true)
	}

	const [formValue, setFormValue] = useState(defaultRate)
	useEffect(() => {
		if (props.rate) {
			setFormValue(props.rate)
			props.dispatcher({ type: "CLEAR_RATE_BUFFER" })
		}
		// eslint-disable-next-line
	}, [props.rate])

	return (
		<Fragment>
			<button
				type={"button"}
				className={"btn btn-primary"}
				onClick={() => props.dispatcher({ type: "NEW_RATE" })}
			>
				New Rate
			</button>
			<Modal isOpen={props.open} toggle={() => props.dispatcher({ type: "CLOSE_RATE_FORM" })}>
				<ModalHeader>New Buy Rate</ModalHeader>
				<Formik
					initialValues={formValue}
					enableReinitialize={true}
					validationSchema={Yup.object({
						id: Yup.number().integer().nullable(),
						destinationCy: Yup.string().required(),
						deliveryCity: Yup.string().required(),
						deliveryState: Yup.string().required(),
						deliveryZip: Yup.string().required(),
						baseRate: Yup.number().positive().integer().required(),
						lastUpdatedBy: Yup.string().nullable(),
						lastUpdatedDate: Yup.date().nullable(),
						provider: Yup.string().required(),
						attachmentId: Yup.number().integer().nullable(),
						attchmentName: Yup.string().nullable(),
						attachment: Yup.string().nullable(),
						fsc: Yup.number().nullable(),
						total: Yup.number().nullable(),
						notes: Yup.string().nullable(),
					})}
					onSubmit={(values) => {
						trackPromise(
							buyRateService.saveDrayBuyRate(values).then(
								() => {
									props.dispatcher({ type: "CLOSE_RATE_FORM" })
									// If user is editing refresh the table to show the edits after saving.
									props.refreshBuyRateTable()
								},
								() => {
									props.dispatcher({ type: "CLOSE_RATE_FORM" })
								},
							),
						)
					}}
				>
					{(form) => (
						<Form>
							<ModalBody>
								<div className={"form-column justify-start align-start"}>
									<div className={"form-row"}>
										<TextInput name="provider" label={"Provider*"} type={"text"}></TextInput>
										<NumberInput label={"Base Rate*"} name={"baseRate"} type={"number"} />
									</div>
									<div className={"form-row justify-start align-end"}>
										<TextInput
											name="destinationCy"
											label={"Destination CY*"}
											type={"text"}
										></TextInput>
										<TextInput
											name="deliveryCity"
											label={"Delivery City*"}
											type={"text"}
										></TextInput>
										<TextInput
											name="deliveryState"
											label={"Delivery State*"}
											type={"text"}
										></TextInput>
										<TextInput name="deliveryZip" label={"Delivery Zip*"} type={"text"}></TextInput>
									</div>
									<div className={"form-row justify-start align-start"}>
										<div>
											<label>Attachment</label>
											<DragDrop
												handleFileChange={(value) => handleFileChange(form, value)}
												base64={true}
											></DragDrop>
										</div>
									</div>
									<div className={"form-row justify-start align-start"}>
										<div className={"notes-input"}>
											<label>Notes</label>
											<Field as="textarea" name="notes"></Field>
										</div>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<button
									className={"btn btn-secondary"}
									type={"button"}
									onClick={() => props.dispatcher({ type: "CLOSE_RATE_FORM" })}
								>
									Cancel
								</button>
								<button className={"btn btn-primary"} type={"submit"}>
									Save
								</button>
							</ModalFooter>
						</Form>
					)}
				</Formik>
			</Modal>
		</Fragment>
	)
}

export default RateForm
