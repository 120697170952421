import React, { Fragment, FC, useState } from "react"
import "../AdminConsole.scss"
import useAdminService from "Services/AdminService"
import { trackPromise } from "react-promise-tracker"

interface IProps {
	context: any
}

const FuelSurcharge: FC<IProps> = ({ context }) => {
	const adminService = useAdminService()

	const [state, setState] = useState({
		id: context.tableData.id,
		fsc: "",
	})
	const [errors, setErrors] = useState<string>("")

	const inputHandler = (event: any) => {
		validateFieldFn(event.target.value)
		const name = event.target.name
		const value = event.target.value
		updateInput(name, value)
	}

	const updateInput = (key: string, data: string) => {
		const temp = { ...state, [key]: data }
		setState(temp)
	}

	const updateFSC = () => {
		trackPromise(
			adminService.updateRecord("dray_buy_rates_fsc", state).then(() => {
				context.fetchAdminTableFn(context.tableName)
			}),
		)
		reset()
	}

	const reset = () => {
		setState({
			id: context.tableData.id,
			fsc: "",
		})
		setErrors("")
	}

	const validateFieldFn = (amount: string) => {
		let amountFormatted = false
		let amountFormErrors = ""
		if (!amount) return setErrors("")

		if (parseFloat(amount) === 0.0) {
			amountFormErrors = "Amount must be greater than Zero"
		} else {
			amount.match(/^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\d{0,2}|\.\d{1,2})$/)
				? (amountFormatted = true)
				: (amountFormatted = false)
			amountFormErrors = amountFormatted ? "" : "Invalid amount format (###.##)"
		}
		setErrors(amountFormErrors)
	}

	return (
		<Fragment>
			<div className={"card fuel-surcharge"}>
				<div className={"card-header"}>{context.tableName}</div>
				<div className="card-body">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th scope="col" colSpan={2}>
									Fuel Surcharge
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="input-group">
										<div className="input-group-append">
											<span className="input-group-text">Current</span>
											<span className="input-group-text">
												{context.tableData.fsc} &nbsp;
												<i className="fa fa-percent"></i>
											</span>
										</div>
									</div>
								</td>
								<td>
									<div className="input-group">
										<span className="input-group-text">New</span>
										<input
											type="text"
											name="fsc"
											value={state.fsc}
											className="form-control"
											onChange={inputHandler}
										/>
										<div className="input-group-append">
											<span className="input-group-text">
												<i className="fa fa-percent"></i>
											</span>
										</div>
										<button
											type="button"
											className="btn btn-primary"
											disabled={errors !== "" || state.fsc === ""}
											onClick={updateFSC}
										>
											SAVE
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<p hidden={errors === ""} className="errors">
						{errors}
					</p>
				</div>
			</div>
		</Fragment>
	)
}

export default FuelSurcharge
