import React from "react"
import { Form, Formik, Field } from "formik"
import * as Yup from "yup"
import DatePicker from "react-date-picker"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { stringifyDate } from "Utilities/DateUtils"
import "../Page.scss"
import { OceanRateRowType, AcceptedOceanRate } from "Types/OceanRateTypes"

interface IProps {
	currentRow: OceanRateRowType | null
	showModal: boolean
	toogleModal: () => void
	contactNames: string[]
	onSubmit: (values: AcceptedOceanRate) => void
}

export const AcceptFormModal: React.FC<IProps> = ({
	currentRow,
	showModal,
	toogleModal,
	contactNames,
	onSubmit,
}) => {
	const displayForm = (): JSX.Element => {
		return (
			<Formik
				enableReinitialize
				initialValues={{
					acceptedDate: new Date(),
					acceptedUser: contactNames[0],
				}}
				validationSchema={Yup.object({
					acceptedDate: Yup.date()
						.typeError("Accepted date is required")
						.required("Accepted date is required"),
					acceptedUser: Yup.string().required("Accepted user is required"),
				})}
				onSubmit={(values) => {
					const updatedRow = {
						acceptedUser: values.acceptedUser,
						acceptedDate: stringifyDate(values.acceptedDate, "ISO"),
					}
					onSubmit(updatedRow)
				}}
			>
				{({ errors, touched, values, setFieldValue, isValid }) => (
					<Form>
						<ModalBody>
							<div className="form-column">
								<div className="date-picker-wrapper">
									<label className={errors.acceptedDate ? "red-text" : ""}>Accepted Date</label>
									<DatePicker
										value={values.acceptedDate}
										format="MM/dd/yyyy"
										onChange={(value: Date) => setFieldValue("acceptedDate", value)}
										required
									/>
									{errors.acceptedDate && <div className="input-errors">{errors.acceptedDate}</div>}
								</div>
							</div>
							<div className="form-column">
								<div className="form-input-group">
									<label
										htmlFor="acceptedUser"
										className={`${
											touched.acceptedUser && errors.acceptedUser ? "has-error" : null
										}`}
									>
										Accepted User
									</label>
									<Field
										name="acceptedUser"
										className={`form-control ${
											touched.acceptedUser && errors.acceptedUser ? "has-error" : null
										}`}
										as="select"
									>
										{contactNames.map((name) => (
											<option value={name} key={name}>
												{name}
											</option>
										))}
									</Field>
									{touched.acceptedUser && errors.acceptedUser && (
										<div className="input-errors">{errors.acceptedUser}</div>
									)}
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-secondary" type="button" onClick={toogleModal}>
								Cancel
							</button>
							<button className="btn btn-primary" type="submit" disabled={!isValid}>
								Save
							</button>
						</ModalFooter>
					</Form>
				)}
			</Formik>
		)
	}

	const displayError = (): JSX.Element => {
		return (
			<>
				<ModalBody>
					<p>There are no client contacts. Please add a contact in the client profile.</p>
				</ModalBody>
				<ModalFooter>
					<button className="btn btn-secondary" type="button" onClick={toogleModal}>
						Cancel
					</button>
				</ModalFooter>
			</>
		)
	}

	return (
		<Modal isOpen={showModal} toggle={toogleModal}>
			<ModalHeader>Accept Rate for Quote #: {currentRow?.quoteNumber}</ModalHeader>
			{contactNames.length ? displayForm() : displayError()}
		</Modal>
	)
}
