import React, { useContext } from "react"
import UserContext from "../Contexts/User/Context"
import { useNavigate } from "react-router-dom"

function Toolbar() {
	const navigate = useNavigate()
	const user = useContext(UserContext)
	return (
		<div className={"toolbar"}>
			<ToolbarButton action={() => navigate("/app/user-profile")}>
				<i className="toolbar-icon fa fa-user-circle" title="User Profile" aria-hidden="true"></i>
			</ToolbarButton>
			{user && user.details && (user.details.role === "Admin" || user.details.role === "User") ? (
				<ToolbarButton action={() => navigate("/app/admin-console")}>
					<i className="toolbar-icon fa fa-cogs" title="Admin Console" aria-hidden="true"></i>
				</ToolbarButton>
			) : null}
			<ToolbarButton action={() => user.logout()}>
				<i className="toolbar-icon fa fa-sign-out" title="Logout" aria-hidden={true}></i>
			</ToolbarButton>
		</div>
	)
}

function ToolbarButton({ children, action }) {
	return (
		<button className={"btn toolbar-btn"} onClick={() => action()}>
			{children}
		</button>
	)
}

export default Toolbar
