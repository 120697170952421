import React, { useEffect, useState } from "react"
import useSellRateService from "Services/SellRateService"
import FilterSelect from "Shared/Inputs/FilterSelect/FilterSelect"

function ClientFilter({ filter, updateFilter }) {
	const sellRateService = useSellRateService()
	const [clientName, setClientName] = useState("")

	// Set default value
	if (!filter.value) filter.value = null

	const [clients, setClients] = useState([])

	useEffect(() => {
		sellRateService.getClients().then((clients) => {
			setClients(clients)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function updateValue(client) {
		const updatedFilter = { ...filter }
		updatedFilter.value = client.id
		setClientName(client.name)
		updateFilter(updatedFilter)
	}

	return (
		<div>
			<FilterSelect
				label={""}
				placholder={clientName}
				suggestions={clients}
				selectFn={updateValue}
				value={filter.value}
			/>
		</div>
	)
}

export default ClientFilter
