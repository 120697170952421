import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import "./bootstrap-overrides.scss"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { transitions, positions, Provider as AlertProvider } from "react-alert"
import AlertTemplate from "Shared/Alert/AlertTemplate"

const alertOptions = {
	position: positions.BOTTOM_CENTER,
	// timeout: 5000,
	offset: "30px",
	transition: transitions.SCALE,
}

const alertStyle = {
	bottom: 20,
	left: "50%",
	transform: "translate(-50%)",
	position: "fixed",
	zIndex: 100,
}

const Root = () => (
	<AlertProvider style={alertStyle} template={AlertTemplate} {...alertOptions}>
		<App />
	</AlertProvider>
)

ReactDOM.render(<Root />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
