import { FC, useState, useEffect, useMemo, ChangeEvent } from "react"
import {
	ColumnDef,
	useReactTable,
	getCoreRowModel,
	flexRender,
	RowData,
	Row,
	RowSelectionState,
} from "@tanstack/react-table"
import "../AdminConsole.scss"
import useAdminService from "Services/AdminService"
import { editableColumn } from "Shared/ReactTableUtils/TableUtils"

interface Commodity {
	id: number
	text: string
	value: string
	isForwarding: boolean
	isShipping: boolean
	isLandTransport: boolean
	isSystem: boolean
}

declare module "@tanstack/react-table" {
	interface TableMeta<TData extends RowData> {
		updateData: (rowIndex: number, columnId: string, value: unknown) => void
	}
}

interface IProps {
	context: {
		tableName: string
		tableData: Commodity[]
		selectedTableFn: (event: React.ChangeEvent<HTMLSelectElement>) => void
		fetchAdminTableFn: (tableName: string) => void
	}
}

export const AdminCommoditiesTable: FC<IProps> = ({ context }) => {
	const [newEntry, setNewEntry] = useState<boolean>(false)
	const [newCommodity, setNewCommodity] = useState<Commodity>({
		id: 0,
		text: "",
		value: "",
		isForwarding: false,
		isShipping: false,
		isLandTransport: false,
		isSystem: false,
	})
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
	const adminService = useAdminService()
	const [data, setData] = useState<Commodity[]>(context.tableData)
	const isSelected = useMemo<boolean>(() => Object.keys(rowSelection).length > 0, [rowSelection])
	const columns = useMemo<ColumnDef<Commodity>[]>(
		() => [
			{
				accessorKey: "value",
				header: "Code",
			},
			{
				accessorKey: "text",
				header: "Discription",
			},
			{
				accessorKey: "isForwarding",
				header: "Forwarding",
			},
			{
				accessorKey: "isShipping",
				header: "Shipping",
			},
			{
				accessorKey: "isLandTransport",
				header: "Land Transport",
			},
			{
				accessorKey: "isSystem",
				header: "System",
			},
		],
		[],
	)
	useEffect(() => {
		setData([...context.tableData])
	}, [context.tableData])
	const table = useReactTable({
		data,
		columns,
		defaultColumn: editableColumn,
		state: {
			rowSelection,
		},
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		// Provide our updateData function to our table meta
		meta: {
			updateData: (rowIndex, columnId, value) => {
				setData((old) =>
					old.map((row, index) => {
						if (index === rowIndex) {
							return {
								...old[rowIndex],
								[columnId]: value,
							}
						}
						return row
					}),
				)
			},
		},
	})

	const updateRow = (rowIndex: number, rowData: Commodity): void => {
		setData((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...rowData,
					}
				}
				return row
			}),
		)
	}

	const cancel = (row: Row<Commodity>): void => {
		updateRow(row.index, context.tableData[row.index])
		row.toggleSelected()
	}
	const update = (row: Row<Commodity>): void => {
		adminService.updateRecord(context.tableName, row.original).then(() => {
			context.fetchAdminTableFn(context.tableName)
			row.toggleSelected()
		})
	}

	const remove = (record: Commodity): void => {
		adminService.deleteRecord(context.tableName, record.id).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
	}

	const add = () => {
		adminService
			.addRecord(context.tableName, newCommodity)
			.then(() => {
				context.fetchAdminTableFn(context.tableName)
			})
			.finally(() => {
				reset()
			})
	}

	const validateNewCommodity = (): boolean => {
		if (!newCommodity.text || !newCommodity.value) {
			return false
		}
		return true
	}

	const validateRow = (original: Commodity, current: Commodity): boolean => {
		if (!current.text || !current.value) {
			return false
		}
		if (
			original.value !== current.value ||
			original.text !== current.text ||
			original.isForwarding !== current.isForwarding ||
			original.isLandTransport !== current.isLandTransport ||
			original.isShipping !== current.isShipping ||
			original.isSystem !== current.isSystem
		) {
			return true
		}
		return false
	}

	const reset = (): void => {
		setNewCommodity({
			id: 0,
			text: "",
			value: "",
			isForwarding: false,
			isShipping: false,
			isLandTransport: false,
			isSystem: false,
		})
		setNewEntry(false)
	}

	const updateInput = (key: string, data: string | boolean) => {
		const temp = { ...newCommodity, [key]: data }
		setNewCommodity(temp)
	}

	const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name
		const value = event.target.value
		updateInput(name, value)
	}

	const checkHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name
		const value = event.target.checked
		updateInput(name, value)
	}

	return (
		<div className="card other-admin-table">
			<div className="card-header admin-table-title">
				{context.tableName}
				<button
					type="button"
					className="add-btn"
					onClick={() => setNewEntry(true)}
					disabled={newEntry || isSelected}
				>
					<i className="fa fa-plus"></i>
				</button>
			</div>
			<div className="card-body">
				<table className="table table-bordered">
					<thead className="thead-light">
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
									</th>
								))}
								<th>Actions</th>
							</tr>
						))}
						<tr hidden={!newEntry} className="newEntry">
							<td>
								<input
									type="text"
									name="value"
									className="form-control"
									value={newCommodity.value}
									onChange={inputHandler}
								/>
							</td>
							<td>
								<input
									type="text"
									name="text"
									className="form-control"
									value={newCommodity.text}
									onChange={inputHandler}
								/>
							</td>
							<td>
								<input
									type="checkbox"
									name="isForwarding"
									checked={newCommodity.isForwarding}
									onChange={checkHandler}
								/>
							</td>
							<td>
								<input
									type="checkbox"
									name="isShipping"
									checked={newCommodity.isShipping}
									onChange={checkHandler}
								/>
							</td>
							<td>
								<input
									type="checkbox"
									name="isLandTransport"
									checked={newCommodity.isLandTransport}
									onChange={checkHandler}
								/>
							</td>
							<td>
								<input
									type="checkbox"
									name="isSystem"
									checked={newCommodity.isSystem}
									onChange={checkHandler}
								/>
							</td>
							<td>
								<div className="action-cell">
									<button
										type="button"
										className="save-btn"
										onClick={() => add()}
										disabled={!validateNewCommodity()}
									>
										<i className="fa fa-floppy-o"></i>
									</button>
									<button type="button" className="trash-cancel-btn" onClick={() => reset()}>
										<i className="fa fa-ban"></i>
									</button>
								</div>
							</td>
						</tr>
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => {
							return (
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<td key={cell.id}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										)
									})}
									<td>
										<div className="action-cell">
											<button
												type="button"
												hidden={!row.getIsSelected()}
												className="save-btn"
												onClick={() => update(row)}
												disabled={
													!validateRow(
														context.tableData.find((x) => x.id === row.original.id),
														row.original,
													)
												}
											>
												<i className="fa fa-floppy-o"></i>
											</button>
											<button
												type="button"
												hidden={!row.getIsSelected()}
												className="trash-cancel-btn"
												onClick={() => cancel(row)}
											>
												<i className="fa fa-ban"></i>
											</button>
											<button
												type="button"
												hidden={isSelected || newEntry}
												className="edit-btn"
												onClick={row.getToggleSelectedHandler()}
											>
												<i className="fa fa-pencil"></i>
											</button>
											<button
												type="button"
												hidden={isSelected || newEntry}
												className="trash-cancel-btn"
												onClick={() => remove(row.original)}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}
