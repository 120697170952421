export function dateFromString(dateString: string, format: "ISO" | "locale" = "ISO"): Date {
	if (!dateString) return null
	let year
	let month
	let day
	if (format === "ISO") {
		const comps = dateString.split("-")
		year = parseInt(comps[0], 10)
		month = parseInt(comps[1], 10) - 1
		day = parseInt(comps[2], 10)
	} else {
		const comps = dateString.split("/")
		month = parseInt(comps[0], 10) - 1
		day = parseInt(comps[1], 10)
		year = parseInt(comps[2], 10)
	}

	const date = new Date()
	date.setFullYear(year, month, day)

	return date
}

export function stringifyDate(date: Date, format?: "ISO" | "locale"): string {
	if (!date) return ""

	if (format === "ISO") {
		return date.toISOString().split("T")[0]
	} else {
		return date.toLocaleString([], { year: "numeric", month: "numeric", day: "numeric" })
	}
}

export function formatDateString(dateString: string): string {
	if (!dateString) return ""
	const date = dateFromString(dateString)
	return date.toLocaleString([], { year: "numeric", month: "numeric", day: "numeric" })
}

export function formatDateTimeString(dateTimeString: string) {
	if (!dateTimeString) return ""
	const date = new Date(dateTimeString)
	return date.toLocaleString([], {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	})
}

export function formatFilterDateString(value: string): string {
	try {
		const newDate = new Date(value)
		const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate)
		const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate)
		const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate)
		return month + "/" + day + "/" + year
	} catch {
		return "00/00/0000"
	}
}

export function getMinExpirationDate(effectiveDate: Date | null) {
	if (effectiveDate) {
		return new Date(
			effectiveDate.getFullYear(),
			effectiveDate.getMonth(),
			effectiveDate.getDate() + 1,
		)
	} else {
		const minExpirationDate = new Date()
		minExpirationDate.setDate(minExpirationDate.getDate() + 1)
		return minExpirationDate
	}
}
