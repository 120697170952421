import { IClientProfile } from "../Pages/ClientProfile/ClientProfile"
import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"

const useClientService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		createClientProfile: (client: IClientProfile) => {
			return apiContext.post("client/", client, source.token)
		},
		updateClientProfile: (client: IClientProfile, id: number) => {
			return apiContext.post(`client/${id}`, client, source.token)
		},
		getClientProfile: (id: number) => {
			return apiContext.get(`client/${id}`, source.token)
		},
		getClientDropdowns: () => {
			return apiContext.get("dropdown/client", source.token)
		},
		deleteClientProfile: (id: number) => {
			return apiContext.del("client/" + id, source.token)
		},
		getClients: () => {
			return apiContext.get("client/", source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
	}

	return API
}

export default useClientService
