import React, { useState, Fragment } from "react"
import Modal from "Shared/Modal/Modal"
import { useAlert } from "react-alert"
import "./GenerateRateSheet.scss"
import RateSheetMessage from "Shared/Inputs/RateSheetMessage/RateSheetMessage"
import useRateSheetService from "Services/RateSheetService"
import { trackPromise } from "react-promise-tracker"
import { RateSheetItems } from "Pages/SellRate/Helpers"

export interface IProps {
	clientID: number
	rateSheetItems?: RateSheetItems
}

const RateSheetGenerator: React.FC<IProps> = ({ clientID, rateSheetItems }) => {
	const alert = useAlert()

	const [modal, setModal] = useState<boolean>(false)

	const rateSheetService = useRateSheetService()

	function download() {
		rateSheetService.downloadRateSheet(clientID, rateSheetItems).then(
			(data) => {
				const url = window.URL.createObjectURL(new Blob([data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", `RateSheet.xlsx`)
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
			},
			() => {
				alert.error("Unable to download Rate Sheet.", { timeout: 10000 })
			},
		)
	}

	function send(message: any) {
		trackPromise(
			rateSheetService.sendRateSheets([clientID], message, rateSheetItems).then(
				() => {
					console.log("Rate Sheet Sent")
				},
				() => {
					alert.error("Unable to send Rate Sheet.", { timeout: 10000 })
				},
			),
		)
		setModal(!modal)
	}

	return (
		<Fragment>
			<div className="action-wrapper">
				<button type="button" className="btn btn-primary" onClick={() => setModal(!modal)}>
					Send Rate Sheet
				</button>
				<button type="button" className="btn btn-primary" onClick={() => download()}>
					Download Rate Sheet
				</button>
			</div>
			{modal ? (
				<Modal>
					<div className="confirm-modal">
						<p>You are manually sending out the current rate sheet. Do you wish to continue?</p>
						<RateSheetMessage
							placeholder={"Custom Message"}
							dismiss={() => setModal(false)}
							send={send}
						></RateSheetMessage>
					</div>
				</Modal>
			) : null}
		</Fragment>
	)
}
export default RateSheetGenerator
