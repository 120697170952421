import React, { Fragment, FC, useState, useEffect } from "react"
import "../AdminConsole.scss"
import Modal from "../../../Shared/Modal/Modal"
import UsersForm from "./UsersForm"
import useAdminService from "Services/AdminService"

interface IProps {
	context: any
}

const UsersTable: FC<IProps> = ({ context }) => {
	const [user, setUser] = useState<any>({
		id: undefined,
		firstName: "",
		lastName: "",
		userName: "",
		disabled: false,
		password: "",
	})
	const [options, setOptions] = useState([])
	const [formModal, setFormModal] = useState<boolean>(false)
	const [confirmModal, setConfirmModal] = useState<boolean>(false)

	const adminService = useAdminService()

	useEffect(() => {
		const temp = [...context.tableData]
		setOptions(temp)
		// eslint-disable-next-line
	}, [context.tableData])

	const toggleUserFormModal = () => {
		setFormModal(!formModal)
	}

	const userFormModal = () => {
		if (formModal) {
			return (
				<Modal dismiss={toggleUserFormModal}>
					<UsersForm
						userContext={user}
						context={context}
						toggleModal={toggleUserFormModal}
					></UsersForm>
				</Modal>
			)
		} else {
			return null
		}
	}

	const toggleConfirmModal = (user?: any) => {
		if (user) {
			setUser(user)
		}
		setConfirmModal(!confirmModal)
	}

	const confirmDeleteModal = () => {
		if (confirmModal) {
			return (
				<Modal dismiss={() => toggleConfirmModal()}>
					<p>
						Are you sure you want to delete the user
						<br />
						{user.firstName} {user.lastName}
					</p>
					<div className="actions">
						<button
							type="button"
							className="btn btn-danger"
							onClick={() => {
								removeUser(user.id)
							}}
						>
							YES
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => {
								toggleConfirmModal()
							}}
						>
							NO
						</button>
					</div>
				</Modal>
			)
		} else {
			return null
		}
	}

	const newUser = () => {
		setUser(null)
		toggleUserFormModal()
	}

	const editUser = (user: any) => {
		const u = {
			id: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			username: user.username,
			disabled: user.disabled,
			role: user.role,
		}
		setUser(u)
		toggleUserFormModal()
	}

	const removeUser = (id: number) => {
		adminService.deleteUser(id).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
		toggleConfirmModal()
	}

	return (
		<Fragment>
			<div className={"card users-admin-table"}>
				<div className={"card-header users-admin-table-title"}>
					{context.tableName}
					<button type="button" className="add-btn" onClick={() => newUser()}>
						<i className="fa fa-plus"></i>
					</button>
				</div>
				<div className="card-body">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th scope="col">First Name</th>
								<th scope="col">Last Name</th>
								<th scope="col">Username</th>
								<th scope="col">Role</th>
								<th scope="col">Active</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{options
								? options.map((user, index) => (
										<tr key={user.id}>
											<td>{user.firstName}</td>
											<td>{user.lastName}</td>
											<td>{user.username}</td>
											<td>{user.role}</td>
											<td>{user.disabled ? "NO" : "YES"}</td>
											<td>
												<div className="action-cell">
													<button type="button" className="edit-btn" onClick={() => editUser(user)}>
														<i className="fa fa-pencil"></i>
													</button>
													<button
														type="button"
														className="trash-cancel-btn"
														onClick={() => toggleConfirmModal(user)}
													>
														<i className="fa fa-trash"></i>
													</button>
												</div>
											</td>
										</tr>
								  ))
								: null}
						</tbody>
					</table>
				</div>
			</div>
			{userFormModal()}
			{confirmDeleteModal()}
		</Fragment>
	)
}

export default UsersTable
