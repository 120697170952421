import React, { Fragment, useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import TableView from "./AccessorialTableView"

function AccessorialPicker({ saveFn, clientAccessorials, masterAccessorials }) {
	const [accessorialsBackup, setAccesorialsBackup] = useState([])
	const [accessorials, setAccesorials] = useState([])

	useEffect(() => {
		if (!clientAccessorials || !masterAccessorials) return

		const clientAcc = [...clientAccessorials]
		const syncedAcessorials = [...masterAccessorials]

		if (!clientAccessorials.length) {
			const cAccessorials = syncedAcessorials.map((a) => {
				return {
					id: a.id,
					name: a.name,
					location: a.location,
					amount: a.amount,
					custom: a.custom,
					isVisible: false,
					per: a.per,
				}
			})

			setAccesorialsBackup((prevState) => cAccessorials)
			setAccesorials((prevState) => cAccessorials)
		} else {
			clientAcc.forEach((ca) => {
				const index = syncedAcessorials.findIndex((a) => a.id === ca.id)
				const accessorial = syncedAcessorials[index]
				if (index > -1) {
					syncedAcessorials[index] = {
						id: ca.id,
						name: ca.name,
						location: ca.location,
						amount: accessorial.amount,
						custom: ca.isCustom ? ca.amount : "",
						isVisible: true,
						per: ca.per,
					}
				}
			})

			setAccesorialsBackup((prevState) => syncedAcessorials)
			setAccesorials((prevState) => syncedAcessorials)
		}
	}, [clientAccessorials, masterAccessorials])

	function updateAccessorial(accessorial) {
		const value = { ...accessorial }
		if (!accessorial.isVisible) accessorial.custom = ""
		const accessorialsCopy = [...accessorials]
		const index = accessorialsCopy.findIndex((a) => a.id === value.id)

		if (index > -1) {
			accessorialsCopy[index] = value
			setAccesorials(accessorialsCopy)
		} else {
			console.error("Accessorial does not exist in list.")
		}
	}

	function save() {
		const payload = accessorials
			.filter((a) => a.isVisible)
			.map((a) => {
				return {
					id: a.id,
					name: a.name,
					amount: a.custom ? a.custom : null,
					per: a.per,
				}
			})

		saveFn(payload)
		toggle()
	}

	function closeModal() {
		const originalAcessorials = [...accessorialsBackup]
		setAccesorials(originalAcessorials)
		setModal(false)
	}

	const [modal, setModal] = useState(false)
	const toggle = () => setModal((prevState) => !prevState)

	return (
		<Fragment>
			<Button color="primary" onClick={toggle}>
				Accessorials
			</Button>
			<Modal isOpen={modal} toggle={closeModal}>
				<ModalHeader toggle={closeModal}>Accessorials</ModalHeader>
				<ModalBody>
					<TableView accessorials={accessorials} updateAccessorial={updateAccessorial} />
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={() => save()}>
						Save Changes
					</Button>
					<Button color="secondary" onClick={() => closeModal()}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</Fragment>
	)
}

export default AccessorialPicker
