import React, { Fragment, useContext, useEffect, useReducer, useState } from "react"
import Table from "Pages/BuyRates/Dray/RateTable"
import "Pages/BuyRates/Page.scss"

import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"
import { InitialDrayBuyRatePageState, DrayBuyRatePageReducer } from "./Helpers"
import UserContext from "../../../Contexts/User/Context"
import AdvancedSearch from "Shared/DynamicSearch/DynamicSearch"
import RateImporter from "./RateImporter"
import RateForm from "./RateForm"
import useBuyRateService from "Services/BuyRateService"
import { trackPromise } from "react-promise-tracker"
import { Filterable } from "../../../Shared/DynamicSearch/index"

function DrayBuyRateSearchPage({ setHeaderTitle }) {
	const user = useContext(UserContext)
	const [state, dispatcher] = useReducer(DrayBuyRatePageReducer, InitialDrayBuyRatePageState)
	const [dataset, setDataset] = useState(null)
	const [filters, setFilters] = useState(null)
	const buyRateService = useBuyRateService()

	useEffect(() => {
		setHeaderTitle("Dray Rate Search")
		trackPromise(
			buyRateService.getDrayBuyRateDataset().then((dataset) => {
				setDataset(dataset)
			}),
		)
	}, [setHeaderTitle])

	useEffect(() => {
		refreshRates()
	}, [filters])

	function refreshRates() {
		if (filters) {
			trackPromise(
				buyRateService.lookupDrayRates(filters).then((drayRates) => {
					dispatcher({ type: "SET_RATES", payload: drayRates })
				}),
			)
		}
	}

	const disableIfNoFilters = (currentFilters: Filterable[]): boolean => {
		if (currentFilters.length === 0) {
			return true
		}
		const appliedFilters = currentFilters.filter((f) => f.applied === true)
		if (appliedFilters.length) {
			return !appliedFilters.every((f) => (f.value ? true : false))
		} else {
			return true
		}
	}

	const data = React.useMemo(() => [...state.rates], [{}])

	return (
		<div className="buy-rate-page-wrapper">
			<LoadingIndicator />
			<div className="buy-rate-search-wrapper">
				<div className="button-row">
					<AdvancedSearch
						dataset={dataset}
						onApply={(filters) => setFilters(filters)}
						disableApply={disableIfNoFilters}
					></AdvancedSearch>
					{user.details.role === "Admin" ? (
						<Fragment>
							<RateImporter open={state.showRateImportModal} dispatcher={dispatcher}></RateImporter>
							<RateForm
								open={state.showRateFormModal}
								rate={state.rateBuffer}
								dispatcher={dispatcher}
								refreshBuyRateTable={refreshRates}
							></RateForm>
						</Fragment>
					) : null}
				</div>
			</div>
			{data.length ? <Table data={data} dispatcher={dispatcher} /> : null}
		</div>
	)
}

export default DrayBuyRateSearchPage
