import { useCallback, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Modal from "../Modal/Modal"
import "./BlockerPrompt.scss"

function NavigationBlocker({ blockWhen, message, confirmButtonText, cancelButtonText }) {
	const [isBlocking, onConfirm, onCancel] = useNavigationBlocker(blockWhen)

	return (
		<BlockerPrompt
			showPrompt={isBlocking}
			promptMessage={message}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmButtonText={confirmButtonText}
			cancelButtonText={cancelButtonText}
		/>
	)
}

function BlockerPrompt({
	showPrompt,
	promptMessage,
	onConfirm,
	onCancel,
	confirmButtonText,
	cancelButtonText,
}) {
	return showPrompt ? (
		<Modal>
			<div className={"prompt-container"}>
				<div className={"prompt-body"}>{promptMessage}</div>
				<div className={"prompt-actions"}>
					<button type="button" className="btn btn-primary" onClick={onConfirm}>
						{confirmButtonText}
					</button>
					<button type="button" className="btn btn-secondary" onClick={onCancel}>
						{cancelButtonText}
					</button>
				</div>
			</div>
		</Modal>
	) : null
}

function useNavigationBlocker(blockWhen) {
	const navigate = useNavigate()
	const location = useLocation()

	const [isBlocking, setIsBlocking] = useState(false)
	const [lastLocation, setLastLocation] = useState(null)
	const [confirmedNavigation, setConfirmedNavigation] = useState(false)

	const onConfirm = useCallback(() => {
		setIsBlocking(false)
		setConfirmedNavigation(true)
	}, [])

	const onCancel = useCallback(() => {
		setIsBlocking(false)
	}, [])

	const handleNavigation = useCallback(
		(nextLocation) => {
			if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
				setIsBlocking(true)
				setLastLocation(nextLocation)
				return false
			}

			return true
		},
		[confirmedNavigation],
	)

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location.pathname)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [confirmedNavigation, lastLocation])

	//THIS DOES NOT WORK. GVR 11/04/2021.
	// useBlocker(handleNavigation, blockWhen);

	return [isBlocking, onConfirm, onCancel]
}

export default NavigationBlocker
