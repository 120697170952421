import React, { Fragment, FC, useState, useEffect } from "react"
import "../AdminConsole.scss"
import PortGroupForm from "./PortGroupForm"
import useAdminService from "Services/AdminService"
import { ContextType, TableDataType } from "../../../Types/PortGroupTypes"

interface IProps {
	context: ContextType
}

const PortGroups: FC<IProps> = ({ context }) => {
	const [show, setShow] = useState<boolean>(false)
	const [disable, setDisable] = useState<boolean>(false)
	const [content, setContent] = useState<TableDataType[]>([])
	const [row, setRow] = useState<TableDataType>()
	const tableName = "locode_groups"

	const adminService = useAdminService()

	useEffect(() => {
		const temp = [...context.tableData]
		setContent(temp)
		// eslint-disable-next-line
	}, [context.tableData])

	function edit(item: TableDataType) {
		setRow(item)
		setShow(!show)
		setDisable(!disable)
	}

	function add(group: TableDataType) {
		adminService.addRecord(tableName, group).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
	}

	function update(group: TableDataType) {
		adminService.updateRecord(tableName, group).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
	}

	function del(id: number) {
		adminService.deleteRecord(tableName, id).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
	}

	return (
		<Fragment>
			<div className={"card port-group-table"}>
				<div className="card-header port-header-title">
					Port Groups
					<button
						type="button"
						className="add-btn"
						onClick={() => {
							setShow(!show)
							setDisable(!disable)
							setRow(null)
						}}
						disabled={disable}
					>
						<i className="fa fa-plus"></i>
					</button>
				</div>
				<div className="card-body">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th scope="col">Name</th>
								<th scope="col">List of Ports</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>
							{content
								? content.map((item) => (
										<tr key={item.id}>
											<td>{item.name}</td>
											{item.value.length > 0 ? (
												<td>
													<small>{item.value.join(", ")}</small>
												</td>
											) : (
												<td>No Content</td>
											)}
											<td>
												<div className="action-cell">
													<button
														type="button"
														className="edit-btn"
														onClick={() => edit(item)}
														disabled={disable}
													>
														<i className="fa fa-pencil"></i>
													</button>
													<button
														type="button"
														className="trash-cancel-btn"
														onClick={() => del(item.id)}
														disabled={disable}
													>
														<i className="fa fa-trash"></i>
													</button>
												</div>
											</td>
										</tr>
								  ))
								: null}
						</tbody>
					</table>
					{show ? (
						<PortGroupForm
							show={setShow}
							disable={setDisable}
							row={row}
							addFn={add}
							updateFn={update}
							content={content}
						></PortGroupForm>
					) : null}
				</div>
			</div>
		</Fragment>
	)
}

export default PortGroups
