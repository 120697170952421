import React, { useRef, useEffect } from "react"
import uniqid from "uniqid"
import { useField } from "formik"

function Select({ form, label, options, ...props }) {
	const id = useRef(uniqid())
	const [field, meta, helpers] = useField(props.name)

	useEffect(() => {
		if (!options.find((o) => o.value === field.value)) {
			helpers.setValue("")
		}
	}, [form.initialValues])

	return (
		<div className={"form-input-group"}>
			{
				<label
					htmlFor={id.current}
					className={`${meta.touched && meta.error ? "has-error" : null}`}
				>
					{label}
				</label>
			}
			<select
				id={id.current}
				{...field}
				{...props}
				className={`form-control ${meta.touched && meta.error ? "has-error" : null}`}
			>
				<option key={"no-opt"} value="">
					-- None --
				</option>
				{options.map((o) => {
					return (
						<option value={o.value} key={o.text}>
							{o.text}
						</option>
					)
				})}
			</select>
			{meta.touched && meta.error && <div className={"input-errors"}></div>}
		</div>
	)
}

export default Select
