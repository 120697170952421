import React, { FC, Fragment, useState, useEffect } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { Note } from "../ClientProfile"

export interface IProps {
	clientContext
}

const InternalNotes: FC<IProps> = ({ clientContext }) => {
	const [note, setNote] = useState({
		comment: "",
		date: new Date(),
	})
	const type = "internal"
	const [selectedIndex, setSelectedIndex] = useState(-1)
	const [newEntry, setNewEntry] = useState<boolean>(false)
	const [noteIndex, setNoteIndex] = useState<number>(undefined)
	const [errors, setErrors] = useState<string>("")

	useEffect(() => {
		resetNote()
		selectedIndexFn(-1)
		// eslint-disable-next-line
	}, [clientContext.client.notes.internal])

	const inputHandler = (event: any) => {
		const newNote = { ...note, [event.target.name]: event.target.value.replace(/\t/g, " ") }
		setNote(newNote)
	}

	const updateNotes = (notes: any) => {
		clientContext.updateNotesFn(notes, type)
	}

	const addNote = (event: any) => {
		event.preventDefault()
		const notesCheck = clientContext.client.notes.internal.filter(
			(item) => item.comment === note.comment,
		).length
		if (!notesCheck) {
			const newNote = [...clientContext.client.notes.internal, note]
			updateNotes(newNote)
			resetNote()
		} else {
			setErrors("Duplicate notes.")
		}
	}

	const removeNote = (i: number) => {
		let arr = [...clientContext.client.notes.internal]
		arr = arr.filter((_, index) => index !== i)
		updateNotes(arr)
		resetNote()
	}

	const editNote = (index: number, comment: string) => {
		setSelectedIndex(index)
		setNote({ comment: comment.replace(/\t/g, " "), date: new Date() })
		setNoteIndex(index)
	}

	const updateNote = () => {
		const arr = [...clientContext.client.notes.internal]
		arr[noteIndex] = note
		updateNotes(arr)
		resetNote()
	}

	const resetNote = () => {
		setNote({
			comment: "",
			date: new Date(),
		})
		setNewEntry(false)
		setErrors("")
	}

	const display = () => {
		setNewEntry(true)
	}

	const selectedIndexFn = (index) => {
		if (index !== -1) {
			setSelectedIndex(index)
		} else {
			resetNote()
			setSelectedIndex(-1)
		}
	}

	const noteCells = () => {
		if (clientContext.client.notes.internal.length) {
			return clientContext.client.notes.internal.map((n: Note, index) => (
				<tr key={index}>
					<td className="date-col">{new Date(n.date).toLocaleDateString()}</td>
					<td className="comm-col">
						{selectedIndex === index ? (
							<TextareaAutosize
								name="comment"
								placeholder="New Note"
								value={note.comment}
								onChange={inputHandler}
								className={`form-control ${errors ? "has-error" : null}`}
							/>
						) : (
							n.comment
						)}
					</td>
					<td className="act-col">
						<div className="action-cell">
							<button
								type="button"
								disabled={note.comment === ""}
								hidden={!(selectedIndex === index)}
								className="save-btn"
								onClick={updateNote}
							>
								<i className="fa fa-floppy-o"></i>
							</button>
							<button
								type="button"
								hidden={!(selectedIndex === index)}
								className="trash-cancel-btn"
								onClick={() => selectedIndexFn(-1)}
							>
								<i className="fa fa-ban"></i>
							</button>
							<button
								type="button"
								className="edit-btn"
								disabled={newEntry}
								hidden={selectedIndex === index}
								onClick={() => editNote(index, n.comment)}
							>
								<i className="fa fa-pencil"></i>
							</button>
							<button
								type="button"
								className="trash-cancel-btn"
								disabled={newEntry}
								hidden={selectedIndex === index}
								onClick={() => removeNote(index)}
							>
								<i className="fa fa-trash"></i>
							</button>
						</div>
					</td>
				</tr>
			))
		} else {
			return (
				<tr key={-1}>
					<td colSpan={3} className="no-notes">
						{" "}
						No Internal Notes{" "}
					</td>
				</tr>
			)
		}
	}

	return (
		<Fragment>
			<div className="card note-wrapper">
				<div className="card-header">
					Internal Notes
					<button
						type="button"
						className="add-btn"
						onClick={display}
						disabled={newEntry || selectedIndex !== -1}
					>
						<i className="fa fa-plus"></i>
					</button>
				</div>
				<div className="card-body">
					<div className="col notes-table">
						<table className="table table-bordered">
							<thead className="thead-light">
								<tr>
									<th scope="col">Date</th>
									<th scope="col">Comment</th>
									<th scope="col"></th>
								</tr>
								<tr hidden={!newEntry} className="newEntry">
									<th>{new Date().toLocaleDateString()}</th>
									<th>
										<TextareaAutosize
											name="comment"
											placeholder="New Note"
											value={note.comment}
											onChange={inputHandler}
											className={`form-control ${errors ? "has-error" : null}`}
										/>
									</th>
									<th>
										<div className="action-cell">
											<button
												type="button"
												disabled={note.comment === ""}
												className="save-btn"
												onClick={addNote}
											>
												<i className="fa fa-floppy-o"></i>
											</button>
											<button type="button" className="trash-cancel-btn" onClick={resetNote}>
												<i className="fa fa-ban"></i>
											</button>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>{noteCells()}</tbody>
						</table>
					</div>
					<p hidden={errors === ""} className="errors">
						{errors}
					</p>
				</div>
			</div>
		</Fragment>
	)
}

export default InternalNotes
