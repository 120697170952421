import React, { FC, useState, Fragment, useEffect } from "react"
import "../ClientProfile.scss"
import { Contact } from "../ClientProfile"

export interface IProps {
	clientContext
}

const ClientContacts: FC<IProps> = ({ clientContext }) => {
	const [contact, setContact] = useState({
		name: "",
		email: "",
		rateSheet: false,
	})
	const type = "external"
	const [selectedIndex, setSelectedIndex] = useState(-1)
	const [newEntry, setNewEntry] = useState<boolean>(false)
	const [contactIndex, setContactIndex] = useState<number>(undefined)
	const [errors, setErrors] = useState<string>("")

	useEffect(() => {
		resetFields()
		selectedIndexFn(-1)
		// eslint-disable-next-line
	}, [clientContext.client.contacts.external])

	const inputHandler = (event: any) => {
		const newVal = { ...contact, [event.target.name]: event.target.value }
		if (event.target.name === "email") {
			validateField(newVal.email)
		}
		if (event.target.name === "rateSheet") {
			newVal.rateSheet = event.target.checked
		}
		setContact(newVal)
	}

	const updateContacts = (contacts: any) => {
		clientContext.updateContactsFn(contacts, type)
	}

	const addContact = (event: any) => {
		event.preventDefault()
		const contactCheck = clientContext.client.contacts.external.filter(
			(item) => item.email.toLowerCase() === contact.email.toLowerCase(),
		).length
		if (contactCheck) {
			setErrors("Duplicate email.")
		} else {
			const newCont = [...clientContext.client.contacts.external, contact]
			updateContacts(newCont)
			resetFields()
		}
	}

	const removeContact = (i: number) => {
		let arr = [...clientContext.client.contacts.external]
		arr = arr.filter((_, index) => index !== i)
		updateContacts(arr)
		resetFields()
	}

	const editContact = (index: number, name: string, email: string, rate: boolean) => {
		setSelectedIndex(index)
		setContact({ name: name, email: email, rateSheet: rate })
		setContactIndex(index)
	}

	const updateContact = () => {
		const arr = [...clientContext.client.contacts.external]
		arr[contactIndex] = contact
		updateContacts(arr)
		resetFields()
	}

	const resetFields = () => {
		setContact({ name: "", email: "", rateSheet: false })
		setNewEntry(false)
		setErrors("")
	}

	const validateField = (email: string) => {
		let emailFormatted = false
		email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
			? (emailFormatted = true)
			: (emailFormatted = false)
		const emailFormErrors = emailFormatted ? "" : "Invalid email format."
		setErrors(emailFormErrors)
	}

	const display = () => {
		setNewEntry(true)
	}

	const selectedIndexFn = (index) => {
		if (index !== -1) {
			setSelectedIndex(index)
		} else {
			resetFields()
			setSelectedIndex(-1)
		}
	}

	const contactCells = () => {
		if (clientContext.client.contacts.external.length) {
			return clientContext.client.contacts.external.map((c: Contact, index) => (
				<tr key={index}>
					<td>
						{selectedIndex === index ? (
							<input
								type="text"
								value={contact.name}
								name="name"
								className="form-control"
								onChange={(event) => inputHandler(event)}
							/>
						) : (
							c.name
						)}
					</td>
					<td>
						{selectedIndex === index ? (
							<input
								type="email"
								value={contact.email}
								name="email"
								className={`form-control ${errors ? "has-error" : null}`}
								onChange={(event) => inputHandler(event)}
							/>
						) : (
							c.email
						)}
					</td>
					<td>
						{selectedIndex === index ? (
							<input
								type="checkbox"
								name="rateSheet"
								checked={contact.rateSheet}
								onChange={(event) => {
									inputHandler(event)
								}}
							/>
						) : c.rateSheet ? (
							"YES"
						) : (
							"NO"
						)}
					</td>
					<td className="act-col">
						<div className="action-cell">
							<button
								type="button"
								disabled={contact.name === "" || contact.email === "" || errors !== ""}
								hidden={!(selectedIndex === index)}
								className="save-btn"
								onClick={updateContact}
							>
								<i className="fa fa-floppy-o"></i>
							</button>
							<button
								type="button"
								hidden={!(selectedIndex === index)}
								className="trash-cancel-btn"
								onClick={() => selectedIndexFn(-1)}
							>
								<i className="fa fa-ban"></i>
							</button>
							<button
								type="button"
								className="edit-btn"
								disabled={newEntry}
								hidden={selectedIndex === index}
								onClick={() =>
									editContact(index, c.name, c.email, c.rateSheet ? c.rateSheet : false)
								}
							>
								<i className="fa fa-pencil"></i>
							</button>
							<button
								type="button"
								className="trash-cancel-btn"
								disabled={newEntry}
								hidden={selectedIndex === index}
								onClick={() => removeContact(index)}
							>
								<i className="fa fa-trash"></i>
							</button>
						</div>
					</td>
				</tr>
			))
		} else {
			return (
				<tr key={-1}>
					<td colSpan={4} className="no-contacts">
						{" "}
						No External Contacts{" "}
					</td>
				</tr>
			)
		}
	}

	return (
		<Fragment>
			<div className="card contact-wrapper">
				<div className="card-header">
					Client Contacts
					<button
						type="button"
						className="add-btn"
						onClick={display}
						disabled={newEntry || selectedIndex !== -1}
					>
						<i className="fa fa-plus"></i>
					</button>
				</div>
				<div className="card-body">
					<div className="col contacts-table">
						<table className="table table-bordered">
							<thead className="thead-light">
								<tr>
									<th scope="col">Name</th>
									<th scope="col">Email</th>
									<th scope="col">Email Rate Sheet</th>
									<th scope="col"></th>
								</tr>
								<tr hidden={!newEntry} className="newEntry">
									<th>
										<input
											type="text"
											name="name"
											className="form-control"
											value={contact.name}
											onChange={inputHandler}
										/>
									</th>
									<th>
										<input
											type="text"
											name="email"
											className={`form-control ${errors ? "has-error" : null}`}
											value={contact.email}
											onChange={inputHandler}
										/>
									</th>
									<th>
										<input
											type="checkbox"
											name="rateSheet"
											checked={contact.rateSheet}
											onChange={inputHandler}
										/>
									</th>
									<th>
										<div className="action-cell">
											<button
												type="button"
												disabled={contact.name === "" || contact.email === "" || errors !== ""}
												className="save-btn"
												onClick={addContact}
											>
												<i className="fa fa-floppy-o"></i>
											</button>
											<button type="button" className="trash-cancel-btn" onClick={resetFields}>
												<i className="fa fa-ban"></i>
											</button>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>{contactCells()}</tbody>
						</table>
					</div>
					<p hidden={errors === ""} className="errors">
						{errors}
					</p>
				</div>
			</div>
		</Fragment>
	)
}

export default ClientContacts
