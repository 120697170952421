import React, { Fragment, useState } from "react"
import "./CreatePSS.scss"
import DatePicker from "react-date-picker"
import "../ControlTower.scss"
import { formatDateString, dateFromString } from "Utilities/DateUtils"

interface IProps {
	logs: any
	updatePSSFn: Function
}

const PSSLogs: React.FC<IProps> = ({ logs, updatePSSFn }) => {
	const [expirationDate, setExpirationDate] = useState({
		date: null,
	})
	const [selectedIndex, setSelectedIndex] = useState(-1)
	const rates = ["rate20", "rate40", "rate40hc", "rate45"]

	const selectedIndexFn = (index, d?: Date) => {
		const temp = { ...expirationDate }
		if (index !== -1) {
			setSelectedIndex(index)
			temp.date = d
			setExpirationDate(temp)
		} else {
			setSelectedIndex(-1)
			temp.date = null
			setExpirationDate(temp)
		}
	}

	const inputHandler = (d: any) => {
		setExpirationDate({ date: d })
	}

	const updatePSS = (id: number, date?: any) => {
		let expDate = {}
		setSelectedIndex(-1)
		if (!date) {
			expDate = { expirationDate: null }
		} else {
			expDate = { expirationDate: date.toLocaleDateString() }
		}
		updatePSSFn(id, expDate)
	}

	const getMinExpirationDate = (effectiveDateString: string) => {
		const effectiveDate = dateFromString(effectiveDateString, "ISO")
		return new Date(
			effectiveDate.getFullYear(),
			effectiveDate.getMonth(),
			effectiveDate.getDate() + 1,
		)
	}

	return (
		<Fragment>
			<div id="log-table" className="card">
				<label className="card-header">PSS Log</label>
				<table className="table table-bordered">
					<thead className="thead-light">
						<tr className="text-center">
							<th scope="col">Customer</th>
							<th scope="col">Effective Date</th>
							<th scope="col">Expiration Date</th>
							<th scope="col">Type</th>
							<th scope="col">Trade</th>
							<th scope="col">20&apos;</th>
							<th scope="col">40&apos;</th>
							<th scope="col">40&apos;HC</th>
							<th scope="col">45&apos;</th>
						</tr>
					</thead>
					<tbody>
						{logs.length > 0 ? (
							logs.map((l, index) => (
								<tr key={l.id}>
									<td className="static-data text-center">{l.clientName}</td>
									<td className="text-center">{formatDateString(l.effectiveDate)}</td>
									<td className="text-center">
										{!(selectedIndex === index) ? (
											<div>
												{l.expirationDate ? (
													<div>
														{formatDateString(l.expirationDate)}
														<i
															className="fa fa-calendar"
															onClick={() => {
																selectedIndexFn(index)
															}}
														></i>
													</div>
												) : (
													<div>
														<small>No Expiration Date</small>
														<i
															className="fa fa-calendar"
															onClick={() => {
																selectedIndexFn(index)
															}}
														></i>
													</div>
												)}
											</div>
										) : (
											<div className="action">
												<DatePicker
													name="expirationDate"
													format="MM/dd/yyyy"
													onChange={(value) => {
														inputHandler(value)
													}}
													value={expirationDate.date}
													minDate={getMinExpirationDate(l.effectiveDate)}
												/>
												<button
													type="button"
													className="save"
													onClick={() => {
														updatePSS(l.id, expirationDate.date)
													}}
												>
													Save
												</button>
												<button
													type="button"
													className="cancel"
													onClick={() => {
														selectedIndexFn(-1)
													}}
												>
													Cancel
												</button>
											</div>
										)}
									</td>
									<td className="text-center">{l.rateType}</td>
									<td className="text-center">{l.trade}</td>
									{rates.map((key: any) => (
										<td className="text-center" key={l.id + key}>
											${l[key]}
										</td>
									))}
								</tr>
							))
						) : (
							<tr className="no-data">
								<td colSpan={9}>No Data to Display</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</Fragment>
	)
}

export default PSSLogs
