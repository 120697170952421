import React, { useState, useEffect } from "react"
import { Form, Formik, isObject } from "formik"
import * as Yup from "yup"

import NumberInput from "Shared/Formik/NumberInput"
import LocodeSelect from "Shared/Formik/LocodeSelect/LocodeSelect"
import DatePicker from "react-date-picker"
import { dateFromString, getMinExpirationDate } from "Utilities/DateUtils"
import TextInput from "Shared/Formik/TextInput"

interface IProps {
	rate: any
	submitHandler: any
	discardHandler: any
	clearRateBuffer: any
}

function DrayRateForm(props: IProps) {
	const defaultRate = {
		effectiveDate: null,
		expirationDate: null,
		destinationCy: null,
		destinationDoor: "",
		baseRate: 0,
		fuelSurcharge: 0,
		total: 0,
	}

	// Passing the whole formik object in maybe a little hacky but there's hardly any documentation
	// on updating different form values based on an input of another value.
	function changeInterceptor(event: any, form) {
		const newFormValues = { ...form.values, [event.target.name]: event.target.value }
		newFormValues.total = calculateTotal(newFormValues.baseRate, newFormValues.fuelSurcharge)
		form.setValues(newFormValues)
	}

	function calculateTotal(baseRate, fuelSurcharge): number {
		const rate = parseInt(baseRate)
		const fuel = parseInt(fuelSurcharge)

		if (rate * fuel === 0) return rate
		return Math.round(rate + rate * (fuelSurcharge / 100))
	}

	function dateChange(form: any, key: string, date: Date) {
		form.setFieldValue(key, date, true)
	}

	function prepareRate(rate: any) {
		if (!isObject(rate.effectiveDate)) {
			const serializedEffectiveDate = rate.effectiveDate ? dateFromString(rate.effectiveDate) : null
			const serializedExpirationDate = rate.expirationDate
				? dateFromString(rate.expirationDate)
				: null
			return {
				...rate,
				effectiveDate: serializedEffectiveDate,
				expirationDate: serializedExpirationDate,
			}
		} else {
			return {
				...rate,
			}
		}
	}

	const [formValue, setFormValue] = useState(defaultRate)
	useEffect(() => {
		if (props.rate) {
			setFormValue(prepareRate(props.rate))
			props.clearRateBuffer()
		}
		// eslint-disable-next-line
	}, [props.rate])

	return (
		<Formik
			initialValues={formValue}
			enableReinitialize={true}
			validationSchema={Yup.object({
				effectiveDate: Yup.date().required(),
				expirationDate: Yup.date().nullable(),
				destinationCy: Yup.object().required(),
				destinationDoor: Yup.string().required(),
				baseRate: Yup.number().required().positive().integer(),
				fuelSurcharge: Yup.number().min(0).integer(),
			})}
			onSubmit={(values) => props.submitHandler("dray", values)}
		>
			{(form) => (
				<Form id={"dray-rate-form"} autoComplete="off">
					<div className={"form-column justify-start align-start"}>
						<div className={"form-row jusify-start align-end"}>
							<div className={"date-picker-wrapper"}>
								<label
									className={form.submitCount > 0 && form.errors.effectiveDate ? "red-text" : ""}
								>
									Effective Date*
								</label>
								<DatePicker
									value={form.values.effectiveDate}
									format="MM/dd/yyyy"
									onChange={(value: Date) => dateChange(form, "effectiveDate", value)}
								/>
							</div>
							<div className={"date-picker-wrapper"}>
								<label>Expiration Date</label>
								<DatePicker
									value={form.values.expirationDate}
									format="MM/dd/yyyy"
									onChange={(value: Date) => dateChange(form, "expirationDate", value)}
									minDate={getMinExpirationDate(form.values.effectiveDate)}
								/>
							</div>
						</div>
						<div className={"form-row justify-start align-end"}>
							<LocodeSelect
								from_buy_rates={true}
								name={"destinationCy"}
								label={"Destination CY*"}
								form={form}
							/>
							<TextInput
								name="destinationDoor"
								label={"Destination Door*"}
								type={"text"}
							></TextInput>
							<NumberInput
								label={"Base Rate*"}
								name={"baseRate"}
								type={"number"}
								onChange={(e) => changeInterceptor(e, form)}
							/>
							<NumberInput
								label={"Fuel Surcharge"}
								name={"fuelSurcharge"}
								type={"number"}
								onChange={(e) => changeInterceptor(e, form)}
							/>
							<p className={"total-label"}>TOTAL: ${form.values.total ? form.values.total : 0}</p>
						</div>
						<div className={"form-row justify-start align-center"}>
							<button
								type={"button"}
								className={"btn btn-secondary"}
								onClick={() => props.discardHandler()}
								disabled={form.isSubmitting}
							>
								Discard
							</button>
							<button
								type={"submit"}
								className={"btn btn-primary"}
								disabled={form.isSubmitting || !form.isValid}
							>
								Save
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default DrayRateForm
