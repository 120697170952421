import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"

import ExpiringRateWidget from "Pages/DashboardHome/ExpiringRateWidget/ExpiringRateWidget"
import DraftWidget from "./DraftWidget/DraftWidget"
import "./DashboardHome.scss"
import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"
import ExpiredRateWidget from "Pages/DashboardHome/ExpiredRateWidget/ExpiredRateWidget"
import UserContext from "../../Contexts/User/Context"

function DashboardHome({ setHeaderTitle }) {
	const user = useContext(UserContext)

	useEffect(() => {
		setHeaderTitle("Dashboard")
	}, [setHeaderTitle])

	return (
		<div className="dashboard-page-wrapper">
			<Helmet>
				<title>Home | UWL Pricing Tool</title>
			</Helmet>
			<LoadingIndicator />
			<div className="alert alert-success" role="alert">
				<h2>
					<strong>Welcome {user.details.firstName}</strong>,
				</h2>
				<h5>below are some things that may need your attention.</h5>
			</div>
			<div className={"widget-layout"}>
				<DraftWidget />
				<ExpiringRateWidget />
				<ExpiredRateWidget />
			</div>
		</div>
	)
}

export default DashboardHome
