import React, { Fragment, useState, useEffect, FC } from "react"
import useAdminService from "Services/AdminService"
import InternalContactsCell from "./InternalContactsCell"

interface IProps {
	context: any
}

const InternalContactsTable: FC<IProps> = ({ context }) => {
	const [contacts, setContacts] = useState([])
	const [record, setRecord] = useState({
		id: undefined,
		name: "",
		email: "",
		position: "",
	})
	const [newEntry, setNewEntry] = useState<boolean>(false)
	const [errors, setErrors] = useState<string>("")
	const [selectedIndex, setSelectedIndex] = useState(-1)
	const [positions, setPositions] = useState([])

	useEffect(() => {
		adminService.getInternalContactsDropdowns().then((data) => {
			setPositions(data.contactPositions)
		})
		// eslint-disable-next-line
	}, [])

	const adminService = useAdminService()

	useEffect(() => {
		const temp = [...context.tableData]
		setContacts(temp)
		// eslint-disable-next-line
	}, [context.tableData])

	const display = (acc: any) => {
		setNewEntry(true)
	}

	const inputHandler = (event: any) => {
		const name = event.target.name
		let value = event.target.value
		if (event.target.name === "email") {
			validateEmailField(value)
			value = value.toLowerCase()
		}
		updateInput(name, value)
	}

	const updateInput = (key: string, data: string) => {
		const temp = { ...record, [key]: data }
		setRecord(temp)
	}

	const add = () => {
		adminService.addRecord(context.tableName, record).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
		reset()
	}

	const update = (record: any) => {
		adminService.updateRecord(context.tableName, record).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
		reset()
		selectedIndexFn(-1)
	}

	const remove = (record: any) => {
		adminService.deleteRecord(context.tableName, record.id).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
		reset()
	}

	const reset = () => {
		setRecord({
			id: undefined,
			name: "",
			email: "",
			position: "",
		})
		setNewEntry(false)
		setErrors("")
	}

	const validateEmailField = (email: string) => {
		let emailFormatted = false
		email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
			? (emailFormatted = true)
			: (emailFormatted = false)
		const emailFormErrors = emailFormatted ? "" : "Invalid email format."
		setErrors(emailFormErrors)
	}

	const selectedIndexFn = (index) => {
		setErrors("")
		setSelectedIndex(index)
	}

	return (
		<Fragment>
			<div className={"card admin-table"}>
				<div className={"card-header admin-table-title"}>
					{context.tableName}
					<button
						type="button"
						className="add-btn"
						onClick={display}
						disabled={newEntry || selectedIndex !== -1}
					>
						<i className="fa fa-plus"></i>
					</button>
				</div>
				<div className="card-body">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th scope="col">Name</th>
								<th scope="col">Email</th>
								<th scope="col">Position</th>
								<th scope="col">Actions</th>
							</tr>
							<tr hidden={!newEntry} className="newEntry">
								<th>
									<input
										type="text"
										name="name"
										value={record.name}
										onChange={(event) => {
											inputHandler(event)
										}}
									/>
								</th>
								<th>
									<input
										type="text"
										name="email"
										value={record.email}
										className={errors ? "cell-error" : null}
										onChange={(event) => {
											inputHandler(event)
										}}
									/>
								</th>
								<th>
									<select
										name="position"
										value={record.position}
										className="form-control"
										onChange={(event) => inputHandler(event)}
									>
										<option>-- Select Position --</option>
										{positions.map((opt, index) => (
											<option key={index} value={opt.value}>
												{opt.text}
											</option>
										))}
									</select>
								</th>
								<th colSpan={2}>
									<div className="action-cell">
										<button
											type="button"
											disabled={
												record.name === "" ||
												record.name === "" ||
												record.position === "" ||
												errors !== ""
											}
											className="save-btn"
											onClick={add}
										>
											<i className="fa fa-floppy-o"></i>
										</button>
										<button type="button" className="trash-cancel-btn" onClick={reset}>
											<i className="fa fa-ban"></i>
										</button>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{contacts
								? contacts.map((contact, index) => (
										<InternalContactsCell
											contact={contact}
											rowIndex={index}
											selectedIndexFn={selectedIndexFn}
											selectedIndex={selectedIndex}
											key={contact.id}
											newEntry={newEntry}
											validateFieldFn={validateEmailField}
											errors={errors}
											updateFn={update}
											removeFn={remove}
											positions={positions}
										></InternalContactsCell>
								  ))
								: null}
						</tbody>
					</table>
					<p hidden={errors === ""} className="errors">
						{errors}
					</p>
				</div>
			</div>
		</Fragment>
	)
}

export default InternalContactsTable
