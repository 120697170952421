import React, { useEffect, useState } from "react"
import "./DraftWidget.scss"
import { trackPromise } from "react-promise-tracker"
import useDashboardService from "Services/DashboardService"
import { formatDateTimeString } from "Utilities/DateUtils"
import { useNavigate } from "react-router-dom"

function DraftWidget() {
	const navigate = useNavigate()
	const [data, setData] = useState([])

	const dashboardService = useDashboardService()

	useEffect(() => {
		getDrafts()
		// eslint-disable-next-line
	}, [])

	function getDrafts() {
		trackPromise(
			dashboardService.getGriGrdDrafts().then((data) => {
				setData(data)
			}),
		)
	}

	function goToDraft(draftId: number) {
		trackPromise(
			dashboardService.getDraftPreview(draftId).then((data) => {
				navigate("/app/control-tower/review", { state: { griObject: data, draftId: draftId } })
			}),
		)
	}

	function deleteDraft(draftId: number) {
		trackPromise(
			dashboardService.deleteDraft(draftId).then(() => {
				getDrafts()
			}),
		)
	}

	return (
		<div className="card widget">
			<div className="card-header">GRI/GRD Drafts</div>
			<div className={"widget-table-wrapper"}>
				<table className={"table table-bordered"}>
					<thead className="thead-light">
						<tr>
							<th scope="col">Apply To</th>
							<th scope="col">Trade</th>
							<th scope="col">Last Updated</th>
							<th scope="col" className={"widget-action-column"}>
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						{data.map((d) => (
							<tr key={d.id}>
								<td>{d.applyTo}</td>
								<td>{d.trade}</td>
								<td>{formatDateTimeString(d.lastUpdate)}</td>
								<td>
									<button className={"btn btn-link"} onClick={() => goToDraft(d.id)}>
										Continue
									</button>
									<button className={"btn btn-link"} onClick={() => deleteDraft(d.id)}>
										Delete
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default DraftWidget
