import React, { Fragment, FC, useState, useEffect } from "react"
import "../AdminConsole.scss"
import RateSheetForm from "./RateSheetForm"

interface IProps {
	context: any
}

const RateSheetContent: FC<IProps> = ({ context }) => {
	const [show, setShow] = useState<boolean>(false)
	const [disable, setDisable] = useState<boolean>(false)
	const [content, setContent] = useState<any>([])
	const [row, setRow] = useState<any>()

	useEffect(() => {
		const temp = [...context.tableData]
		setContent(temp)
		// eslint-disable-next-line
	}, [context.tableData])

	function edit(item: any) {
		setRow(item)
		setShow(!show)
		setDisable(!disable)
	}

	return (
		<Fragment>
			<div className={"card rate-content-table"}>
				<div className="card-header">Rate Sheet Legal Content</div>
				<div className="card-body">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th scope="col">Section</th>
								<th scope="col">Content</th>
								<th scope="col">Edit</th>
							</tr>
						</thead>
						<tbody>
							{content
								? content.map((item, index) => (
										<tr key={item.id}>
											<td>{item.header}</td>
											{item.lines.length > 0 ? (
												<td>
													{item.lines.map((line, index) => (
														<div key={index}>
															<small>{line}</small>
															<br />
														</div>
													))}
												</td>
											) : (
												<td>No Content</td>
											)}
											<td>
												<div className="action-cell">
													<button
														type="button"
														className="edit-btn"
														onClick={() => edit(item)}
														disabled={disable}
													>
														<i className="fa fa-pencil"></i>
													</button>
												</div>
											</td>
										</tr>
								  ))
								: null}
						</tbody>
					</table>
					{show ? (
						<RateSheetForm
							context={context}
							show={setShow}
							disable={setDisable}
							row={row}
						></RateSheetForm>
					) : null}
				</div>
			</div>
		</Fragment>
	)
}

export default RateSheetContent
