import React, { Fragment, useState, useEffect, useContext, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Modal from "Shared/Modal/Modal"
import "../ReviewPage/ReviewPage.scss"
import { useAlert } from "react-alert"

import { trackPromise } from "react-promise-tracker"
import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"
import useControlTowerService from "Services/ControlTowerService"
import MarginAnalysisContext from "Contexts/MarginAnalysis/Context"
import MarginAnalysisPopout from "Contexts/MarginAnalysis/MarginAnalysisPopout/MarginAnalysisPopout"
import useActivityListener from "Shared/CustomHooks/ActivityListenerHook"
import useRateSheetService from "Services/RateSheetService"

const headers = ["Origin", "POD", "Dest. CY", "20'", "40'", "40'HC", "45'", ""]

function ReviewPage({ setHeaderTitle }) {
	const navigate = useNavigate()
	const alert = useAlert()
	const myLocation = useLocation()

	const applyTo = (myLocation.state as any).applyTo
	const trade = (myLocation.state as any).trade
	const [draftId, setDraftId] = useState((myLocation.state as any).draftId)
	const [clients, setClients] = useState(null)
	const [clientsSnapshot, setClientsSnapshot] = useState([])
	const [loaded, setLoaded] = useState(false)

	const controlTowerService = useControlTowerService()
	const rateSheetService = useRateSheetService()

	useEffect(() => {
		setHeaderTitle("GRI/GRD Review")
		// Array of clients with their results
		const rateChangesList = (myLocation.state as any).griObject

		// TODO: Rate changes list returned from margin analysis should match what is recieved from the server and passed in to this component
		// from the control tower.
		// rateChangesList object when returning to this page from the margin analysis has the results as an object instead of an array
		// so this check is place to properly convert that object into an array.
		rateChangesList.forEach((element) => {
			if (typeof element.results === "object") {
				const results = []
				const keys = Object.keys(element.results)
				keys.forEach((key) => {
					results.push(element.results[key])
				})
				element.results = results
			}
		})

		// Because we aren't using dates and typed arrays we can use this trick to perform a deep copy
		setClients(JSON.parse(JSON.stringify(rateChangesList)))
		setClientsSnapshot(JSON.parse(JSON.stringify(rateChangesList)))
		setLoaded(true)

		return () => {
			saveDraft()
			clearTimeout(inactivity.current)
		}
		// eslint-disable-next-line
	}, [setHeaderTitle])

	const clientsRef = useRef([])
	const draftIdRef = useRef(null)
	useEffect(() => {
		clientsRef.current = clients
		draftIdRef.current = draftId

		if (clients && !clients.length) {
			navigate("/app/control-tower")
			if (draftId) {
				controlTowerService.deleteDraft(draftId)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clients, draftId])

	const inactivity = useRef(null)
	const resetInactivity = () => {
		clearTimeout(inactivity.current)
		inactivity.current = setTimeout(saveDraft, 15000)
	}

	useActivityListener(resetInactivity)

	const saveDraft = () => {
		if (!clientsRef.current.length) return

		const payload = { applyTo: applyTo, trade: trade, data: clientsRef.current }

		if (!draftIdRef.current) {
			controlTowerService.createNewDraft(payload).then((data) => {
				setDraftId(data.draftId)
			})
		} else {
			controlTowerService.saveDraft(draftIdRef.current, payload)
		}
	}

	function isRateWithinMargin(
		clientIndex,
		rate: any,
		containerSize: "rate20" | "rate40" | "rate40hc" | "rate45",
	): boolean {
		const clientMin = clients[clientIndex].marginMin
		const clientMax = clients[clientIndex].marginMax

		if (rate[containerSize].sellAmount === 0 && rate[containerSize].rateChange === 0) {
			// rate is not affected by gri/grd so it's within margin automatically
			return true
		} else {
			const margin = rate[containerSize].sellAmount - rate[containerSize].avgBuyAmountTop3
			return margin >= clientMin && margin <= clientMax
		}
	}

	function checkOverallClientMargins(clientIndex: number): boolean {
		if (!clients.length) return false

		const sellRates = clients[clientIndex].results

		for (let i = 0; i < sellRates.length; i++) {
			const rate20WithinMargin = isRateWithinMargin(clientIndex, sellRates[i], "rate20")
			const rate40WithinMargin = isRateWithinMargin(clientIndex, sellRates[i], "rate40")
			const rate40hcWithinMargin = isRateWithinMargin(clientIndex, sellRates[i], "rate40hc")
			const rate45WithinMargin = isRateWithinMargin(clientIndex, sellRates[i], "rate45")

			if (
				!rate20WithinMargin ||
				!rate40WithinMargin ||
				!rate40hcWithinMargin ||
				!rate45WithinMargin
			) {
				return false
			}
		}

		return true
	}

	function handleInput(event: any, clientIndex: number, rateIndex: number, containerSize: any) {
		const updatedClients = JSON.parse(JSON.stringify(clients))
		const newValue = event.target.value
		const prevValue = updatedClients[clientIndex].results[rateIndex][containerSize].sellAmount

		// Set new rate value
		const cleanValue = !newValue.match(/^[-]?[0-9]*$/) ? prevValue : newValue
		updatedClients[clientIndex].results[rateIndex][containerSize].sellAmount = +cleanValue

		// Set new rate change value
		const prevRateChange = clientsSnapshot[clientIndex].results[rateIndex][containerSize].rateChange
		const rateChange =
			cleanValue - clientsSnapshot[clientIndex].results[rateIndex][containerSize].sellAmount
		updatedClients[clientIndex].results[rateIndex][containerSize].rateChange =
			prevRateChange + rateChange

		// Set new margin status
		const marginMin = updatedClients[clientIndex].marginMin
		const marginMax = updatedClients[clientIndex].marginMax
		const margin =
			cleanValue - updatedClients[clientIndex].results[rateIndex][containerSize].avgBuyAmountTop3
		updatedClients[clientIndex].results[rateIndex][containerSize].margin = margin

		if (margin >= marginMin && margin <= marginMax) {
			updatedClients[clientIndex].results[rateIndex][containerSize].status = "ok"
		} else if (margin > marginMax) {
			updatedClients[clientIndex].results[rateIndex][containerSize].status = "above"
		} else if (margin < marginMin) {
			updatedClients[clientIndex].results[rateIndex][containerSize].status = "below"
		}

		setClients(updatedClients)
	}

	function handleUndo(clientIndex: number, rateIndex: number) {
		const updatedClients = JSON.parse(JSON.stringify(clients))
		updatedClients[clientIndex].results[rateIndex] = clientsSnapshot[clientIndex].results[rateIndex]

		setClients(updatedClients)
		resetModalState()
	}

	function approveSingle(payload: any) {
		const sendFn = (message) => approveRateChanges([payload], message)
		const cancelFn = resetModalState
		const dialogText = "Are you sure you want to send out client's rate sheet?"
		toggleModal(sendFn, cancelFn, dialogText)
	}

	function approveAllWithinTolerance() {
		const clientsWithinTolerance = []
		clients.forEach((c, i) => {
			if (checkOverallClientMargins(i)) {
				clientsWithinTolerance.push(JSON.parse(JSON.stringify(c)))
			}
		})

		const sendFn = (message) => approveRateChanges(clientsWithinTolerance, message)
		const cancelFn = resetModalState
		const dialogText =
			"Are you sure you want to send out rate sheets for all the clients within margin tolerance?"
		toggleModal(sendFn, cancelFn, dialogText)
	}

	function approveAllSelected(payload: any[]) {
		const sendFn = (message) => approveRateChanges(payload, message)
		const cancelFn = resetModalState
		const dialogText = "Are you sure you want to send out rate sheets for all the selected clients?"
		toggleModal(sendFn, cancelFn, dialogText)
	}

	function buildRateChangesPayload(payload: any) {
		const payloadCopy = JSON.parse(JSON.stringify(payload))

		if (!payloadCopy.length) {
			alert.error("No clients meet the criteria for approval.", { timeout: 10000 })
			return []
		}

		const rateChanges = []
		payloadCopy.forEach((c) => {
			c.results.forEach((r) => {
				rateChanges.push({
					oceanRateId: r.oceanRateId,
					rate20: r.rate20.rateChange,
					rate40: r.rate40.rateChange,
					rate40hc: r.rate40hc.rateChange,
					rate45: r.rate45.rateChange,
				})
			})
		})
		return rateChanges
	}

	async function approveRateChanges(clientsPayload: any[], emailMessage: string) {
		const rateChanges = buildRateChangesPayload(clientsPayload)
		if (!rateChanges.length) return

		trackPromise(
			controlTowerService.approveChanges({ rateChanges: rateChanges }).then(
				(response) => {
					updatePageState(response.clientIds)

					trackPromise(
						rateSheetService.sendRateSheets(response.clientIds, emailMessage).then(
							() => {
								console.log("Rate Sheets Sent")
							},
							() => {
								alert.error("Rate Sheets Failed to Send", { timeout: 10000 })
							},
						),
					)
				},
				() => {
					alert.error("GRI/GRD Failed", { timeout: 10000 })
					resetModalState()
				},
			),
		)
	}

	function updatePageState(approvedClientIds: number[]) {
		// Remove clients that were approved
		const updatedClients = JSON.parse(JSON.stringify(clients))
		approvedClientIds.forEach((approvedClientId) => {
			const matchedIndex = updatedClients.findIndex((c) => c.clientId === approvedClientId)
			if (matchedIndex !== -1) updatedClients.splice(matchedIndex, 1)
		})

		setClients(updatedClients)
		setSelectedClients([])
		setAccordianState({ selectedIndex: -1, expand: false })
		resetModalState()
	}

	const [accordianState, setAccordianState] = useState({ selectedIndex: -1, expand: false })
	function showExpandedView(clientIndex: number) {
		const expand = clientIndex === accordianState.selectedIndex ? !accordianState.expand : true
		setAccordianState({ selectedIndex: clientIndex, expand: expand })
	}

	const [modalState, setModalState] = useState({
		open: false,
		approveCallback: null,
		cancelCallback: null,
		dialogText: "",
	})
	function toggleModal(approveCallback: Function, cancelCallback: Function, dialogText: string) {
		setModalState({
			open: !modalState.open,
			approveCallback: approveCallback,
			cancelCallback: cancelCallback,
			dialogText: dialogText,
		})
	}

	function resetModalState() {
		setModalState({ open: false, approveCallback: null, cancelCallback: null, dialogText: "" })
	}

	const [selectedClients, setSelectedClients] = useState([])
	function handleClientSelection(client: any) {
		if (clientSelected(client)) {
			const matchedIndex = selectedClients.findIndex((e) => e.clientName === client.clientName)
			const updatedSelectedClients = JSON.parse(JSON.stringify([...selectedClients]))
			updatedSelectedClients.splice(matchedIndex, 1)
			setSelectedClients(updatedSelectedClients)
		} else {
			const selectedClient = JSON.parse(JSON.stringify(client))
			const updatedSelectedClients = [...selectedClients, selectedClient]
			setSelectedClients(updatedSelectedClients)
		}
	}

	function clientSelected(client: any): boolean {
		const matchedIndex = selectedClients.findIndex((e) => e.clientName === client.clientName)
		return matchedIndex !== -1
	}

	const marginAnalysisContext = useContext(MarginAnalysisContext)

	async function moreDetails(clientIndex: number, rateIndex: number) {
		const params = {
			filters: [
				{
					field: "pol",
					value: clients[clientIndex].results[rateIndex].portOfLoad.value,
					applied: true,
				},
				{
					field: "destination",
					value: clients[clientIndex].results[rateIndex].destinationCy.value,
					applied: true,
				},
				{
					field: "pod",
					value: clients[clientIndex].results[rateIndex].portOfDischarge.value,
					applied: true,
				},
				{ field: "agent", value: clients[clientIndex].results[rateIndex].agent, applied: true },
			],
		}
		const buyRates = (await trackPromise(controlTowerService.getBuyRates(params))) as any[]

		if (buyRates.length) {
			marginAnalysisContext.openMarginAnalysisView(
				clients,
				clientIndex,
				rateIndex,
				buyRates,
				handleRateUpdatesFromMarginAnalysisView,
			)
		} else {
			alert.error("No buy rates found for lane", { timeout: 10000 })
		}
	}

	const handleRateUpdatesFromMarginAnalysisView = (rate, clientIndex, rateIndex) => {
		const updatedClients = JSON.parse(JSON.stringify(clients))
		updatedClients[clientIndex].results[rateIndex] = rate
		setClients(updatedClients)
	}

	return (
		<Fragment>
			<div className="container">
				<MarginAnalysisPopout />
				<LoadingIndicator />
				<div id="accordion">
					{loaded ? (
						<div className="card">
							{clients.map((row: any, index: number) => {
								return (
									<Fragment key={row.clientName}>
										{row.clientName ? (
											<div className="container">
												<div className="client-row row">
													<div className="client-header col-md-2">
														<input
															className={"client-checkbox"}
															type="checkbox"
															checked={clientSelected(row)}
															onChange={() => handleClientSelection(row)}
														></input>
														<button
															className="btn"
															type="button"
															onClick={() => showExpandedView(index)}
														>
															<i
																className={
																	accordianState.selectedIndex === index && accordianState.expand
																		? "collIcon fa fa-chevron-up"
																		: "collIcon fa fa-chevron-down"
																}
																aria-hidden="true"
															></i>
														</button>
													</div>
													<div className="textPos col-md-4">
														<h5>{row.clientName}</h5>
													</div>
													<div className="col-md-2">
														<i
															className={
																!checkOverallClientMargins(index)
																	? "marginIcon fa fa-times fa-lg"
																	: "marginIcon fa fa-check fa-lg"
															}
															aria-hidden="true"
															style={{
																color: checkOverallClientMargins(index) ? "green" : "red",
															}}
														></i>
													</div>
													<div className="send-btn-col no-margin col-md-4">
														<button
															className="sendSingle btn btn-primary pull-right col-11"
															onClick={() => approveSingle(row)}
															type="button"
														>
															Send Rate Sheet
														</button>
													</div>
												</div>
											</div>
										) : null}
										{accordianState.selectedIndex === index && accordianState.expand ? (
											<table id="rate-table" className="table table-bordered table-sm">
												<thead className="thead-light">
													<tr>
														{headers.map((key: string) => (
															<th key={key} scope="col" className="text-center">
																{key}
															</th>
														))}
													</tr>
												</thead>
												<tbody>
													{row.results.map((value: any, resultsIndex: number) => (
														<tr key={resultsIndex}>
															<td>
																<button
																	className="marginBtn btn"
																	onDoubleClick={() => moreDetails(index, resultsIndex)}
																>
																	<span className="btnText">{value.portOfLoad.name}</span>
																</button>
															</td>
															<td>
																<button
																	className="marginBtn btn"
																	onDoubleClick={() => moreDetails(index, resultsIndex)}
																>
																	<span className="btnText">{value.portOfDischarge.name}</span>
																</button>
															</td>
															<td>
																<button
																	className="marginBtn btn"
																	onDoubleClick={() => moreDetails(index, resultsIndex)}
																>
																	<span className="btnText">{value.destinationCy.name}</span>
																</button>
															</td>
															<td>
																<div className="input-group input-group-sm">
																	<div className="input-group-prepend">
																		<div className="input-group-text">$</div>
																		<input
																			style={{
																				borderColor: isRateWithinMargin(index, value, "rate20")
																					? "green"
																					: "red",
																			}}
																			className="containerCell form-control"
																			name={"rate20"}
																			onChange={(event) =>
																				handleInput(event, index, resultsIndex, "rate20")
																			}
																			placeholder={"rate20"}
																			value={value["rate20"].sellAmount}
																		></input>
																	</div>
																</div>
															</td>
															<td>
																<div className="input-group input-group-sm">
																	<div className="input-group-prepend">
																		<div className="input-group-text">$</div>
																		<input
																			style={{
																				borderColor: isRateWithinMargin(index, value, "rate40")
																					? "green"
																					: "red",
																			}}
																			className="containerCell form-control"
																			name={"rate40"}
																			onChange={(event) =>
																				handleInput(event, index, resultsIndex, "rate40")
																			}
																			placeholder={"rate40"}
																			value={value["rate40"].sellAmount}
																		></input>
																	</div>
																</div>
															</td>
															<td>
																<div className="input-group input-group-sm">
																	<div className="input-group-prepend">
																		<div className="input-group-text">$</div>
																		<input
																			style={{
																				borderColor: isRateWithinMargin(index, value, "rate40hc")
																					? "green"
																					: "red",
																			}}
																			className="containerCell form-control"
																			name={"rate40hc"}
																			onChange={(event) =>
																				handleInput(event, index, resultsIndex, "rate40hc")
																			}
																			placeholder={"rate40hc"}
																			value={value["rate40hc"].sellAmount}
																		></input>
																	</div>
																</div>
															</td>
															<td>
																<div className="input-group input-group-sm">
																	<div className="input-group-prepend">
																		<div className="input-group-text">$</div>
																		<input
																			style={{
																				borderColor: isRateWithinMargin(index, value, "rate45")
																					? "green"
																					: "red",
																			}}
																			className="containerCell form-control"
																			name={"rate45"}
																			onChange={(event) =>
																				handleInput(event, index, resultsIndex, "rate45")
																			}
																			placeholder={"rate45"}
																			value={value["rate45"].sellAmount}
																		></input>
																	</div>
																</div>
															</td>
															<td>
																<button
																	className="undoBtn btn"
																	onClick={() => handleUndo(index, resultsIndex)}
																	type="button"
																>
																	<i className="fa fa-undo" aria-hidden="true"></i>
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										) : null}
									</Fragment>
								)
							})}
						</div>
					) : null}
				</div>
				<div className="container">
					<div className="allRow row">
						<div className="col-md-8"></div>
						<div className="send-btn-col col-md-4">
							<button
								disabled={!selectedClients.length}
								className="btn btn-success col-12"
								onClick={() => approveAllSelected(selectedClients)}
								type="button"
							>
								Send All Selected
							</button>
							<button
								className="btn btn-success col-12"
								onClick={() => approveAllWithinTolerance()}
								type="button"
							>
								Send All Within Tolerance
							</button>
						</div>
					</div>
				</div>
				{modalState.open ? (
					<EmailSendModal
						dialogText={modalState.dialogText}
						sendFn={modalState.approveCallback}
						cancelFn={modalState.cancelCallback}
					></EmailSendModal>
				) : null}
			</div>
		</Fragment>
	)
}

function EmailSendModal({ dialogText, sendFn, cancelFn }) {
	return (
		<Modal dismiss={cancelFn}>
			<p>{dialogText}</p>
			<EmailSendDialog send={sendFn} cancel={cancelFn}></EmailSendDialog>
		</Modal>
	)
}

function EmailSendDialog({ cancel, send }) {
	const [message, setMessage] = useState("Please review the rate sheet attached to this email.")
	const messageInput = useRef(null)

	useEffect(() => {
		messageInput.current.focus()
		messageInput.current.select()
	}, [])

	function handleInput(event: any) {
		setMessage(event.target.value)
	}

	return (
		<Fragment>
			<div className="dialog-action-area">
				<label>Email Message</label>
				<textarea value={message} ref={messageInput} onChange={handleInput}></textarea>
				<div className={"dialog-btns"}>
					<button type="button" className="btn btn-primary" onClick={() => send(message)}>
						Send
					</button>
					<button type="button" className="btn btn-secondary" onClick={() => cancel()}>
						Cancel
					</button>
				</div>
			</div>
		</Fragment>
	)
}

export default ReviewPage
