import {
	Fragment,
	FC,
	useState,
	useEffect,
	useRef,
	ChangeEvent,
	FormEvent,
	KeyboardEvent,
	MouseEvent,
	SetStateAction,
} from "react"
import "../AdminConsole.scss"
import useAdminService from "Services/AdminService"
import uniqid from "uniqid"
import { TableDataType } from "../../../Types/PortGroupTypes"

interface IProps {
	show: (newShow: SetStateAction<boolean>) => void
	disable: (newDisable: SetStateAction<boolean>) => void
	row: TableDataType
	addFn: (addGroup: TableDataType) => void
	updateFn: (updateGroup: TableDataType) => void
	content: TableDataType[]
}

interface DropdownStateType {
	locodes: TableDataType[]
	cursor: number
	showOptions: boolean
}

const PortGroupForm: FC<IProps> = ({ show, disable, row, addFn, updateFn, content }) => {
	const mainRef = useRef(null)
	const id = useRef(uniqid())
	const optionsRef = useRef(null)

	const [group, setGroup] = useState<TableDataType>({
		id: undefined,
		name: "",
		value: [],
	})

	const [dropdownState, setDropdownState] = useState<DropdownStateType>({
		locodes: [],
		cursor: 0,
		showOptions: false,
	})

	const [port, setPort] = useState<TableDataType>(null)
	const [lookupString, setLookupString] = useState<string>("")
	const [errors, setErrors] = useState<string>("")
	const isEnabled = group.value.length > 0 && group.name

	const adminService = useAdminService()

	useEffect(() => {
		if (row) {
			let temp = { ...group }
			temp = {
				id: row.id,
				name: row.name,
				value: row.value,
			}
			setGroup(temp)
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (port) {
			setLookupString(`${port.name} (${port.value.join(", ")})`)
			setDropdownState({
				locodes: [],
				cursor: 0,
				showOptions: true,
			})
		} else {
			setLookupString("")
		}
	}, [port])

	const validateName = (): boolean => {
		const foundName = content.find((td) => td.name === group.name)
		return foundName ? false : true
	}

	const validatePorts = (): boolean => {
		const foundPortsSameLength = content.filter((td) => td.value.length === group.value.length)
		if (foundPortsSameLength.length === 0) {
			return true
		}
		for (const foundPort of foundPortsSameLength) {
			const checkPorts = foundPort.value.every((m) => group.value.includes(m))
			if (checkPorts) {
				return false
			}
		}
		return true
	}

	function inputHandler(event: ChangeEvent<HTMLInputElement>): void {
		const newVal = { ...group, [event.target.name]: event.target.value }
		setGroup(newVal)
	}

	const inputTimeout = useRef(null)
	function portHandler(e: ChangeEvent<HTMLInputElement>): void {
		const inputValue = e.target.value
		setLookupString(inputValue)

		clearTimeout(inputTimeout.current)
		inputTimeout.current = setTimeout(() => {
			if (inputValue === "") {
				setDropdownState({
					locodes: [],
					cursor: 0,
					showOptions: true,
				})
			} else {
				if (inputValue.length > 3) lookupLocodes(inputValue)
			}
		}, 1000)
	}

	function lookupLocodes(lookupString: string): void {
		adminService.locodeSearch(lookupString).then((locodes: TableDataType[]) => {
			const newLocodes = locodes.filter((locode) => {
				return !group.value.includes(locode.value[0])
			})
			setDropdownState({
				locodes: newLocodes,
				cursor: 0,
				showOptions: true,
			})
		})
	}

	function addToList(e: MouseEvent<HTMLButtonElement>): void {
		e.preventDefault()
		if (!port) return

		const temp = { ...group }
		temp.value = [...temp.value, ...port.value]
		setGroup(temp)
		setLookupString("")
	}

	function remove(i: number): void {
		let temp = [...group.value]
		temp = temp.filter((_, index) => index !== i)
		setGroup({ ...group, value: temp })
	}

	function saveGroup(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault()
		const errorMessage: string[] = []
		const isNameValid = validateName()
		const arePortsValid = validatePorts()
		if (!isNameValid) {
			errorMessage.push("There is another port group with that name.")
		}
		if (!arePortsValid) {
			errorMessage.push("There is another port group with the same ports.")
		}
		if (errorMessage.length) {
			setErrors(errorMessage.join("  "))
		} else {
			if (!group.id) {
				addFn(group)
			} else {
				updateFn(group)
			}
			resetFields()
		}
	}

	function resetFields(): void {
		setGroup({ id: undefined, name: "", value: [] })
		show((s) => !s)
		disable((d) => !d)
		setErrors("")
	}

	function select(option: TableDataType): void {
		setPort(option)
		setDropdownState({
			locodes: [],
			cursor: 0,
			showOptions: false,
		})
	}

	function onBlur(): void {
		setDropdownState({
			locodes: [],
			cursor: 0,
			showOptions: false,
		})
	}

	const liRefMap = {}
	function handleKeyPress(e: KeyboardEvent<HTMLInputElement>): void {
		if (!dropdownState.showOptions) return

		if (e.key === "ArrowUp") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			let updatedCursor = dropdownState.cursor - 1
			if (updatedCursor > dropdownState.locodes.length - 1) updatedCursor = 0
			let scrollTo = 0
			for (let i = 0; i < updatedCursor; i++) {
				scrollTo += liRefMap[dropdownState.locodes[i].id].offsetHeight
			}
			optionsRef.current.scrollTo(0, scrollTo)
			setDropdownState({ ...dropdownState, cursor: updatedCursor })
		}

		if (e.key === "ArrowDown") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			let updatedCursor = dropdownState.cursor + 1
			if (updatedCursor > dropdownState.locodes.length - 1) updatedCursor = 0
			let scrollTo = 0
			for (let i = 0; i < updatedCursor; i++) {
				scrollTo += liRefMap[dropdownState.locodes[i].id].offsetHeight
			}
			optionsRef.current.scrollTo(0, scrollTo)
			setDropdownState({ ...dropdownState, cursor: updatedCursor })
		}

		if (e.key === "Enter") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			const value = dropdownState.locodes[dropdownState.cursor]
			if (value) select(dropdownState.locodes[dropdownState.cursor])
		}

		if (e.key === "Escape") {
			setDropdownState({
				locodes: [],
				cursor: 0,
				showOptions: false,
			})
		}
	}

	return (
		<Fragment>
			<div className="container port-container">
				<h3>Port Group</h3>
				<form onSubmit={saveGroup}>
					<div className="form-row">
						<div className="form-group col-md-4 header-group">
							<label>Name *</label>
							<input
								placeholder="Group Name"
								autoFocus
								className="form-control"
								type="text"
								name="name"
								value={group.name}
								onChange={inputHandler}
							/>
						</div>
						<div className="form-group col-md-4 header-group">
							<label>Port *</label>
							<div className="input-group">
								<input
									ref={mainRef}
									id={id.current}
									type="text"
									name="port"
									className="form-control"
									placeholder="Port Search"
									onBlur={() => onBlur()}
									value={lookupString}
									onChange={portHandler}
									autoComplete="off"
									autoCorrect="off"
									autoCapitalize="off"
									spellCheck={false}
									onKeyDown={handleKeyPress}
								/>
								<div className="input-group-append">
									<button className="input-group-text" onClick={addToList}>
										<i className="fa fa-plus"></i>
									</button>
								</div>
							</div>
							{
								<div className="port-search-wrapper">
									{dropdownState.locodes.length > 0 && (
										<ul ref={optionsRef}>
											{dropdownState.locodes.map((o, i) => (
												<li
													ref={(el) => (liRefMap[o.id] = el)}
													key={o.id}
													className={dropdownState.cursor === i ? "highlighted" : ""}
													onMouseDown={() => select(o)}
												>{`${o.name} (${o.value.join(", ")})`}</li>
											))}
										</ul>
									)}
								</div>
							}
						</div>
					</div>
					<div hidden={!group.value.length} className="port-group">
						{group.value.length
							? group.value.map((p, index) => (
									<span key={index}>
										<label>{p}</label>
										<button type="button">
											<i className="fa fa-times" onClick={() => remove(index)}></i>
										</button>
									</span>
							  ))
							: null}
					</div>
					<p hidden={errors === ""} className="errors">
						{errors}
					</p>
					<div className="addUpdate">
						<button type="submit" className="btn btn-primary" disabled={!isEnabled}>
							Save
						</button>
						<button type="button" className="btn btn-secondary" onClick={resetFields}>
							Cancel
						</button>
					</div>
				</form>
			</div>
		</Fragment>
	)
}
export default PortGroupForm
