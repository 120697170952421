import React, { useEffect, useState } from "react"
import { formatDateString } from "Utilities/DateUtils"

interface IProps {
	rates: any[]
	copyHandler?: any
	editHandler?: any
	expireHandler?: any
	readonly: boolean
	selectHandler?: any
}

function DrayRateTable(props: IProps) {
	const [rateCheckboxes, setRateCheckboxes] = useState<{ id: number; selected: boolean }[]>([])
	const [allSelected, setAllSelected] = useState<boolean>(false)
	const [reorderedRates, setReorderedRates] = useState([])

	useEffect(() => {
		moveExpiredRatesToBottom()

		const tempCheckboxes = []
		for (let i = 0; i < props.rates.length; i++) {
			if (props.rates[i].expired) continue // only effective rates have checkboxes
			const rateCheckbox = rateCheckboxes.find((c) => c.id == props.rates[i].id)
			tempCheckboxes.push({
				id: props.rates[i].id,
				selected: rateCheckbox?.selected || false,
			})
		}
		setRateCheckboxes(tempCheckboxes)
	}, [props.rates])

	useEffect(() => {
		if (props.rates.length === 0) return
		const selectedRateIDs = rateCheckboxes.filter((c) => c.selected).map((c) => c.id)
		setAllSelected(selectedRateIDs.length === rateCheckboxes.length)
		props.selectHandler(selectedRateIDs)
	}, [rateCheckboxes])

	function moveExpiredRatesToBottom() {
		const expiredRates = []
		const reorderedTemp = []
		props.rates.forEach((r) => {
			r.expired ? expiredRates.push(r) : reorderedTemp.push(r)
		})
		expiredRates.forEach((row) => {
			reorderedTemp.push(row)
		})
		setReorderedRates([...reorderedTemp])
	}

	function handleCheckboxChange(event, rateId: number) {
		const tempCheckboxes = [...rateCheckboxes]
		const rateCheckbox = tempCheckboxes.find((s) => s.id == rateId)
		rateCheckbox.selected = event.target.checked
		setRateCheckboxes(tempCheckboxes)
	}

	function handleSelectAllChange(event) {
		const tempCheckboxes = []
		for (let i = 0; i < props.rates.length; i++) {
			tempCheckboxes.push({
				id: props.rates[i].id,
				selected: event.target.checked,
			})
		}
		setRateCheckboxes(tempCheckboxes)
	}

	return (
		<table className={"table table-bordered"}>
			<thead className="thead-light">
				<tr className="text-center">
					<th>
						<input
							type="checkbox"
							checked={allSelected}
							onChange={(event) => handleSelectAllChange(event)}
						></input>
					</th>
					<th scope="col">Destination CY</th>
					<th scope="col">Destination Door</th>
					<th scope="col">Base Rate</th>
					<th scope="col">Fuel</th>
					<th scope="col">Total</th>
					<th scope="col">Eff. Date</th>
					<th scope="col">Exp. Date</th>
					{!props.readonly ? <th scope="col">Actions</th> : null}
				</tr>
			</thead>
			<tbody className="text-center">
				{reorderedRates.length
					? reorderedRates.map((r) => {
							return (
								<tr
									key={r.id}
									className={`${r.expired ? "expired" : null} ${
										r.expiringSoon ? "expiring" : null
									}`}
								>
									<td>
										{!r.expired && (
											<input
												type="checkbox"
												checked={rateCheckboxes.find((c) => c.id == r.id)?.selected || false}
												onChange={(event) => handleCheckboxChange(event, r.id)}
											></input>
										)}
									</td>
									<td>{r.destinationCy.name}</td>
									<td>{r.destinationDoor}</td>
									<td>${r.baseRate}</td>
									<td>{r.fuelSurcharge}%</td>
									<td>${r.total}</td>
									<td>{formatDateString(r.effectiveDate)}</td>
									<td>{formatDateString(r.expirationDate)}</td>
									{!props.readonly ? (
										<td>
											<div className={"table-row-action-buttons"}>
												<button className={"btn btn-link"} onClick={() => props.copyHandler(r)}>
													Copy
												</button>
												{r && !r.expired && (
													<button className={"btn btn-link"} onClick={() => props.editHandler(r)}>
														Edit
													</button>
												)}
												<button
													className={"btn btn-link"}
													onClick={() => props.expireHandler(r.id)}
												>
													Remove
												</button>
											</div>
										</td>
									) : null}
								</tr>
							)
					  })
					: null}
			</tbody>
		</table>
	)
}

export default DrayRateTable
