import React from "react"
import ReactDOM from "react-dom"
import "./Modal.scss"

/**
 * MODAL USAGE: Insert your components / content between <Modal></Modal> jsx tags. To
 * enable dismissal on click outside pass in the appropriate function to call as dismiss prop in order to
 * dismiss the modal.
 */

interface IProps {
	dismiss?: () => void
}

const portalRoot = document.getElementById("modal-root")

class Modal extends React.Component<IProps> {
	modal = undefined
	modalContent = undefined

	constructor(props) {
		super(props)
		this.modal = document.createElement("div")
		this.modal.classList.add("custom-modal")
		this.modal.classList.add("modal")
		this.modalContent = React.createRef()
	}

	componentDidMount() {
		document.body.style.overflow = "hidden"
		document.addEventListener("mousedown", this.handleClick)
		portalRoot.appendChild(this.modal)
	}

	componentWillUnmount() {
		portalRoot.removeChild(this.modal)
		document.body.style.overflow = "unset"
		document.removeEventListener("mousedown", this.handleClick)
	}

	private handleClick = (e) => {
		if (this.modalContent.current.contains(e.target)) {
			return
		}
		if (this.props.dismiss) this.props.dismiss()
	}

	render() {
		return ReactDOM.createPortal(
			<div className={"modal-content"} ref={this.modalContent}>
				{this.props.children}
			</div>,
			this.modal,
		)
	}
}

export default Modal
