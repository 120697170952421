import React, { useState, useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { RiSearchEyeLine } from "react-icons/ri"

import { useDynamicSearchService } from "./DynamicSearchService"
import FiltersetLookup from "../Filters/FiltersetBuilder/FiltersetLookup"
import FilterLookup from "../Filters/FiltersetBuilder/FilterLookup"
import TableView from "./DynamicSeachTableView"
import { Filterable } from "."
import { formatFilterDateString } from "Utilities/DateUtils"

export interface FilterSet {
	name: string
	filters: Filterable[]
}

interface onApplyType {
	filters: Filterable[]
}

interface PropsTypes {
	dataset: any
	onApply: (filters: onApplyType) => void
	disableApply?: (filters: Filterable[]) => boolean
}

function AdvancedSearch({ dataset, onApply, disableApply }: PropsTypes) {
	const searchService = useDynamicSearchService(dataset?.id)
	const [filterSets, setFilterSets] = useState<FilterSet[]>([])
	const [activeFilterSet, setActiveFilterSet] = useState<FilterSet>(null)
	const [unusedFilterables, setUnusedFilterables] = useState([])

	useEffect(() => {
		if (!dataset) return
		const { defaultFilterSet, filterSets } = searchService.getDefaults()
		setActiveFilterSet(defaultFilterSet)
		setFilterSets(filterSets)
	}, [dataset])

	useEffect(() => {
		if (dataset) searchService.updateLocalStorage(filterSets)
	}, [filterSets])

	useEffect(() => {
		if (!dataset?.filterables || !activeFilterSet?.filters) return
		const tempUnused = []
		for (const f of dataset.filterables) {
			const filter = activeFilterSet.filters.find((v) => v.field === f.field)
			if (!filter) {
				if (f.required) {
					activeFilterSet.filters.push({
						field: f.field,
						label: f.label,
						type: f.type,
						value: null,
						applied: true,
					})
				} else {
					tempUnused.push(f)
				}
			}
		}
		setUnusedFilterables(tempUnused)
	}, [activeFilterSet])

	function outputCurrentFilters() {
		const filters = [...activeFilterSet.filters].map((f) => {
			const fCopy = JSON.parse(JSON.stringify(f))
			if (fCopy.type === "date") {
				fCopy.value.fromDate = fCopy.value.fromDate
					? formatFilterDateString(fCopy.value.fromDate)
					: null
				fCopy.value.toDate = fCopy.value.toDate ? formatFilterDateString(fCopy.value.toDate) : null
			}
			return fCopy
		})
		onApply({ filters })
		toggle()
	}

	function addSearchFilter(filter: Filterable) {
		const updatedSearchFilters = searchService.addSearchFilter(filter, activeFilterSet)
		setActiveFilterSet(updatedSearchFilters)
	}

	function removeSearchFilter(filter: Filterable) {
		const updatedSearchFilters = searchService.removeSearchFilter(filter, activeFilterSet)
		setActiveFilterSet(updatedSearchFilters)
	}

	function updateSearchFilter(filter: Filterable) {
		const updatedSearchFilters = searchService.updateSearchFilter(filter, activeFilterSet)
		let filterSetsTemp = [...filterSets]
		filterSetsTemp = filterSetsTemp.filter((f) => f.name !== activeFilterSet.name)
		filterSetsTemp.push(updatedSearchFilters)
		setFilterSets(filterSetsTemp)
		setActiveFilterSet(updatedSearchFilters)
	}

	function newFilterSet(filterSetName: string) {
		const [filterSet, updatedFilterSets] = searchService.newFilterSet(filterSetName, filterSets)

		setFilterSets(updatedFilterSets)
		setActiveFilterSet(filterSet)
	}

	function removeFilterSet(filterSetName: string) {
		const updatedFilterSets = searchService.removeFilterSet(filterSetName, filterSets)

		if (filterSetName === activeFilterSet.name) {
			let newActiveFilterSet
			if (updatedFilterSets.length) {
				newActiveFilterSet = { ...updatedFilterSets[0] }
				setActiveFilterSet(newActiveFilterSet)
			} else {
				const defaults = searchService.getDefaults()
				setActiveFilterSet(defaults.defaultFilterSet)
			}
		}
		setFilterSets(updatedFilterSets)
	}

	function selectFilterSet(filterSetName: string) {
		if (filterSetName === activeFilterSet.name) {
			const defaults = searchService.getDefaults()
			setActiveFilterSet(defaults.defaultFilterSet)
			return
		}
		const selectedFilterSet = filterSets.find((f) => f.name === filterSetName)
		setActiveFilterSet(selectedFilterSet)
	}

	const [modal, setModal] = useState<boolean>(false)
	const toggle = () => setModal((prevState) => !prevState)

	const setDisable = () => {
		if (disableApply) {
			if (activeFilterSet === null) {
				return true
			} else {
				return disableApply(activeFilterSet.filters)
			}
		} else {
			return false
		}
	}

	return (
		<>
			<Button color="primary" onClick={toggle}>
				Search Rates <RiSearchEyeLine />
			</Button>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>
					Search<br></br>
					Filterset: {activeFilterSet && activeFilterSet.name}
				</ModalHeader>
				<ModalBody className="pt-0 pb-0">
					{activeFilterSet && (
						<TableView
							filterSet={activeFilterSet}
							updateFilter={updateSearchFilter}
							removeFilter={removeSearchFilter}
						></TableView>
					)}
				</ModalBody>
				<ModalFooter>
					{activeFilterSet && (
						<FilterLookup filters={unusedFilterables} addFilter={addSearchFilter} />
					)}
					<FiltersetLookup
						filterSets={filterSets}
						newFilterSet={newFilterSet}
						selectFilterSet={selectFilterSet}
						removeFilterSet={removeFilterSet}
					/>
					<Button color="primary" onClick={outputCurrentFilters} disabled={setDisable()}>
						Apply Filters
					</Button>{" "}
				</ModalFooter>
			</Modal>
		</>
	)
}

export default AdvancedSearch
