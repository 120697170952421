import { stringifyDate } from "Utilities/DateUtils"
import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"

export const useSellRateService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		getClients: function () {
			return apiContext.get("client/", source.token).then((clients) => {
				let clientOptions = []

				if (clients) {
					if (clients.length) {
						clientOptions = clients.map((c: any) => {
							return { type: c.name, value: c }
						})
					}
				}
				return clientOptions
			})
		},
		getClientRates: function (filters: any): Promise<any> {
			return apiContext.post(`client-rate/`, filters, source.token)
		},
		getClientRatesDataset: function (): Promise<any> {
			return apiContext.get(`client-rate/dataset`, source.token)
		},
		getOceanRate: function (rateId: string): Promise<any> {
			return apiContext.get(`ocean-rate/search/${rateId}`, source.token)
		},
		saveRate: function (type: "ocean" | "dray", rate: any): Promise<any> {
			if (type === "ocean") {
				return apiContext.post("ocean-rate/", rate, source.token)
			} else {
				return apiContext.post("dray-rate/", rate, source.token)
			}
		},
		updateRate: function (type: "ocean" | "dray", rate: any, rateId: number): Promise<any> {
			if (type === "ocean") {
				return apiContext.post(`ocean-rate/${rateId}`, rate, source.token)
			} else {
				return apiContext.post(`dray-rate/${rateId}`, rate, source.token)
			}
		},
		deleteRate: function (type: "ocean" | "dray", rateId: number): Promise<any> {
			if (type === "ocean") {
				return apiContext.del(`ocean-rate/${rateId}`, source.token)
			} else {
				return apiContext.del(`dray-rate/${rateId}`, source.token)
			}
		},
		getBuyRates: function (params: any): Promise<any> {
			return apiContext.post("buy-rate/", params, source.token)
		},
		getMarginAnalysis: function (rate: any): Promise<any> {
			return apiContext.post(`ocean-rate/margin-analysis/`, rate, source.token)
		},
		getRateSheetSnapshot: function (clientId: number, date: Date): Promise<any> {
			const filterDate = stringifyDate(date, "ISO")
			return apiContext.get(
				`rate-sheet/snapshot/${clientId}?filterDate=${filterDate}`,
				source.token,
			)
		},
		getRateDropdowns: function (): Promise<any> {
			return apiContext.get("dropdown/sell-rate", source.token)
		},
		getClientDetails: function (id: number): Promise<any> {
			return apiContext.get(`client/${id}`, source.token)
		},
		updateClientProfile: function (id: number, client: any): Promise<any> {
			return apiContext.post(`client/${id}`, client, source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
		acceptRate: function (rate: any, rateId: number): Promise<any> {
			return apiContext.post(`ocean-rate/${rateId}/accept`, rate, source.token)
		},
		getPastOceanRateHistory: (id: number): Promise<any> => {
			return apiContext.get(`ocean-rate/${id}/history`, source.token)
		},
	}

	return API
}

export default useSellRateService
