import React, { useRef, useState } from "react"
import uniqid from "uniqid"

import "./LocodeSelect.scss"
import useLocodeService from "Services/LocodeService"

export function LocodeSelect(props) {
	const id = useRef(uniqid())
	const mainRef = useRef(null)
	const optionsRef = useRef(null)
	const locodeService = useLocodeService()
	const [inputValue, setInputValue] = useState("")
	const [dropdownState, setDropdownState] = useState({
		locodes: [],
		cursor: 0,
		showOptions: false,
	})
	const inputTimeout = useRef(null)

	// Set input value based on form field value
	const formFieldValue = props.form.values[props.name]
	let formFieldValueDisplayString = ""
	if (typeof formFieldValue === "string") {
		formFieldValueDisplayString = formFieldValue
		if (inputValue !== formFieldValueDisplayString) handleInput(formFieldValueDisplayString)
	} else {
		formFieldValueDisplayString = formFieldValue
			? `${formFieldValue.name} (${formFieldValue.value.join(", ")})`
			: null
		// Check diff between inputValue and formFieldValueDisplayString before setting to avoid infinite render loop
		if (formFieldValue && formFieldValueDisplayString && inputValue !== formFieldValueDisplayString)
			setInputValue(formFieldValueDisplayString)
	}

	function handleInput(e: any) {
		// If user modifies input value we clear out the current form field value
		if (formFieldValue) select(null)

		let inputValue = ""
		if (typeof e === "string") {
			inputValue = e
		} else {
			inputValue = e.target.value
		}

		if (inputTimeout.current) clearTimeout(inputTimeout.current)

		if (inputValue !== "") {
			inputTimeout.current = setTimeout(() => {
				lookupLocodes(inputValue)
			}, 500)
		} else {
			// close dropdown if open
			resetDropdown()
		}

		setInputValue(inputValue)
	}

	function lookupLocodes(lookupString: string) {
		const urlSearch = new URLSearchParams({
			filterString: lookupString.toLowerCase(),
		}).toString()
		const locodesUrl = props.from_buy_rates
			? `locodes/from-buy-rates?${urlSearch}`
			: `locodes/?${urlSearch}`
		locodeService.lookupLocodes(locodesUrl).then((locodes) => {
			setDropdownState({
				locodes: locodes,
				cursor: 0,
				showOptions: true,
			})
		})
	}

	function select(option) {
		// Propagate new value to the form
		props.form.setFieldValue(props.name, option, true)
		resetDropdown()
	}

	function onBlur() {
		// If formFieldValue is empty we do not want to save currently inputted text
		if (!formFieldValue) setInputValue("")
		resetDropdown()
	}

	function resetDropdown() {
		setDropdownState({
			locodes: [],
			cursor: 0,
			showOptions: false,
		})
	}

	const liRefMap = {}
	function handleKeyPress(e) {
		if (!dropdownState.showOptions || !dropdownState.locodes.length) return

		if (e.key === "ArrowUp") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			let updatedCursor = dropdownState.cursor - 1
			if (updatedCursor > dropdownState.locodes.length - 1) updatedCursor = 0
			let scrollTo = 0
			for (let i = 0; i < updatedCursor; i++) {
				scrollTo += liRefMap[dropdownState.locodes[i].id].offsetHeight
			}
			optionsRef.current.scrollTo(0, scrollTo)
			setDropdownState({ ...dropdownState, cursor: updatedCursor })
		}

		if (e.key === "ArrowDown") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			let updatedCursor = dropdownState.cursor + 1
			if (updatedCursor > dropdownState.locodes.length - 1) updatedCursor = 0
			let scrollTo = 0
			for (let i = 0; i < updatedCursor; i++) {
				scrollTo += liRefMap[dropdownState.locodes[i].id].offsetHeight
			}
			optionsRef.current.scrollTo(0, scrollTo)
			setDropdownState({ ...dropdownState, cursor: updatedCursor })
		}

		if (e.key === "Enter" || e.key === "Tab") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			const value = dropdownState.locodes[dropdownState.cursor]
			if (value) select(dropdownState.locodes[dropdownState.cursor])
		}

		if (e.key === "Escape") {
			setDropdownState({
				locodes: [],
				cursor: 0,
				showOptions: false,
			})
		}
	}

	return (
		<div className={"port-select-wrapper"}>
			{
				<label
					htmlFor={id.current}
					className={`${
						props.form.touched[props.name] && props.form.errors[props.name] ? "has-error" : null
					}`}
				>
					{props.label}
				</label>
			}
			<input
				ref={mainRef}
				className={`form-control ${
					props.form.touched[props.name] && props.form.errors[props.name] ? "has-error" : null
				}`}
				id={id.current}
				type={"text"}
				onChange={handleInput}
				value={inputValue}
				onBlur={() => onBlur()}
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				spellCheck={false}
				onKeyDown={handleKeyPress}
			/>
			{dropdownState.locodes.length > 0 && (
				<ul ref={optionsRef}>
					{dropdownState.locodes.map((o, i) => (
						<li
							ref={(el) => (liRefMap[o.id] = el)}
							key={o.id}
							className={dropdownState.cursor === i ? "highlighted" : ""}
							onMouseDown={() => select(o)}
						>{`${o.name} (${o.value.join(", ")})`}</li>
					))}
				</ul>
			)}
		</div>
	)
}

export default LocodeSelect
