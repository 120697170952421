import React, { useState, Fragment, useRef, useEffect } from "react"
import "./RateSheetMessage.scss"

export interface IProps {
	placeholder: string
	dismiss: Function
	send: Function
}

const RateSheetMessage: React.FC<IProps> = ({ placeholder, dismiss, send }) => {
	const [message, setMessage] = useState("Please review the rate sheet attached to this email.")
	const messageInput = useRef(null)

	useEffect(() => {
		messageInput.current.focus()
		messageInput.current.select()
	}, [])

	function handleInput(event: any) {
		setMessage(event.target.value)
	}

	return (
		<Fragment>
			<div className="wrapper">
				<label htmlFor={"message"}>Email Message</label>
				<textarea
					id={"message"}
					ref={messageInput}
					placeholder={placeholder}
					value={message}
					onChange={handleInput}
				></textarea>
				<span>
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => {
							send(message)
						}}
					>
						SEND
					</button>
					<button type="button" className="btn btn-secondary" onClick={() => dismiss()}>
						CANCEL
					</button>
				</span>
			</div>
		</Fragment>
	)
}

export default RateSheetMessage
