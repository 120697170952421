import React, { useState, useEffect, useRef } from "react"
import ApiContext from "Contexts/API/Context"
import axios, { AxiosInstance } from "axios"
import { useAlert } from "react-alert"

export function ApiContextProvider({ children }) {
	const alert = useAlert()
	const baseConfig = {
		headers: {
			"content-type": "application/json",
		},
		baseURL: process.env.REACT_APP_API_URL,
	}
	const mainInstance = useRef<AxiosInstance>(axios.create(baseConfig))

	const [tokenRevoked, setTokenRevoked] = useState(false)
	const [refreshState, setRefreshState] = useState({
		isRefreshing: false,
		refreshingCall: undefined,
	})
	const [tokens, setTokens] = useState({
		accessToken: JSON.parse(localStorage.getItem("auth"))?.access_token,
		refreshToken: JSON.parse(localStorage.getItem("auth"))?.refresh_token,
	})

	const tokenTimeout = useRef(null)

	function login(username, password) {
		const axiosInstance = axios.create({
			headers: {
				"content-type": "application/json",
			},
			baseURL: process.env.REACT_APP_API_URL,
		})

		return axiosInstance
			.post("auth/login", { username, password })
			.then((response) => {
				setTokenRevoked(false)
				mainInstance.current.defaults.headers.common[
					"Authorization"
				] = `Bearer ${response.data["access_token"]}`
				setTokens({
					accessToken: response.data["access_token"],
					refreshToken: response.data["refresh_token"],
				})
				localStorage.setItem("auth", JSON.stringify(response.data))
				return response.data.login
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function logout() {
		// WILL REFACTOR TO USE AXIOS SOON
		const localReqBuilder = (type: "access" | "refresh") => {
			const url = `${process.env.REACT_APP_API_URL}/auth/${
				type === "access" ? "logout" : "logout2"
			}`
			const initObject = {
				method: "GET",
				headers: {
					"content-type": "application/json",
					Authorization: `Bearer ${type === "access" ? tokens.accessToken : tokens.refreshToken}`,
				},
			}
			return async () =>
				await fetch(url, initObject).then((response) => {
					if (!response.ok) {
						console.error("Error logging out")
					}
					setTokens({ ...tokens, accessToken: undefined })
					localStorage.removeItem("auth")
					return response.ok
				})
		}

		const blacklistAccessToken = localReqBuilder("access")
		const blacklistRefreshToken = localReqBuilder("refresh")

		return blacklistAccessToken().then((success) => {
			if (success) {
				return blacklistRefreshToken()
			}
			return success
		})
	}

	const refreshToken = () => {
		if (refreshState.isRefreshing) {
			return refreshState.refreshingCall
		}
		setRefreshState({ ...refreshState, isRefreshing: true })

		const refreshCall = mainInstance.current
			.post("auth/token/refresh", null, {
				headers: { Authorization: `Bearer ${tokens.refreshToken}` },
			})
			.then((response) => {
				const responseData = responseInterceptor(response)
				setTokens({ ...tokens, accessToken: responseData["access_token"] })
				setRefreshState({ isRefreshing: false, refreshingCall: undefined })

				const oldAuth = JSON.parse(localStorage.getItem("auth"))
				const newAuth = { ...oldAuth, access_token: responseData["access_token"] }
				localStorage.setItem("auth", JSON.stringify(newAuth))
				return Promise.resolve(responseData["access_token"])
			})
			.catch((error) => {
				alert.error("unauthorized", { timeout: 10000 })
				setTokens({ accessToken: undefined, refreshToken: undefined })
				setTokenRevoked(true)
				localStorage.removeItem("auth")
				return Promise.reject(error)
			})
		setRefreshState({ ...refreshState, refreshingCall: refreshCall })
		return refreshCall
	}

	function forgotPassword(username: string) {
		const instance = axios.create({
			headers: { "content-type": "application/json" },
			baseURL: process.env.REACT_APP_API_URL,
		})

		return instance
			.get(`auth/reset/${username}`)
			.then((response) => {
				return responseInterceptor(response)
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function resetPassword(token: string, password: string) {
		const instance = axios.create({
			headers: { "content-type": "application/json" },
			baseURL: process.env.REACT_APP_API_URL,
		})

		return instance
			.post(`auth/reset`, { token, password })
			.then((response) => {
				return responseInterceptor(response)
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function download(url, cancelToken) {
		const instance = axios.create({
			headers: {
				"content-type": "application/json",
				Authorization: `Bearer ${tokens.accessToken}`,
			},
			responseType: "blob",
			timeout: 30000,
			baseURL: process.env.REACT_APP_API_URL,
		})
		return instance
			.get(url, { cancelToken: cancelToken })
			.then((response) => {
				return responseInterceptor(response)
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function downloadWithPayload(url, payload, cancelToken) {
		const instance = axios.create({
			headers: {
				"content-type": "application/json",
				authorization: `Bearer ${tokens.accessToken}`,
			},
			responseType: "blob",
			timeout: 30000,
			baseURL: process.env.REACT_APP_API_URL,
		})
		return instance
			.post(url, payload, { cancelToken: cancelToken })
			.then((response) => {
				return responseInterceptor(response)
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function upload(url, payload, cancelToken) {
		return mainInstance.current
			.post(url, payload, {
				cancelToken: cancelToken,
				headers: { "content-type": "application/octet-stream" },
			})
			.then((response) => {
				return responseInterceptor(response)
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function post(url: string, payload: any, cancelToken) {
		return mainInstance.current
			.post(url, payload, { cancelToken: cancelToken })
			.then((response) => {
				return responseInterceptor(response)
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function get(url: string, cancelToken) {
		return mainInstance.current
			.get(url, { cancelToken: cancelToken })
			.then((response) => {
				return responseInterceptor(response)
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function del(url: string, cancelToken) {
		return mainInstance.current
			.delete(url, { cancelToken: cancelToken })
			.then((response) => {
				return responseInterceptor(response)
			})
			.catch((error) => {
				return errorInterceptor(error)
			})
	}

	function responseInterceptor(response) {
		if (response.data?.message) {
			alert.success(response.data.message, { timeout: 5000 })
		}
		return response.data
	}

	function errorInterceptor(error) {
		const timeout = { timeout: 10000 }
		const originalRequest = error.config
		if (axios.isCancel(error)) {
			// Request was deliberately canceled - DO NOTHING.
			return
		} else if (error?.response?.status === 401 && !originalRequest._isRetry) {
			return refreshToken().then((accessToken) => {
				originalRequest._isRetry = true
				originalRequest.headers["Authorization"] = `Bearer ${accessToken}`
				return mainInstance.current
					.request(originalRequest)
					.then((response) => {
						return responseInterceptor(response)
					})
					.catch((error) => {
						return errorInterceptor(error)
					})
			})
		} else if (error?.response?.status === 401 && originalRequest._isRetry) {
			alert.error("unauthorized", timeout)
			setTokens({ accessToken: undefined, refreshToken: undefined })
			setTokenRevoked(true)
			localStorage.removeItem("auth")
		} else if (error?.response) {
			const message = error.response.data
			alert.error(message, timeout)
		} else if (error?.request) {
			alert.error({ type: "Request Error", details: error.message }, timeout)
		} else {
			alert.error({ type: "An error ocurred", details: error.message }, timeout)
		}
		return Promise.reject(error)
	}

	useEffect(() => {
		return () => {
			clearTimeout(tokenTimeout.current)
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		mainInstance.current.defaults.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`
		clearTimeout(tokenTimeout.current)
		if (tokens.accessToken) {
			tokenTimeout.current = setTimeout(refreshToken, 1000 * 60 * 14)
		}
		// eslint-disable-next-line
	}, [tokens.accessToken])

	return (
		<ApiContext.Provider
			value={{
				tokenRevoked: tokenRevoked,
				login: login,
				logout: logout,
				get: get,
				post: post,
				del: del,
				forgotPassword: forgotPassword,
				resetPassword: resetPassword,
				download: download,
				downloadWithPayload: downloadWithPayload,
				upload: upload,
			}}
		>
			{children}
		</ApiContext.Provider>
	)
}

export default ApiContextProvider
