import "../GRI-GRD/CreateGRI_GRD.scss"
import "../ControlTower.scss"
import React, { Fragment, useState, useEffect } from "react"
import RateTable from "../RateTable/RateTable"
import ClientLookup from "../ClientLookup/ClientLookup"
import ControlDropdowns from "../DropdownsSection/ControlDropdowns"
import PSSLogs from "./PSSLogs"
import DatePicker from "react-date-picker"
import useControlTowerService from "Services/ControlTowerService"
import { trackPromise } from "react-promise-tracker"
import { getMinExpirationDate } from "Utilities/DateUtils"

interface IService {
	routing: string
	rate20: number
	rate40: number
	rate40HC: number
	rate45: number
	[key: string]: any
}
export interface IPSSAmountTable {
	rateChanges: IService[]
}
export interface IInclClientID {
	inclusions: {
		id: string
		rateType: string
		listId: number
	}[]
	[key: string]: any
}
export interface IInclClientName {
	inclusionClients: {
		show: boolean
		client: string
	}[]
	disable: boolean
	[key: string]: any
}

export interface IProps {
	dropdowns: any
}

const TableRows = [
	{ routing: "Amount", rate20: 0, rate40: 0, rate40HC: 0, rate45: 0, listId: Date.now() },
]

const CreatePSS: React.FC<IProps> = ({ dropdowns }) => {
	const [trade, setTrade] = useState<string>("")
	const [customers, setCustomers] = useState<IInclClientID>({
		inclusions: [
			{
				id: "",
				rateType: "Fixed",
				listId: Date.now(),
			},
		],
	})
	const [pssTableRows, setPssTableRows] = useState([])
	const [tradeRows, setTradeRows] = useState([])
	const [inclusionShow, setInclusionShow] = useState<IInclClientName>({
		inclusionClients: [
			{
				show: false,
				client: "",
			},
		],
		disable: false,
	})

	const [effectiveDate, setEffectiveDate] = useState({
		date: new Date(),
	})

	const [expirationDate, setExpirationDate] = useState({
		date: null,
	})

	const [logs, setLogs] = useState([])

	const controlTowerService = useControlTowerService()

	useEffect(() => {
		const tableRows = TableRows.map((item, index) => {
			return { ...item, listId: Date.now() + index }
		})
		const tradeRows = dropdowns.trades.map((item) => {
			return { ...item, listId: item.id }
		})
		setPssTableRows(tableRows)
		setTradeRows(tradeRows)
		getLogs()
		// eslint-disable-next-line
	}, [])

	function getLogs() {
		let temp = [...logs]

		trackPromise(
			controlTowerService.getPSSLogs().then((l: any) => {
				temp = l
				setLogs(temp)
			}),
		)
	}

	function tradeTypeSelected(event: any) {
		setTrade(event.target.value)
	}

	function addRow() {
		setCustomers({
			inclusions: customers.inclusions.concat([{ id: "", rateType: "Fixed", listId: Date.now() }]),
		})
		setInclusionShow({
			inclusionClients: inclusionShow.inclusionClients.concat([{ show: false, client: "" }]),
			disable: false,
		})
	}

	function deleteRow(index: number, type: string) {
		customers.inclusions.splice(index, 1)
		inclusionShow.inclusionClients.splice(index, 1)
		setCustomers({ inclusions: customers.inclusions })
		setInclusionShow({
			inclusionClients: inclusionShow.inclusionClients,
			disable: inclusionShow.disable,
		})
	}

	function setClientName(status: any, type: string) {
		setInclusionShow(status)
	}

	function updateClient(status: any, type: string) {
		setCustomers(status)
	}

	function handleChange(event: any, key: string, index: number) {
		const value = parseInt(event.target.value, 10)
		const cleanValue = isNaN(value) ? "" : value
		const tableRows = [...pssTableRows]
		pssTableRows[index][key] = cleanValue
		setPssTableRows(tableRows)
	}

	function setEffectiveDateFn(d: any) {
		setEffectiveDate({ date: d })
	}

	function setExpirationDateFn(d: any) {
		setExpirationDate({ date: d })
	}

	function apply() {
		const clients = []

		customers.inclusions.forEach((e) => {
			clients.push({
				id: e.id,
				rateType: e.rateType,
			})
		})
		const pss = {
			trade: trade,
			effectiveDate: effectiveDate.date.toLocaleDateString(),
			expirationDate: expirationDate.date ? expirationDate.date.toLocaleDateString() : null,
			clients: clients,
			rate20: pssTableRows[0].rate20,
			rate40: pssTableRows[0].rate40,
			rate40hc: pssTableRows[0].rate40HC,
			rate45: pssTableRows[0].rate45,
		}

		trackPromise(
			controlTowerService.applyPSS(pss).then(() => {
				getLogs()
				reset()
			}),
		)
	}

	function updatePSSFn(id: number, date: any) {
		trackPromise(
			controlTowerService.updatePSS(id, date).then(() => {
				getLogs()
			}),
		)
	}

	function reset() {
		setEffectiveDate({ date: new Date() })
		setExpirationDate({ date: null })
		setCustomers({
			inclusions: [
				{
					id: "",
					rateType: "Fixed",
					listId: Date.now(),
				},
			],
		})
		setInclusionShow({
			inclusionClients: [
				{
					show: false,
					client: "",
				},
			],
			disable: false,
		})
		setPssTableRows([
			{ routing: "Amount", rate20: 0, rate40: 0, rate40HC: 0, rate45: 0, listId: Date.now() },
		])
	}

	return (
		<Fragment>
			<form id="pss-form">
				<div className="form-row justify-start align-end">
					<div className="date-picker-wrapper">
						<label id="effectiveDate">Effective Date* </label>
						<DatePicker
							name="effectiveDate"
							format="MM/dd/yyyy"
							required
							onChange={(value) => {
								setEffectiveDateFn(value)
							}}
							value={effectiveDate.date}
						/>
					</div>
					<div className="date-picker-wrapper">
						<label id="expirationDate">Expiration Date </label>
						<DatePicker
							name="expirationDate"
							format="MM/dd/yyyy"
							onChange={(value) => {
								setExpirationDateFn(value)
							}}
							value={expirationDate.date}
							minDate={getMinExpirationDate(effectiveDate.date)}
						/>
					</div>
				</div>
				<ControlDropdowns
					rateSelected={null}
					rateDropdown={null}
					applyTo={null}
					disabled={false}
					tradeSelected={tradeTypeSelected}
					tradeDropdown={tradeRows}
					trade={trade}
				></ControlDropdowns>
				<div className="form-inline control-label">
					<label id="label-incl">Inclusions</label>
					<button className="btn-add" onClick={addRow} type="button">
						<i className="fa fa-plus" aria-hidden="true"></i>
					</button>
				</div>
				{customers.inclusions.map(({ id, rateType, listId }, index: number) => (
					<div className="form-inline" key={listId}>
						<ClientLookup
							inclClientID={customers}
							exclClientID={null}
							inclClientName={inclusionShow}
							exclClientName={null}
							setClientName={setClientName}
							updateClient={updateClient}
							disable={null}
							index={index}
							type={"inclusion"}
						></ClientLookup>
						<button
							disabled={customers.inclusions.length === 1}
							className="btn-remove"
							onClick={() => deleteRow(index, "inclusion")}
							type="button"
						>
							<i className="fa fa-trash" aria-hidden="true"></i>
						</button>
					</div>
				))}
				<RateTable
					rateTable={pssTableRows}
					bafTable={null}
					updateTable={handleChange}
					base={false}
				></RateTable>
				<button
					disabled={
						!effectiveDate.date || inclusionShow.inclusionClients[0].client === "" || !trade
					}
					className="btn btn-primary"
					onClick={apply}
					type="button"
				>
					Apply
				</button>
			</form>
			<PSSLogs logs={logs} updatePSSFn={updatePSSFn}></PSSLogs>
		</Fragment>
	)
}

export default CreatePSS
