import React, { useState } from "react"
import MarginAnalysisContext from "./Context"

interface SaveFunction {
	(rateUpdateInfo: { rate: any; clientIndex: number; rateIndex: number }): void
}

const initialState = {
	clientIndex: null,
	rateIndex: null,
	rateChanges: null,
	buyRates: null,
	rateChangesSnapshot: null,
	rateChangesBuffer: null,
	marginMin: null,
	marginMax: null,
	marginAnalysisData: {
		algorithm: { rate20: 0, rate40: 0, rate40hc: 0, rate45: 0 },
		avgTop3: { rate20: 0, rate40: 0, rate40hc: 0, rate45: 0 },
		avgTop5: { rate20: 0, rate40: 0, rate40hc: 0, rate45: 0 },
		profitableCarriers: { rate20: 0, rate40: 0, rate40hc: 0, rate45: 0 },
	},
	rate: null,
	rateFormValue: null, // Only used when opening margin analysis on sell rates page
	showMarginAnalysis: false,
	save: null,
}

export function MarginAnalysisContextProvider({ children }) {
	const [state, setState] = useState(initialState)

	function openMarginAnalysisView(
		rateChanges: any[],
		clientIndex: number,
		rateIndex: number,
		buyRates: any[],
		save: SaveFunction,
	) {
		const temp = returnCopy(rateChanges)
		const client = temp[clientIndex]
		const rate = temp[clientIndex].results[rateIndex]

		const myBuys = configureBuyRates(buyRates, rate)
		const marginAnalysisDisplayData = configureMarginAnalysisData(myBuys, rate)

		setState({
			...state,
			rate: rate,
			clientIndex: clientIndex,
			rateIndex: rateIndex,
			rateChanges: returnCopy(rateChanges),
			rateChangesSnapshot: returnCopy(rateChanges),
			rateChangesBuffer: returnCopy(rateChanges),
			buyRates: myBuys,
			showMarginAnalysis: true,
			marginMin: client.marginMin,
			marginMax: client.marginMax,
			marginAnalysisData: marginAnalysisDisplayData,
			save: save,
		})
	}

	function openMarginAnalysisViewForSellRatePage(
		rateFormValue: any,
		rateChanges: any[],
		clientIndex: number,
		rateIndex: number,
		buyRates: any[],
		save: SaveFunction,
	) {
		const temp = returnCopy(rateChanges)
		const client = temp[clientIndex]
		const rate = temp[clientIndex].results[rateIndex]

		const myBuys = configureBuyRates(buyRates, rate)
		const marginAnalysisDisplayData = configureMarginAnalysisData(myBuys, rate)

		setState({
			...state,
			rate: rate,
			rateFormValue: rateFormValue,
			clientIndex: clientIndex,
			rateIndex: rateIndex,
			rateChanges: returnCopy(rateChanges),
			rateChangesSnapshot: returnCopy(rateChanges),
			rateChangesBuffer: returnCopy(rateChanges),
			buyRates: myBuys,
			showMarginAnalysis: true,
			marginMin: client.marginMin,
			marginMax: client.marginMax,
			marginAnalysisData: marginAnalysisDisplayData,
			save: save,
		})
	}

	function close() {
		setState(initialState)
	}

	function save(): any {
		let rate = state.rate

		// If we are on the sell rates page the rate object we send back is different in order to set
		// the sell rate form correctly.
		if (state.rateFormValue) {
			rate = {
				...state.rateFormValue,
				rate20: state.rate.rate20.sellAmount,
				rate40: state.rate.rate40.sellAmount,
				rate40hc: state.rate.rate40hc.sellAmount,
				rate45: state.rate.rate45.sellAmount,
			}
		}

		state.save(rate, state.clientIndex, state.rateIndex)
		close()
	}

	function returnCopy(object: any) {
		return JSON.parse(JSON.stringify(object))
	}

	function handleInput(event: any, containerSize: "rate20" | "rate40" | "rate40hc" | "rate45") {
		const newValue = event.target.value
		const updatedClients = JSON.parse(JSON.stringify(state.rateChangesBuffer))

		// Set new rate value
		updatedClients[state.clientIndex].results[state.rateIndex][containerSize].sellAmount = newValue

		// Set new rate change value
		const prevRateChange =
			state.rateChangesSnapshot[state.clientIndex].results[state.rateIndex][containerSize]
				.rateChange
		const rateChange =
			newValue -
			state.rateChangesSnapshot[state.clientIndex].results[state.rateIndex][containerSize]
				.sellAmount
		updatedClients[state.clientIndex].results[state.rateIndex][containerSize].rateChange =
			prevRateChange + rateChange

		// Set new margin status
		const marginMin = updatedClients[state.clientIndex].marginMin
		const marginMax = updatedClients[state.clientIndex].marginMax
		const margin =
			newValue -
			updatedClients[state.clientIndex].results[state.rateIndex][containerSize].avgBuyAmountTop3
		updatedClients[state.clientIndex].results[state.rateIndex][containerSize].margin = margin

		if (margin >= marginMin && margin <= marginMax) {
			updatedClients[state.clientIndex].results[state.rateIndex][containerSize].status = "ok"
		} else if (margin > marginMax) {
			updatedClients[state.clientIndex].results[state.rateIndex][containerSize].status = "above"
		} else if (margin < marginMin) {
			updatedClients[state.clientIndex].results[state.rateIndex][containerSize].status = "below"
		}

		const rate = updatedClients[state.clientIndex].results[state.rateIndex]
		const marginAnalysisDataCopy = returnCopy(state.marginAnalysisData)
		marginAnalysisDataCopy.algorithm = {
			...marginAnalysisDataCopy.algorithm,
			[containerSize]: Math.round(margin),
		}
		marginAnalysisDataCopy.profitableCarriers = {
			...marginAnalysisDataCopy.profitableCarriers,
			[containerSize]: profitableCarriers(rate, containerSize),
		}
		marginAnalysisDataCopy.avgTop3 = {
			...marginAnalysisDataCopy.avgTop3,
			[containerSize]: calculateMargin(rate, containerSize),
		}
		marginAnalysisDataCopy.avgTop5 = {
			...marginAnalysisDataCopy.avgtop5,
			[containerSize]: calculateMargin(rate, containerSize, true),
		}

		const buyRates = updateBuyRateColumns(rate, containerSize)

		setState({
			...state,
			rateChangesBuffer: updatedClients,
			rate: rate,
			marginAnalysisData: marginAnalysisDataCopy,
			buyRates: buyRates,
		})
	}

	function configureBuyRates(buyRates, rate): any[] {
		let buyRatesCopy = buyRates ? returnCopy(buyRates) : []
		buyRatesCopy = buyRatesCopy.map((buyRate) => {
			return {
				...buyRate,
				rate20: marginAgainstBuyRate(buyRate, rate, "rate20"),
				rate40: marginAgainstBuyRate(buyRate, rate, "rate40"),
				rate40hc: marginAgainstBuyRate(buyRate, rate, "rate40hc"),
				rate45: marginAgainstBuyRate(buyRate, rate, "rate45"),
				markupRate20: buyRateMarkup(buyRate, rate, "rate20"),
				markupRate40: buyRateMarkup(buyRate, rate, "rate40"),
				markupRate40hc: buyRateMarkup(buyRate, rate, "rate40hc"),
				markupRate45: buyRateMarkup(buyRate, rate, "rate45"),
			}
		})

		return buyRatesCopy
	}

	function configureMarginAnalysisData(buyRates, rate): any {
		const marginAnalysisDataCopy = returnCopy(state.marginAnalysisData)

		marginAnalysisDataCopy.algorithm = {
			rate20: calculateMargin(rate, "rate20"),
			rate40: calculateMargin(rate, "rate40"),
			rate40hc: calculateMargin(rate, "rate40hc"),
			rate45: calculateMargin(rate, "rate45"),
		}
		marginAnalysisDataCopy.avgTop3 = {
			rate20: calculateMargin(rate, "rate20"),
			rate40: calculateMargin(rate, "rate40"),
			rate40hc: calculateMargin(rate, "rate40hc"),
			rate45: calculateMargin(rate, "rate45"),
		}
		marginAnalysisDataCopy.avgTop5 = {
			rate20: calculateMargin(rate, "rate20", true),
			rate40: calculateMargin(rate, "rate40", true),
			rate40hc: calculateMargin(rate, "rate40hc", true),
			rate45: calculateMargin(rate, "rate45", true),
		}
		marginAnalysisDataCopy.profitableCarriers = {
			rate20: profitableCarriers(rate, "rate20", buyRates),
			rate40: profitableCarriers(rate, "rate40", buyRates),
			rate40hc: profitableCarriers(rate, "rate40hc", buyRates),
			rate45: profitableCarriers(rate, "rate45", buyRates),
		}

		return marginAnalysisDataCopy
	}

	function updateBuyRateColumns(sellRate, containerSize): any[] {
		let buyRatesCopy = state.buyRates ? returnCopy(state.buyRates) : []
		buyRatesCopy = buyRatesCopy.map((buyRate) => {
			const markupKey = `markup${containerSize.charAt(0).toUpperCase() + containerSize.slice(1)}`
			return {
				...buyRate,
				[containerSize]: marginAgainstBuyRate(buyRate, sellRate, containerSize),
				[markupKey]: buyRateMarkup(buyRate, sellRate, containerSize),
			}
		})

		return buyRatesCopy
	}

	// Helpers
	const containerSizeMap = {
		rate20: "gp20",
		rate40: "gp40",
		rate40hc: "hq40",
		rate45: "hq45",
	}

	function moneyStringToNumber(moneyValue) {
		const numberValue = moneyValue
			? parseInt(moneyValue.trim().replace("$", "").replace(",", ""))
			: 0
		return numberValue
	}

	function buyRateMarkup(buyRate, rate, containerSize) {
		const buyRateValue = moneyStringToNumber(buyRate[containerSizeMap[containerSize]])
		if (!buyRateValue) return ""

		const markupValue = Math.round(
			(marginAgainstBuyRate(buyRate, rate, containerSize) / buyRateValue) * 100,
		)
		return `${markupValue}%`
	}

	function marginAgainstBuyRate(buyRate, rate, containerSize) {
		const marginValue = Math.round(
			rate[containerSize].sellAmount -
				moneyStringToNumber(buyRate[containerSizeMap[containerSize]]),
		)
		return marginValue
	}

	function profitableCarriers(sellRate, containerSize, buyRates?) {
		const buyRateList = buyRates ? buyRates : state.buyRates
		const profitableCarriers = []
		if (buyRateList.length) {
			buyRateList.forEach((buyRate) => {
				if (
					sellRate[containerSize].sellAmount >
					moneyStringToNumber(buyRate[containerSizeMap[containerSize]])
				) {
					if (!profitableCarriers.includes(buyRate.carrier)) {
						profitableCarriers.push(buyRate.carrier)
					}
				}
			})
		}
		return profitableCarriers.length
	}

	function calculateMargin(rate, containerSize, top5 = false) {
		const avgBuyValue = top5
			? rate[containerSize].avgBuyAmountTop5
			: rate[containerSize].avgBuyAmountTop3
		return Math.round(rate[containerSize].sellAmount - avgBuyValue)
	}

	return (
		<MarginAnalysisContext.Provider
			value={{
				openMarginAnalysisView: openMarginAnalysisView,
				openMarginAnalysisViewForSellRates: openMarginAnalysisViewForSellRatePage,
				close: close,
				handleInput: handleInput,
				rate: state.rate,
				rateChanges: state.rateChangesBuffer,
				marginAnalysisData: state.marginAnalysisData,
				showMarginAnalysis: state.showMarginAnalysis,
				marginMin: state.marginMin,
				marginMax: state.marginMax,
				buyRates: state.buyRates,
				save: save,
			}}
		>
			{children}
		</MarginAnalysisContext.Provider>
	)
}

export default MarginAnalysisContext
