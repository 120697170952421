import { useEffect } from "react"

const activityEvents = [
	"mousemove",
	"keydown",
	"wheel",
	"DOMMouseScroll",
	"mouseWheel",
	"mousedown",
	"touchstart",
	"touchmove",
	"MSPointerDown",
	"MSPointerMove",
	"visibilitychange",
]

const useActivityListener = (listener) => {
	useEffect(() => {
		activityEvents.forEach((e) => {
			document.addEventListener(e, listener, { capture: true, passive: true })
		})
		return () => {
			activityEvents.forEach((e) => {
				document.removeEventListener(e, listener, { capture: true })
			})
		}
	}, [])
}

export default useActivityListener
