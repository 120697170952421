import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate, useParams } from "react-router-dom"
import { useFormik } from "formik"
import "./ChangePasswordPage.scss"

interface IProps {
	user: any
}

const ForgotPasswordPage: React.FC<IProps> = ({ user }) => {
	const navigate = useNavigate()
	const params = useParams()
	const [changingPassword, setChangingPassword] = useState<boolean>(false)
	const token = params["token"]

	const validate = (values): any => {
		const errors = {} as any
		if (!values.newPassword) {
			errors.newPassword = "Required"
		} else if (values.newPassword.length < 6) {
			errors.newPassword = "Password must be at least 6 characters"
		}
		if (!values.confirmedPassword) {
			errors.confirmedPassword = "Required"
		} else if (values.confirmedPassword !== values.newPassword) {
			errors.confirmedPassword = "Passwords do not match"
		}
		return errors
	}

	const form = useFormik({
		initialValues: {
			newPassword: "",
			confirmedPassword: "",
		},
		validate,
		onSubmit: (values) => {
			setChangingPassword(true)
			user
				.resetPassword(token, values.newPassword)
				.then(() => {
					form.resetForm()
					navigate("/login")
				})
				.finally(() => {
					setChangingPassword(false)
				})
		},
	})

	return (
		<div id="pass-page-wrapper">
			<Helmet>
				<title>Change Password | UWL Pricing Tool</title>
			</Helmet>
			<div id="pass-card" className="card">
				<div className="card-header">Change Password</div>
				<div className="card-body">
					<div id="pass-form-header">
						<p id="pass-form-p">Please enter and confirm the new password.</p>
					</div>
					<hr id="hr-m" />
					<form onSubmit={form.handleSubmit} id="pass-form">
						<fieldset disabled={changingPassword}>
							<div className="change-pass-input-wrapper">
								<label htmlFor="pass-email-input">New Password</label>
								<input
									autoFocus
									className={`form-control ${
										form.touched.newPassword && form.errors.newPassword ? "has-error" : null
									}`}
									id="pass-email-input"
									name="newPassword"
									onBlur={form.handleBlur}
									onChange={form.handleChange}
									type="password"
									value={form.values.newPassword}
								/>
								{form.touched.newPassword && form.errors.newPassword ? (
									<div className="pass-input-errors">{form.errors.newPassword}</div>
								) : null}
								<label id="change-pass-label" htmlFor="pass-email-input">
									Confirm New Password
								</label>
								<input
									className={`form-control ${
										form.touched.confirmedPassword && form.errors.confirmedPassword
											? "has-error"
											: null
									}`}
									id="pass-email-input"
									name="confirmedPassword"
									onBlur={form.handleBlur}
									onChange={form.handleChange}
									type="password"
									value={form.values.confirmedPassword}
								/>
								{form.touched.confirmedPassword && form.errors.confirmedPassword ? (
									<div className="pass-input-errors">{form.errors.confirmedPassword}</div>
								) : null}
							</div>
							<button
								type="submit"
								className="btn btn-primary"
								id="pass-button"
								disabled={
									form.touched.newPassword &&
									!form.errors.newPassword &&
									form.touched.confirmedPassword &&
									!form.errors.confirmedPassword
										? false
										: true
								}
							>
								{changingPassword ? (
									<div className="text-center spinner-border text-light" role="status"></div>
								) : (
									"Change Password"
								)}
							</button>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	)
}

export default ForgotPasswordPage
