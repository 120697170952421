export interface IReducerAction {
	type: string
	payload?: any
}

export interface IDrayBuyRatePageState {
	rates: any[]
	showRateFormModal: boolean
	showRateImportModal: boolean
	rateBuffer: any
	refreshFN: Function
}

export const InitialDrayBuyRatePageState: IDrayBuyRatePageState = {
	rates: [],
	showRateFormModal: false,
	showRateImportModal: false,
	rateBuffer: null,
	refreshFN: null,
}

export interface IPageReducerAction extends IReducerAction {
	type:
		| "SET_RATES"
		| "CLEAR_RATE_BUFFER"
		| "SET_RATE_BUFFER"
		| "EDIT_RATE"
		| "NEW_RATE"
		| "IMPORT_RATES"
		| "CLOSE_RATE_FORM"
		| "CLOSE_RATE_IMPORTER"
		| "SET_REFRESH_FN"
}

export const DrayBuyRatePageReducer = (
	state: IDrayBuyRatePageState,
	action: IPageReducerAction,
) => {
	switch (action.type) {
		case "SET_RATES":
			return {
				...state,
				rates: action.payload,
			}
		case "SET_RATE_BUFFER":
			return {
				...state,
				rateBuffer: action.payload,
			}
		case "CLEAR_RATE_BUFFER":
			return {
				...state,
				rateBuffer: null,
			}
		case "EDIT_RATE":
			return {
				...state,
				rateBuffer: action.payload,
				showRateFormModal: true,
			}
		case "NEW_RATE":
			return {
				...state,
				showRateImportModal: false,
				showRateFormModal: true,
				rateBuffer: null,
			}
		case "IMPORT_RATES":
			return {
				...state,
				showRateImportModal: true,
				showRateFormModal: false,
				rateBuffer: null,
			}
		case "CLOSE_RATE_FORM":
			return {
				...state,
				rateBuffer: null,
				showRateFormModal: false,
				showRateImportModal: false,
			}
		case "CLOSE_RATE_IMPORTER":
			return {
				...state,
				rateBuffer: null,
				showRateFormModal: false,
				showRateImportModal: false,
			}
		case "SET_REFRESH_FN":
			return {
				...state,
				refreshFN: action.payload,
			}
		default:
			return {
				...state,
			}
	}
}
