import React, { Fragment } from "react"
import DatePicker from "react-date-picker"
import { Input } from "reactstrap"

// interface DateFilterValue {
//   type: string;
//   from: Date | null;
//   to: Date | null;
//   n?: number | null;
// }

const DATE_FILTER_TYPES = [
	{
		label: "date range",
		value: "range",
	},
	{
		label: "after",
		value: "after",
	},
	{
		label: "before",
		value: "before",
	},
	{
		label: "YTD (year to date)",
		value: "ytd",
	},
	{
		label: "MTD (month to date",
		value: "mtd",
	},
	{
		label: "QTD (quarter to date)",
		value: "qtd",
	},
	{
		label: "past N days",
		value: "past-n-days",
	},
]

function DateFilter({ filter, updateFilter }) {
	if (!filter.value) {
		filter.value = {
			type: "range",
			toDate: null,
			fromDate: null,
		}
	}

	function updateValueType(e) {
		const updatedFilter = { ...filter }
		if (e.target.value !== "past-n-days") {
			delete updatedFilter.value.n
			updatedFilter.value = {
				type: e.target.value,
				toDate: null,
				fromDate: null,
			}
		} else {
			updatedFilter.value = {
				type: "past-n-days",
				toDate: null,
				fromDate: null,
				n: null,
			}
		}

		updateFilter(updatedFilter)
	}

	function updateValue(key, value) {
		const updatedFilter = { ...filter }
		filter.value[key] = value

		updateFilter(updatedFilter)
	}

	return (
		<div>
			{filter && filter.value ? (
				<div>
					<TypeSelector filter={filter} updateValueType={updateValueType}></TypeSelector>
					<ValueInput filter={filter} updateValue={updateValue} />
				</div>
			) : null}
		</div>
	)
}

function TypeSelector({ filter, updateValueType }) {
	return (
		<Input type="select" onChange={updateValueType} defaultValue={filter.value.type}>
			{DATE_FILTER_TYPES.map((dft) => (
				<option value={dft.value} key={dft.value}>
					{dft.label}
				</option>
			))}
		</Input>
	)
}

function ValueInput({ filter, updateValue }) {
	switch (filter.value.type || null) {
		case "range": {
			return (
				<>
					<DatePicker
						value={filter.value.fromDate}
						format="MM/dd/yyyy"
						onChange={(value: Date) => updateValue("fromDate", value)}
					/>
					-
					<DatePicker
						value={filter.value.toDate}
						format="MM/dd/yyyy"
						onChange={(value: Date) => updateValue("toDate", value)}
					/>
				</>
			)
		}
		case "before": {
			return (
				<>
					<DatePicker
						value={filter.value.toDate}
						format="MM/dd/yyyy"
						onChange={(value: Date) => updateValue("toDate", value)}
					/>
				</>
			)
		}
		case "after": {
			return (
				<>
					<DatePicker
						value={filter.value.fromDate}
						format="MM/dd/yyyy"
						onChange={(value: Date) => updateValue("fromDate", value)}
					/>
				</>
			)
		}
		case "past-n-days": {
			return (
				<>
					<Input
						type="number"
						placeholder="N days"
						value={filter.value.n}
						onChange={(e) => updateValue("n", e.target.value)}
					/>
				</>
			)
		}
		default: {
			return <></>
		}
	}
}

export default DateFilter
