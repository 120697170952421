import React, { useState } from "react"
import { BsTrashFill } from "react-icons/bs"
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Button,
	Row,
	Col,
} from "reactstrap"

function FiltersetLookup({ filterSets, newFilterSet, selectFilterSet, removeFilterSet }) {
	filterSets = filterSets.filter((f) => f.name !== "Default")
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const deleteIcons = []
	const setRef = (ref) => {
		if (ref) deleteIcons.push(ref)
	}

	function toggle(e) {
		const parentElement = e.target.parentElement
		if (
			dropdownOpen &&
			(deleteIcons.includes(e.target) ||
				deleteIcons.includes(parentElement) ||
				deleteIcons.includes(parentElement.parentElement))
		)
			return
		setDropdownOpen(!dropdownOpen)
	}

	const [modal, setModal] = useState(false)
	const toggleModal = () => {
		setFiltersetName("")
		setModal((prevState) => !prevState)
	}

	const createFn = () => {
		newFilterSet(filterSetName)
		toggleModal()
	}

	const [filterSetName, setFiltersetName] = useState("")
	function updateFilterSetName(e) {
		setFiltersetName(e.target.value)
	}

	function Options() {
		return (
			<>
				<DropdownItem divider></DropdownItem>
				{filterSets.length && <DropdownItem header>Select Filterset</DropdownItem>}
				{filterSets.map((fs) => (
					<DropdownItem key={fs.name}>
						<Row>
							<Col md="9" onClick={() => selectFilterSet(fs.name)}>
								{fs.name}
							</Col>
							<div
								ref={setRef}
								id={fs.name}
								onClick={() => removeFilterSet(fs.name)}
								className="d-inline-block ml-2"
							>
								<BsTrashFill></BsTrashFill>
							</div>
						</Row>
					</DropdownItem>
				))}
			</>
		)
	}

	return (
		<>
			<Dropdown isOpen={dropdownOpen} toggle={toggle}>
				<DropdownToggle caret>Select Filterset</DropdownToggle>
				<DropdownMenu>
					<DropdownItem header>Create Filterset</DropdownItem>
					<DropdownItem onClick={() => toggleModal()} key={"create"}>
						Create
					</DropdownItem>
					{filterSets && filterSets.length ? <Options></Options> : null}
				</DropdownMenu>
			</Dropdown>
			<Modal isOpen={modal} toggle={toggleModal}>
				<ModalHeader>Create Filterset</ModalHeader>
				<ModalBody>
					<Input
						type="text"
						placeholder="Filterset name..."
						value={filterSetName}
						onChange={(e) => updateFilterSetName(e)}
					></Input>
				</ModalBody>
				<ModalFooter>
					<Button color={"secondary"} onClick={toggleModal}>
						Cancel
					</Button>
					<Button color="primary" onClick={() => createFn()}>
						Create
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default FiltersetLookup
