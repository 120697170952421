import React from "react"
import "./Tabs.scss"

export interface TabsProps {
	tabs
	activeTab
	changeTab
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, changeTab }) => {
	return (
		<ul className="nav nav-tabs tabs">
			{tabs.map((tab) => (
				<Tab
					key={tab.label}
					data={tab}
					isActive={activeTab.label === tab.label}
					handleClick={changeTab}
				/>
			))}
		</ul>
	)
}

export interface TabProps {
	data
	isActive
	handleClick
}

const Tab: React.FC<TabProps> = ({ data, isActive, handleClick }) => {
	return (
		<li
			onClick={() => handleClick(data)}
			className={isActive ? "nav-item nav-link active active-tab" : "nav-item nav-link tab"}
		>
			<p>{data.label}</p>
		</li>
	)
}

export default Tabs
