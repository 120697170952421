import { Fragment, useState, useEffect, FC, ChangeEvent } from "react"
import AdminAccessorialsCell from "./AdminAccessorialsCell"
import useAdminService from "Services/AdminService"
import { ContextType, TableDataType } from "Types/AdminAccessoraialsTypes"

interface IProps {
	context: ContextType
}

const AdminAccessorialsTable: FC<IProps> = ({ context }) => {
	const [accessorials, setAccessorials] = useState<TableDataType[]>([])
	const [record, setRecord] = useState<TableDataType>({
		id: undefined,
		isDefault: false,
		name: "",
		location: "",
		amount: 0,
		per: "",
	})
	const [newEntry, setNewEntry] = useState<boolean>(false)
	const [errors, setErrors] = useState<string>("")
	const [selectedIndex, setSelectedIndex] = useState<number>(-1)

	const adminService = useAdminService()

	useEffect(() => {
		const accTemp = [...context.tableData]
		setAccessorials(accTemp)
		// eslint-disable-next-line
	}, [context.tableData])

	const display = (): void => {
		setNewEntry(true)
	}

	const updateInput = (key: string, data: string | boolean | number): void => {
		const temp = { ...record, [key]: data }
		setRecord(temp)
	}

	const validateField = (amount: string): boolean => {
		let amountFormatted = false
		let amountFormErrors = ""
		if (amount === "") {
			amountFormErrors = "Amount is required"
		} else if (parseFloat(amount) === 0.0) {
			amountFormErrors = "Amount must be greater than Zero"
		} else {
			amount.match(/^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\d{0,2}|\.\d{1,2})$/)
				? (amountFormatted = true)
				: (amountFormatted = false)
			amountFormErrors = amountFormatted ? "" : "Invalid amount format (###.##)"
		}
		setErrors(amountFormErrors)
		return amountFormErrors === ""
	}

	const inputHandler = (event: ChangeEvent<HTMLInputElement>): void => {
		let value: string | number
		if (event.target.name === "amount") {
			const isValid = validateField(event.target.value)
			value = isValid ? parseFloat(event.target.value) : 0
		} else {
			value = event.target.value
		}
		const name = event.target.name
		updateInput(name, value)
	}

	const checkHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name
		const value = event.target.checked
		updateInput(name, value)
	}

	const validateRecord = (record: TableDataType): boolean => {
		const findRecord = accessorials.find((r) => {
			return (
				r.name.toLowerCase() === record.name.toLowerCase() &&
				r.location.toLowerCase() === record.location.toLowerCase() &&
				r.amount === record.amount &&
				r.isDefault === record.isDefault &&
				r.per.toLowerCase() === record.per.toLowerCase()
			)
		})
		return findRecord ? false : true
	}

	const add = (): void => {
		if (validateRecord(record)) {
			adminService.addRecord(context.tableName, record).then(() => {
				context.fetchAdminTableFn(context.tableName)
			})
			reset()
		} else {
			setErrors("That record already exists.")
		}
	}

	const update = (record: TableDataType): void => {
		if (validateRecord(record)) {
			adminService.updateRecord(context.tableName, record).then(() => {
				context.fetchAdminTableFn(context.tableName)
			})
			reset()
			selectedIndexFn(-1)
		} else {
			setErrors("That record already exists.")
		}
	}

	const remove = (record: TableDataType): void => {
		adminService.deleteRecord(context.tableName, record.id).then(() => {
			context.fetchAdminTableFn(context.tableName)
		})
		reset()
	}

	const reset = (): void => {
		setRecord({
			id: undefined,
			isDefault: false,
			name: "",
			location: "",
			amount: 0,
			per: "",
		})
		setNewEntry(false)
		setErrors("")
	}

	const selectedIndexFn = (index: number): void => {
		setErrors("")
		if (index !== -1) {
			setSelectedIndex(index)
		} else {
			setSelectedIndex(-1)
		}
	}

	return (
		<Fragment>
			<div className={"card accessorial-admin-table"}>
				<div className={"card-header accessorial-admin-table-title"}>
					{context.tableName}
					<button
						type="button"
						className="add-btn"
						onClick={() => display()}
						disabled={newEntry || selectedIndex !== -1}
					>
						<i className="fa fa-plus"></i>
					</button>
				</div>
				<div className="card-body">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th scope="col">Default</th>
								<th scope="col">Charge Name</th>
								<th scope="col">Location</th>
								<th scope="col">Default Amount</th>
								<th scope="col">Per</th>
								<th scope="col">Actions</th>
							</tr>
							<tr hidden={!newEntry} className="newEntry">
								<th>
									<input
										type="checkbox"
										name="isDefault"
										checked={record.isDefault}
										onChange={checkHandler}
									/>
								</th>
								<th>
									<input type="text" name="name" value={record.name} onChange={inputHandler} />
								</th>
								<th>
									<input
										type="text"
										name="location"
										value={record.location}
										onChange={inputHandler}
									/>
								</th>
								<th>
									<input
										type="text"
										name="amount"
										value={record.amount !== 0 ? record.amount : ""}
										className={errors ? "amount-error" : null}
										onChange={inputHandler}
									/>
								</th>
								<th>
									<input type="text" name="per" value={record.per} onChange={inputHandler} />
								</th>
								<th colSpan={2}>
									<div className="action-cell">
										<button
											type="button"
											disabled={
												record.name === "" ||
												record.amount === 0 ||
												record.per === "" ||
												errors !== ""
											}
											className="save-btn"
											onClick={add}
										>
											<i className="fa fa-floppy-o"></i>
										</button>
										<button type="button" className="trash-cancel-btn" onClick={reset}>
											<i className="fa fa-ban"></i>
										</button>
									</div>
									<p hidden={errors === ""} className="errors">
										{errors}
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							{accessorials
								? accessorials.map((acc, index) => (
										<AdminAccessorialsCell
											acc={acc}
											rowIndex={index}
											selectedIndexFn={selectedIndexFn}
											selectedIndex={selectedIndex}
											key={acc.id}
											newEntry={newEntry}
											validateField={validateField}
											errors={errors}
											updateAccFn={update}
											removeAccFn={remove}
										></AdminAccessorialsCell>
								  ))
								: null}
						</tbody>
					</table>
				</div>
			</div>
		</Fragment>
	)
}

export default AdminAccessorialsTable
