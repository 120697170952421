import { useCallback, useState } from "react"

const usePageTitleService = (): [string, Function] => {
	const [pageTitle, setPageTitle] = useState("")
	const updatePageTitle = useCallback((title) => {
		setPageTitle(title)
	}, [])

	return [pageTitle, updatePageTitle]
}

export default usePageTitleService
