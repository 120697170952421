import React from "react"
import { formatDateString } from "Utilities/DateUtils"

export function SurchargeTable({ surcharges }) {
	return (
		<table className={"table table-bordered"}>
			<thead className="thead-light">
				<tr>
					<th scope="col">Rate Type</th>
					<th scope="col">Trade</th>
					<th scope="col">20&apos;</th>
					<th scope="col">40&apos;</th>
					<th scope="col">40&apos;HC</th>
					<th scope="col">45&apos;</th>
					<th scope="col">Eff. Date</th>
					<th scope="col">Exp. Date</th>
				</tr>
			</thead>
			<tbody>
				{surcharges.length
					? surcharges.map((surcharge) => {
							return (
								<tr key={surcharge.id}>
									<td>{surcharge.rateType}</td>
									<td>{surcharge.trade}</td>
									<td>${surcharge.rate20}</td>
									<td>${surcharge.rate40}</td>
									<td>${surcharge.rate40hc}</td>
									<td>${surcharge.rate45}</td>
									<td>{formatDateString(surcharge.effectiveDate)}</td>
									<td>{formatDateString(surcharge.expirationDate)}</td>
								</tr>
							)
					  })
					: null}
			</tbody>
		</table>
	)
}

export default SurchargeTable
