import React, { FC, Fragment, useState, useEffect } from "react"
import "../ClientProfile.scss"

export interface IProps {
	clientContext: any
}

const ClientInfo: FC<IProps> = ({ clientContext }) => {
	const isProspective = clientContext.client.prospective
	const isDomestic = clientContext.client.country === "United States"

	const updateDetails = (event: any) => {
		const name = event.target.name
		let val = null
		if (event.target.type === "number") {
			val = parseInt(event.target.value)
		} else if (event.target.type === "checkbox") {
			val = event.target.checked
		} else {
			val = event.target.value
		}

		clientContext.updateClientFn(name, val)
	}

	const updateMargin = (event: any) => {
		setMargin(event.target.value)
		const val = parseInt(event.target.value)
		if (val != null && val !== undefined && !isNaN(val)) {
			updateDetails(event)
		} else {
			clientContext.updateClientFn(event.target.name, "")
		}
	}

	const onImageChange = (event: any) => {
		const blob = event.target.files[0]
		const reader = new FileReader()
		reader.readAsDataURL(blob)
		reader.onloadend = function () {
			const base64data = reader.result
			clientContext.updateClientFn("logo", base64data)
		}
	}
	const [margin, setMargin] = useState(null)
	useEffect(() => {
		setMargin(clientContext.client.prospectiveMargin)
	}, [clientContext.client])

	return (
		<Fragment>
			<div className="card">
				<div className="card-header">General Information</div>
				<div className="card-body">
					<form className="row">
						<div className="col general">
							<div className="group">
								<label>Prospective Client</label>
								<input
									type="checkbox"
									name="prospective"
									value={clientContext.client.prospective}
									checked={clientContext.client.prospective}
									onChange={updateDetails}
								></input>
							</div>
							{isProspective ? (
								<div>
									<div className="group">
										<label>Prospective Margin*</label>
										<input
											type="number"
											name="prospectiveMargin"
											placeholder="Enter Amount"
											className="form-control"
											value={margin}
											onChange={updateMargin}
										></input>
									</div>
									<div className="group">
										<label>Prospective Rate Sheet*</label>
										<select
											name="emailFrequency"
											value={clientContext.client.emailFrequency}
											className="form-control"
											onChange={updateDetails}
										>
											<option value="" disabled>
												-- Select Frequency --
											</option>
											<option value={"daily"}>Daily</option>
											<option value={"weekly"}>Weekly</option>
											<option value={"bimonthly"}>Bi-Monthly</option>
											<option value={"monthly"}>Monthly</option>
											<option value={"quarterly"}>Quarterly</option>
										</select>
									</div>
								</div>
							) : null}
							<div className="group">
								<label>Client Name*</label>
								<input
									type="text"
									name="name"
									placeholder="Client Name"
									className="form-control"
									value={clientContext.client.name}
									onChange={updateDetails}
								></input>
							</div>
							<div className="group">
								<label>Country*</label>
								<select
									name="country"
									value={clientContext.client.country}
									className="form-control"
									onChange={updateDetails}
								>
									<option value="" disabled>
										-- Select Country --
									</option>
									{clientContext.countries.map((country, index) => (
										<option key={index} value={country.value}>
											{country.text}
										</option>
									))}
								</select>
							</div>
							<div className="group">
								<label>Address*</label>
								{isDomestic ? (
									<input
										type="text"
										name="address"
										placeholder="Address"
										className="form-control"
										value={clientContext.client.address}
										onChange={updateDetails}
									></input>
								) : (
									<textarea
										name="address"
										placeholder="Address"
										className="form-control"
										disabled={!clientContext.client.country}
										value={clientContext.client.address}
										onChange={updateDetails}
									></textarea>
								)}
							</div>
							{isDomestic ? (
								<div>
									<div className="group">
										<label>City*</label>
										<input
											type="text"
											name="city"
											placeholder="City"
											className="form-control"
											value={clientContext.client.city}
											onChange={updateDetails}
										></input>
									</div>
									<div className="group">
										<label>State*</label>
										<select
											name="state"
											value={clientContext.client.state}
											className="form-control"
											onChange={updateDetails}
										>
											<option value="" disabled>
												-- Select State --
											</option>
											{clientContext.usStates.map((state, index) => (
												<option key={index} value={state.value}>
													{state.text}
												</option>
											))}
										</select>
									</div>
									<div className="group">
										<label>Zip Code*</label>
										<input
											type="text"
											name="zipCode"
											placeholder="Zip Code"
											className="form-control"
											value={clientContext.client.zipCode}
											onChange={updateDetails}
										></input>
									</div>
								</div>
							) : null}
							<div className="group">
								<label>CW Org Code{clientContext.client.prospective ? "" : "*"}</label>
								<input
									type="text"
									name="cwOrgCode"
									placeholder="CW Org Code"
									className="form-control"
									value={clientContext.client.cwOrgCode}
									onChange={updateDetails}
								></input>
							</div>
						</div>
						<div className="col general">
							<div className="group">
								<label>Margin Min*</label>
								<input
									type="number"
									min="0"
									name="marginMin"
									className="form-control"
									value={clientContext.client.marginMin}
									onChange={updateDetails}
								></input>
							</div>
							<div className="group">
								<label>Margin Max*</label>
								<input
									type="number"
									min="0"
									name="marginMax"
									className="form-control"
									value={clientContext.client.marginMax}
									onChange={updateDetails}
								></input>
							</div>
							<div className="group">
								<label>Primary Agent</label>
								<select
									name="primaryAgent"
									value={clientContext.client.primaryAgent}
									className="form-control"
									onChange={updateDetails}
								>
									<option value="" disabled>
										-- Select Agent --
									</option>
									{clientContext.agents.map((agent, index) => (
										<option key={index} value={agent.value}>
											{agent.text}
										</option>
									))}
								</select>
							</div>
							<div className="group">
								<label>General Commodity</label>
								<select
									name="genCommodity"
									value={clientContext.client.genCommodity}
									className="form-control"
									onChange={updateDetails}
								>
									{clientContext.commodities.map((comm, index) => (
										<option key={index} value={comm.value}>
											{comm.text}
										</option>
									))}
								</select>
							</div>
							<div className="radio-parent">
								<label>Rate Structure*</label>
								<div className="radio-group">
									<div className="form-check form-check-inline">
										<input
											type="radio"
											name="rateStructure"
											value="Rolled Up"
											className="form-check-input"
											checked={clientContext.client.rateStructure === "Rolled Up"}
											onChange={updateDetails}
										/>
										<label className="form-check-label">Rolled Up</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											type="radio"
											name="rateStructure"
											value="Broken Out"
											className="form-check-input"
											checked={clientContext.client.rateStructure === "Broken Out"}
											onChange={updateDetails}
										/>
										<label className="form-check-label">Broken Out</label>
									</div>
									<div className="form-check form-check-inline form-last-button">
										<input
											type="radio"
											name="rateStructure"
											value="Both"
											className="form-check-input"
											checked={clientContext.client.rateStructure === "Both"}
											onChange={updateDetails}
										/>
										<label className="form-check-label">Both</label>
									</div>
								</div>
							</div>
							<div className="group">
								<label>Upload Logo</label>
								<input
									type="file"
									title=""
									className="form-control-file"
									name="logo"
									onChange={onImageChange}
								/>
								{!clientContext.client.id && clientContext.client.logo ? (
									<img alt="" src={clientContext.client.logo}></img>
								) : null}
							</div>
						</div>
					</form>
				</div>
			</div>
		</Fragment>
	)
}

export default ClientInfo
