import React, { useRef } from "react"
import uniqid from "uniqid"
import { useField } from "formik"

function TextInput({ label, ...props }) {
	const id = useRef(uniqid())
	const [field, meta] = useField(props.name)
	return (
		<div className={"form-input-group"}>
			{
				<label
					htmlFor={id.current}
					className={`${meta.touched && meta.error ? "has-error" : null}`}
				>
					{label}
				</label>
			}
			<input
				id={id.current}
				className={`form-control ${meta.touched && meta.error ? "has-error" : null}`}
				{...field}
				{...props}
			/>
			{meta.touched && meta.error && <div className={"input-errors"}></div>}
		</div>
	)
}

export default TextInput
