import React from "react"
import "./AlertTemplate.scss"

const AlertTemplate = ({ message, options, style, close }) => {
	let type = message?.type ? message.type : message
	let details = message?.details ? message.details : ""

	if (details instanceof Object) {
		details = ""
	}
	if (type instanceof Object) {
		type = ""
	}

	const color = options.type === "success" ? "#5cb85c" : "#d9534f"

	return (
		<div style={{ borderLeft: "10px solid" + color }} className="alert-box">
			{options.type === "success" && (
				<i style={{ color: color }} className="fa fa-check-circle icon"></i>
			)}
			{options.type === "error" && (
				<i style={{ color: color }} className="fa fa-exclamation-circle icon"></i>
			)}

			<div className="row row-cols-1" style={{ textAlign: "center" }}>
				<span className="col" style={{ textTransform: "uppercase", lineHeight: "150%" }}>
					{type}
				</span>
				{details && <span className="col"> {details}</span>}
			</div>
			<button onClick={close} className="close-button">
				<i className="fa fa-times"></i>
			</button>
		</div>
	)
}

export default AlertTemplate
