import React from "react"
import { Button, Col, Input, Label, Row } from "reactstrap"
import { BsTrashFill } from "react-icons/bs"
import SearchFilterMap from "./DynamicSearchFilterMap"
import { Filterable } from "."

interface TableCellProps {
	filter: Filterable
	updateFilter: Function
	removeFilter: Function
}

function TableCell({ filter, updateFilter, removeFilter }: TableCellProps) {
	return (
		<Row className={"pb-4 pt-4 border-bottom"}>
			<Col md="12">
				<Row className="align-items-center">
					<Col md="8">
						<label>{filter.label}</label>
						<SearchFilterMap filter={filter} updateFilter={updateFilter} />
					</Col>
					<Col md="4">
						{filter.required ? null : (
							<TableCellActions
								filter={filter}
								removeFilter={removeFilter}
								updateFilter={updateFilter}
							/>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

function TableCellActions({ filter, removeFilter, updateFilter }) {
	function applyFilter() {
		const updatedFilter = { ...filter }
		updatedFilter.applied = !updatedFilter.applied
		updateFilter(updatedFilter)
	}

	return (
		<div className="d-flex justify-content-end">
			<Label>
				Applied
				<Input
					type="switch"
					id={filter.field}
					checked={filter.applied}
					onChange={() => applyFilter()}
				/>
			</Label>
			<Button onClick={() => removeFilter(filter)} className="d-inline-block ml-4">
				<BsTrashFill></BsTrashFill>
			</Button>
		</div>
	)
}

export default TableCell
