import { Fragment, FC, useState, ChangeEvent } from "react"
import "../AdminConsole.scss"
import useAdminService from "Services/AdminService"
import { trackPromise } from "react-promise-tracker"

interface IProps {
	context: any
}

const ProspectiveMessage: FC<IProps> = ({ context }) => {
	const adminService = useAdminService()

	const [state, setState] = useState({
		id: context.tableData.id,
		message: context.tableData.message,
	})
	const [errors, setErrors] = useState<string>("")

	const inputHandler = (event: ChangeEvent<HTMLTextAreaElement>): void => {
		validateFieldFn(event.target.value)
		updateInput(event.target.name, event.target.value)
	}

	const updateInput = (key: string, data: string): void => {
		const temp = { ...state, [key]: data }
		setState(temp)
	}

	const updateMessage = (): void => {
		trackPromise(
			adminService.updateRecord("prospective_email_message", state).then(() => {
				context.fetchAdminTableFn(context.tableName)
			}),
		)
	}

	const validateFieldFn = (message: string): void => {
		if (!message || message === "") {
			setErrors("Message cannot be empty")
		} else if (errors !== "") {
			setErrors("")
		}
	}

	return (
		<Fragment>
			<div className={"card fuel-surcharge"}>
				<div className={"card-header"}>{context.tableName}</div>
				<div className="card-body">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th scope="col">Prospective Client Email Message</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="input-group">
										<span className="input-group-text">Message</span>
										<textarea
											name="message"
											rows={8}
											value={state.message}
											className="form-control"
											onChange={inputHandler}
										/>
										<button
											type="button"
											className="btn btn-primary align-self-center"
											disabled={errors !== "" || state.message === ""}
											onClick={updateMessage}
										>
											SAVE
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<p hidden={errors === ""} className="errors">
						{errors}
					</p>
				</div>
			</div>
		</Fragment>
	)
}

export default ProspectiveMessage
