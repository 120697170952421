import { Input } from "reactstrap"

function StringFilter({ filter, updateFilter }) {
	// Set default value
	if (!filter.value) filter.value = ""

	function updateValue(e: any) {
		const updatedFilter = { ...filter }
		updatedFilter.value = e.target.value
		updateFilter(updatedFilter)
	}

	return (
		<div>
			<Input
				type="text"
				placeholder={filter.label}
				name={filter.field}
				value={filter.value}
				onChange={(e) => updateValue(e)}
			/>
		</div>
	)
}

export default StringFilter
