import React from "react"
import ApiContextProvider from "Contexts/API/Provider"
import { BrowserRouter } from "react-router-dom"
import UserProvider from "./Contexts/User/Provider"
import AppRouter from "./Routing/AppRouter"
import { MarginAnalysisContextProvider } from "./Contexts/MarginAnalysis/Provider"

const App: React.FC = () => {
	return (
		<ApiContextProvider>
			<UserProvider>
				<MarginAnalysisContextProvider>
					<BrowserRouter>
						<AppRouter />
					</BrowserRouter>
				</MarginAnalysisContextProvider>
			</UserProvider>
		</ApiContextProvider>
	)
}

export default App
