import React, { Fragment, useState, useContext, useEffect } from "react"
import "./UserProfile.scss"
import UserContext from "Contexts/User/Context"
import { useFormik } from "formik"
import Helmet from "react-helmet"

function UserProfile({ setHeaderTitle }) {
	const user = useContext(UserContext)
	const [saving, setSaving] = useState<boolean>(false)

	useEffect(() => {
		setHeaderTitle("User Profile")
	}, [setHeaderTitle])

	const validate = (values): any => {
		const errors = {} as any

		if (!values.firstName) {
			errors.firstName = "Required"
		}

		if (!values.lastName) {
			errors.lastName = "Required"
		}

		if (values.password && values.password.length < 6) {
			errors.password = "Password must be at least 6 characters!"
		}

		if (!values.confirmPassword.match(values.password)) {
			errors.confirmPassword = "Passwords must match!"
		}

		return errors
	}

	const form = useFormik({
		initialValues: {
			id: user.details.id,
			firstName: user.details.firstName,
			lastName: user.details.lastName,
			username: user.details.username,
			password: "",
			confirmPassword: "",
		},
		validate,
		onSubmit: (values) => {
			let details = {}
			if (values.password === "") {
				details = {
					id: values.id,
					firstName: values.firstName,
					lastName: values.lastName,
					username: values.username,
				}
			} else {
				details = {
					id: values.id,
					firstName: values.firstName,
					lastName: values.lastName,
					username: values.username,
					password: values.password,
				}
			}
			setSaving(true)
			user.update(details).then(
				() => {
					user.getUserDetails()
					setSaving(false)
				},
				(err) => {
					setSaving(false)
				},
			)
		},
	})

	return (
		<Fragment>
			<Helmet>
				<title>Login | UWL Pricing Tool</title>
			</Helmet>
			<div className="card" id="panel">
				<div className="card-body">
					<form onSubmit={form.handleSubmit}>
						<fieldset disabled={saving}>
							<div>
								<label htmlFor="first-name-input">First Name</label>
								<input
									className={`form-control ${
										form.touched.firstName && form.errors.firstName ? "has-error" : null
									}`}
									type="text"
									id="first-name-input"
									name="firstName"
									onChange={form.handleChange}
									onBlur={form.handleBlur}
									value={form.values.firstName}
								/>
								{form.touched.firstName && form.errors.firstName ? (
									<div className="input-errors">{form.errors.firstName}</div>
								) : null}
							</div>
							<div>
								<label htmlFor="last-name-input">Last Name</label>
								<input
									className={`form-control ${
										form.touched.lastName && form.errors.lastName ? "has-error" : null
									}`}
									type="text"
									id="last-name-input"
									name="lastName"
									onChange={form.handleChange}
									onBlur={form.handleBlur}
									value={form.values.lastName}
								/>
								{form.touched.lastName && form.errors.lastName ? (
									<div className="input-errors">{form.errors.lastName}</div>
								) : null}
							</div>
							<div>
								<label htmlFor="user-name-input">Username</label>
								<input
									className={`form-control`}
									type="text"
									id="username-input"
									name="username"
									disabled={true}
									value={form.values.username}
								/>
							</div>
							<div>
								<label htmlFor="password-input">New Password</label>
								<input
									className={`form-control ${
										form.touched.password && form.errors.password ? "has-error" : null
									}`}
									type="password"
									id="password-input"
									name="password"
									onChange={form.handleChange}
									onBlur={form.handleBlur}
									value={form.values.password ? form.values.password : ""}
								/>
								{form.touched.password && form.errors.password ? (
									<div className="input-errors">{form.errors.password}</div>
								) : null}
							</div>
							<div>
								<label htmlFor="confirm-password-input">Confirm Password</label>
								<input
									disabled={!form.values.password || form.values.password === ""}
									className={`form-control ${
										form.touched.confirmPassword && form.errors.confirmPassword ? "has-error" : null
									}`}
									type="password"
									id="confirm-password-input"
									name="confirmPassword"
									onChange={form.handleChange}
									onBlur={form.handleBlur}
									value={form.values.confirmPassword ? form.values.confirmPassword : ""}
								/>
								{form.touched.confirmPassword && form.errors.confirmPassword ? (
									<div className="input-errors">{form.errors.confirmPassword}</div>
								) : null}
							</div>

							<button type="submit" className="btn btn-primary" id="save-button">
								{saving ? (
									<div className="text-center spinner-border text-light" role="status"></div>
								) : (
									"Save"
								)}
							</button>
						</fieldset>
					</form>
				</div>
			</div>
		</Fragment>
	)
}

export default UserProfile
