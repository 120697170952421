import React, { useState } from "react"

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

function FilterLookup({ filters, addFilter }) {
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const toggle = () => setDropdownOpen((prevState) => !prevState)

	function Filters() {
		return filters.map((f) =>
			f.field ? (
				<DropdownItem onClick={() => addFilter(f)} key={f.field}>
					{f.label}
				</DropdownItem>
			) : null,
		)
	}

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Add Filter</DropdownToggle>
			<DropdownMenu>
				<DropdownItem header>Select Filter</DropdownItem>
				<Filters />
			</DropdownMenu>
		</Dropdown>
	)
}

export default FilterLookup
