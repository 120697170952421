import React from "react"
import { useTable, useSortBy } from "react-table"
import "Pages/RateSheets/Page.scss"
import { LaneRateTableHeaders } from "Pages/TableHeaders"

export interface IProps {
	quotes
	state?
}

const QuoteTable: React.FC<IProps> = ({ quotes, state }) => {
	const columns = React.useMemo(() => LaneRateTableHeaders(), [])
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data: quotes,
			initialState: state,
		},
		useSortBy,
	)

	return (
		<table {...getTableProps()} className={"table table-bordered"}>
			<thead className={"thead-light"}>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())}>
								{column.render("Header")}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
					prepareRow(row)
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => (
								<td className="table-td" {...cell.getCellProps()}>
									{cell.render("Cell")}
								</td>
							))}
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}

export default QuoteTable
