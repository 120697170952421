import React, { Fragment } from "react"

export interface IProps {
	rateTable
	bafTable
	updateTable: Function
	base: boolean
}

const RateTable: React.FC<IProps> = ({ rateTable, bafTable, updateTable, base }) => {
	function handleChange(event: any, key: string, index: number) {
		updateTable(event, key, index)
	}
	return (
		<Fragment>
			<table className="table table-bordered table-sm rate-table">
				<thead className="thead-light">
					<tr className="text-center">
						<th scope="col"></th>
						<th scope="col">20&apos;</th>
						<th scope="col">40&apos;</th>
						<th scope="col">40&apos;HC</th>
						<th scope="col">45&apos;</th>
					</tr>
				</thead>
				<tbody>
					{rateTable.map((row, index) => (
						<tr key={row.listId}>
							{Object.keys(row).map((key: any) =>
								key !== "listId" ? (
									key === "routing" ? (
										<th className="text-center align-middle" scope="row" key={key}>
											{row.routing}
										</th>
									) : (
										<td key={key}>
											{bafTable ? (
												<div className="input-group" id="ec-group" hidden={!base}>
													<div className="input-group-prepend">
														<div className="input-group-text">$</div>
													</div>
													<input
														className="form-control"
														disabled={true}
														name={key}
														onChange={null}
														pattern="[0-9]*"
														type="text"
														value={bafTable[index][key]}
													></input>
												</div>
											) : null}
											<div className="input-group" id="ec-group">
												<div className="input-group-prepend">
													<div className="input-group-text">$</div>
												</div>
												<input
													autoComplete="off"
													className="form-control"
													name={key}
													onChange={(event) => {
														handleChange(event, key, index)
													}}
													placeholder="0"
													value={row[key]}
												></input>
											</div>
										</td>
									)
								) : null,
							)}
						</tr>
					))}
				</tbody>
			</table>
		</Fragment>
	)
}

export default RateTable
