import React, { Fragment, FC, useState } from "react"
import "../AdminConsole.scss"
import { Formik } from "formik"
import useAdminService from "Services/AdminService"

interface IProps {
	userContext: any
	context: any
	toggleModal: Function
}

const userRoles = ["Admin", "User", "Viewer"]

const UsersForm: FC<IProps> = ({ userContext, context, toggleModal }) => {
	const [saving, setSaving] = useState<boolean>(false)

	const adminService = useAdminService()

	const validate = (values) => {
		const errors = {} as any

		if (!values.firstName) {
			errors.firstName = "Required"
		}

		if (!values.lastName) {
			errors.lastName = "Required"
		}

		if (values.role === "-- Select Role --") {
			errors.role = "Required"
		}

		if (!values.username) {
			errors.username = "Required"
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
			errors.username = "Invalid email address"
		}

		if (!values.password && !values.id) {
			errors.password = "Required"
		}

		if (values.password && values.password.length) {
			if (values.password.length < 6) {
				errors.password = "Password must be at least 6 characters!"
			}
		}

		return errors
	}

	return (
		<Formik
			initialValues={{
				id: userContext ? userContext.id : undefined,
				firstName: userContext ? userContext.firstName : "",
				lastName: userContext ? userContext.lastName : "",
				username: userContext ? userContext.username : "",
				role: userContext ? userContext.role : "",
				password: "",
				disabled: userContext ? userContext.disabled : false,
			}}
			validate={validate}
			enableReinitialize
			onSubmit={async (values) => {
				setSaving(true)
				if (values.id) {
					let userObj: any
					if (values.password === "") {
						userObj = {
							id: values.id,
							firstName: values.firstName,
							lastName: values.lastName,
							username: values.username,
							disabled: values.disabled,
							role: values.role,
						}
					} else {
						userObj = {
							id: values.id,
							firstName: values.firstName,
							lastName: values.lastName,
							username: values.username,
							password: values.password,
							disabled: values.disabled,
							role: values.role,
						}
					}
					adminService.updateUser(userObj).then(() => {
						context.fetchAdminTableFn(context.tableName)
						toggleModal()
					})
				} else {
					adminService
						.addUser({
							id: undefined,
							firstName: values.firstName,
							lastName: values.lastName,
							username: values.username,
							password: values.password,
							disabled: values.disabled,
							role: values.role,
						})
						.then(() => {
							context.fetchAdminTableFn(context.tableName)
							toggleModal()
						})
				}
				setSaving(false)
			}}
			onReset={() => {
				toggleModal()
			}}
		>
			{(props) => {
				const { values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset } =
					props
				return (
					<Fragment>
						<fieldset disabled={saving}>
							<form onSubmit={handleSubmit} onReset={handleReset}>
								<h1>{!values.id ? "New User" : "Edit User"}</h1>
								<div className="form-col">
									<div className="form-group">
										<label>First Name *</label>
										<input
											autoFocus
											type="text"
											name="firstName"
											className={`form-control ${
												touched.firstName && errors.firstName ? "has-error" : null
											}`}
											placeholder="First Name"
											disabled={values.disabled}
											value={values.firstName}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{touched.firstName && errors.firstName ? (
											<small className="form-errors">{errors.firstName}</small>
										) : null}
									</div>
									<div className="form-group">
										<label>Last Name *</label>
										<input
											type="text"
											name="lastName"
											className={`form-control ${
												touched.lastName && errors.lastName ? "has-error" : null
											}`}
											placeholder="Last Name"
											disabled={values.disabled}
											value={values.lastName}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{touched.lastName && errors.lastName ? (
											<small className="form-errors">{errors.lastName}</small>
										) : null}
									</div>
									<div className="form-group">
										<label>Username *</label>
										<input
											type="email"
											name="username"
											className={`form-control ${
												touched.username && errors.username ? "has-error" : null
											}`}
											placeholder="Email"
											disabled={values.disabled || values.id}
											value={values.username}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{touched.username && errors.username ? (
											<small className="form-errors">{errors.username}</small>
										) : null}
									</div>
									<div className="form-group">
										<label>Role *</label>
										<select
											name="role"
											className="form-control"
											onChange={handleChange}
											value={values.role}
										>
											<option value="" disabled>
												-- Select Role --
											</option>
											{userRoles.map((r, index) => (
												<option key={index} value={r}>
													{r}
												</option>
											))}
										</select>
										{touched.role && errors.role ? (
											<small className="form-errors">{errors.username}</small>
										) : null}
									</div>
									<div className="form-group">
										<label>{`Password ${!values.id ? "*" : ""}`} </label>
										<div className="input-group password-field">
											<input
												type="password"
												name="password"
												className={`form-control ${
													touched.password && errors.password ? "has-error" : null
												}`}
												placeholder="Password"
												disabled={values.disabled}
												value={values.password}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											<div className="input-group-append">
												<span className="input-group-text">
													<i className="fa fa-key"></i>
												</span>
											</div>
										</div>
										{touched.password && errors.password ? (
											<small className="form-errors">{errors.password}</small>
										) : null}
									</div>
									<div className="form-group" hidden={!values.id}>
										<div className="form-check">
											<input
												type="checkbox"
												name="disabled"
												className="form-check-input"
												checked={values.disabled}
												value={values.disabled}
												onChange={handleChange}
											/>
											<label className="form-check-label">Disabled</label>
										</div>
									</div>
								</div>
								<div className="form-actions">
									<button type="submit" className="btn btn-primary">
										SAVE
									</button>
									<button type="reset" className="btn btn-danger" onClick={handleReset}>
										CANCEL
									</button>
								</div>
							</form>
						</fieldset>
					</Fragment>
				)
			}}
		</Formik>
	)
}
export default UsersForm
