import React from "react"

export function TableWrapper({ children, tableLabel }) {
	return (
		<div className={"t-table-wrapper"}>
			<p className={"table-label"}>{tableLabel}</p>
			<div className={"table-overflow-wrapper"}>{children}</div>
		</div>
	)
}

export default TableWrapper
