import { useContext } from "react"
import ApiContext from "Contexts/API/Context"
import axios from "axios"

const useDashboardService = () => {
	const apiContext = useContext(ApiContext)
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const API = {
		getExpiringRateWidgetData: () => {
			return apiContext.get("dash-widgets/expiring-rates", source.token)
		},
		getExpiredRateWidgetData: () => {
			return apiContext.get("dash-widgets/expired-rates", source.token)
		},
		getGriGrdDrafts: () => {
			return apiContext.get("dash-widgets/gri-grd-drafts", source.token)
		},
		getDraftPreview: (draftId: number) => {
			return apiContext.get("gri-grd/preview/from-draft/" + draftId, source.token)
		},
		deleteDraft: (draftId: number) => {
			return apiContext.del("gri-grd/draft/" + draftId, source.token)
		},
		abortRequests: () => {
			source.cancel("Operation canceled by user.")
		},
	}

	return API
}

export default useDashboardService
