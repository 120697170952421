import React, { Fragment, FC, useState } from "react"

interface IProps {
	contact: any
	rowIndex: any
	newEntry: boolean
	selectedIndex: any
	errors: any
	positions: any
	removeFn: Function
	updateFn: Function
	selectedIndexFn: Function
	validateFieldFn: Function
}

const InternalContactsCell: FC<IProps> = ({
	contact,
	rowIndex,
	newEntry,
	selectedIndex,
	updateFn,
	removeFn,
	selectedIndexFn,
	validateFieldFn,
	errors,
	positions,
}) => {
	const [rowData, setRowData] = useState({
		id: undefined,
		name: "",
		email: "",
		position: "",
	})

	const editRow = (rec: any, index) => {
		selectedIndexFn(index)
		setRowData(rec)
	}

	const inputHandler = (event: any) => {
		const name = event.target.name
		let value = event.target.value
		if (event.target.name === "email") {
			validateFieldFn(value)
			value = value.toLowerCase()
		}
		updateInput(name, value)
	}

	const updateInput = (key: string, data: string) => {
		const temp = { ...rowData, [key]: data }
		setRowData(temp)
	}

	return (
		<Fragment>
			<tr>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input
							type="text"
							value={rowData.name}
							name="name"
							onChange={(event) => inputHandler(event)}
						/>
					) : (
						contact.name
					)}
				</td>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<input
							type="text"
							value={rowData.email}
							name="email"
							className={errors ? "cell-error" : null}
							onChange={(event) => inputHandler(event)}
						/>
					) : (
						contact.email
					)}
				</td>
				<td>
					{" "}
					{selectedIndex === rowIndex ? (
						<select
							name="position"
							value={rowData.position}
							className="form-control"
							onChange={(event) => inputHandler(event)}
						>
							<option>-- Select Position --</option>
							{positions.map((opt, index) => (
								<option key={index} value={opt.value}>
									{opt.text}
								</option>
							))}
						</select>
					) : (
						contact.position
					)}
				</td>
				<td>
					<div className="action-cell">
						<button
							type="button"
							disabled={
								rowData.name === "" ||
								rowData.email === "" ||
								rowData.position === "" ||
								errors !== ""
							}
							hidden={!(selectedIndex === rowIndex)}
							className="save-btn"
							onClick={() => updateFn(rowData)}
						>
							<i className="fa fa-floppy-o"></i>
						</button>
						<button
							type="button"
							hidden={!(selectedIndex === rowIndex)}
							className="trash-cancel-btn"
							onClick={() => selectedIndexFn(-1)}
						>
							<i className="fa fa-ban"></i>
						</button>
						<button
							type="button"
							disabled={newEntry}
							hidden={selectedIndex === rowIndex}
							className="edit-btn"
							onClick={() => editRow(contact, rowIndex)}
						>
							<i className="fa fa-pencil"></i>
						</button>
						<button
							type="button"
							disabled={newEntry}
							hidden={selectedIndex === rowIndex}
							className="trash-cancel-btn"
							onClick={() => removeFn(contact)}
						>
							<i className="fa fa-trash"></i>
						</button>
					</div>
				</td>
			</tr>
		</Fragment>
	)
}

export default InternalContactsCell
