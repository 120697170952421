import React from "react"
import { useContext } from "react"
import UserContext from "../Contexts/User/Context"
import { Navigate } from "react-router"

function ProtectedComponent({ component: Component, ...props }) {
	const user = useContext(UserContext)

	let comp = <Component {...props} />
	if (!user.loggedIn) comp = <Navigate to="/login" />

	return comp
}

export default ProtectedComponent
