import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import "./ClientProfile.scss"
import ClientInfo from "./ClientInfo/ClientInfo"
import ClientContacts from "./Contacts/ExternalContacts"
import InternalContacts from "./Contacts/InternalContacts"

import ClientProfileContext from "./ClientProfileContext"
import ClientProfileProvider from "./ClientProfileProvider"
import ExternalNotes from "./Notes/ExternalNotes"
import InternalNotes from "./Notes/InternalNotes"
import Modal from "Shared/Modal/Modal"
import RateSheetGenerator from "Shared/RateSheet/GenerateRateSheet"
import FilterSelect from "Shared/Inputs/FilterSelect/FilterSelect"
import Tabs from "Shared/Tabs/Tabs"
import AccessorialPicker from "./AccessorialPicker/AccessorialPicker"

export interface IClientProfile {
	id: undefined | number
	name: string
	country: string
	city: string
	state: string
	address: string
	zipCode: string
	logo: any
	cwOrgCode: string
	marginMin: number
	marginMax: number
	primaryAgent: string
	genCommodity: string
	rateStructure: string
	contacts: {
		internal: Contact[]
		external: Contact[]
	}
	accessorials: any[]
	notes: Note[]
	prospective: boolean
	prospective_margin: number
	email_frequency: string
}

export interface Contact {
	name: string
	email: string
	position?: string
	type?: string
	rateSheet?: boolean
}

export interface Note {
	date: Date
	comment: string
}

export interface Accessorial {
	id: number
	isDefault: boolean
	name: string
	amount: string
	per: string
	isVisible: boolean
	custom?: string
}

function ClientProfile({ setHeaderTitle }) {
	useEffect(() => {
		setHeaderTitle("Client Profile")
	}, [setHeaderTitle])

	const tabs = [{ label: "General" }, { label: "Contacts" }, { label: "Notes" }]
	const [activeTab, setActiveTab] = useState(tabs[0])
	const [showErrors, setShowErrors] = useState(false)

	function handleTabChange(tab) {
		setActiveTab(tab)
	}

	function confirmModal(context) {
		return (
			context.confirm && (
				<Modal dismiss={() => context.toggleConfirmModal()}>
					<div className="confirm-modal">
						<h2>
							WARNING <i className="fa fa-exclamation"></i>
						</h2>
						<p>
							Deleting the profile for {context.client.name} will also remove any rates that are
							associated with this client. Do you wish to continue?
						</p>
						<span>
							<button
								type="button"
								className="btn btn-danger"
								onClick={() => {
									context.removeClientProfileFn(context.client.id)
								}}
							>
								YES
							</button>
							<button
								type="button"
								className="btn btn-secondary"
								onClick={() => {
									context.toggleConfirmModal()
								}}
							>
								NO
							</button>
						</span>
					</div>
				</Modal>
			)
		)
	}

	return (
		<ClientProfileProvider>
			<ClientProfileContext.Consumer>
				{(context) => (
					<div className="client-profile">
						<Helmet>
							<title>Client Profile | UWL Pricing Tool</title>
						</Helmet>
						<div id="profile-header" className="container">
							<div className="row justify-content-end" style={{ padding: "0 10px" }}>
								<FilterSelect
									placholder={"Client Search"}
									suggestions={context.clients}
									selectFn={context.select}
									disabled={!context.clients || context.clients.length === 0}
								/>
							</div>
							<div className="row justify-content-between">
								<div className="align-self-end">
									{context.client.id ? (
										context.client.logo ? (
											<img alt="" src={context.client.logo} />
										) : (
											<h3>{context.client.name}</h3>
										)
									) : (
										<h3>New Profile</h3>
									)}
								</div>
								<div className="align-self-end">
									<AccessorialPicker
										saveFn={(accessorials) => context.updateClientFn("accessorials", accessorials)}
										clientAccessorials={context.client.accessorials}
										masterAccessorials={context.masterAccessorials}
									/>
									{context.client.id && (
										<button
											type="button"
											className="btn btn-primary"
											onClick={() => context.clientSellRateFn()}
										>
											{" "}
											Client Sell Rates{" "}
										</button>
									)}
									{context.client.id && <RateSheetGenerator clientID={context.client.id} />}
								</div>
							</div>
						</div>
						<div className="card" style={{ margin: "0 22px" }}>
							<div className="card-body">
								<Tabs tabs={tabs} activeTab={activeTab} changeTab={handleTabChange} />
								<div>
									{activeTab.label === "General" && (
										<section className="panel">
											<ClientInfo clientContext={context} />
										</section>
									)}
									{activeTab.label === "Contacts" && (
										<section className="panel">
											<ClientContacts clientContext={context} />
											<br></br> <br></br>
											<InternalContacts clientContext={context} />
										</section>
									)}
									{activeTab.label === "Notes" && (
										<section className="panel">
											<ExternalNotes clientContext={context} />
											<br></br> <br></br>
											<InternalNotes clientContext={context} />
										</section>
									)}
								</div>
							</div>
						</div>
						{showErrors && context.errorList.length > 0 ? (
							<div className="errorList">{context.errorList.join(", ")}</div>
						) : (
							<br></br>
						)}
						<br></br>
						<div className="client-action-btns">
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => context.isValid && context.saveClientProfileFn()}
								onMouseEnter={() => setShowErrors(true)}
								onMouseLeave={() => setShowErrors(false)}
							>
								{" "}
								Save Profile{" "}
							</button>
							{context.client.id && (
								<button
									type="button"
									className="btn btn-danger"
									onClick={() => context.toggleConfirmModal()}
								>
									{" "}
									Delete Profile{" "}
								</button>
							)}
						</div>
						{confirmModal(context)}
					</div>
				)}
			</ClientProfileContext.Consumer>
		</ClientProfileProvider>
	)
}

export default ClientProfile
