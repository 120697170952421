import React, { useState, useEffect, useRef } from "react"
import { ValueTypePair } from "../../Models/ValueTypePair"

import "./FilterSelect.scss"

interface IProps {
	label?: string
	placholder?: string
	suggestions: ValueTypePair[]
	selectFn: any
	disabled?: boolean
	value?: number
}

const defaultState: { userInput: string; showSuggestions: boolean; filteredSuggestions: any[] } = {
	userInput: "",
	showSuggestions: false,
	filteredSuggestions: [],
}

const FilterSelect: React.FC<IProps> = ({
	label,
	placholder,
	suggestions,
	selectFn,
	disabled,
	value = null,
}) => {
	const liRefMap = {}
	const optionsRef = useRef(null)
	const [state, setState] = useState(defaultState)
	const [clientState, setClientState] = useState({
		clients: [],
		cursor: 0,
		showOptions: false,
	})

	useEffect(() => {
		if (value && suggestions) {
			const client = suggestions.find((c) => c.value.id === value)
			if (client)
				setState((s) => {
					return { ...s, userInput: client.value.name }
				})
		}
	}, [suggestions, value])

	const handleUserInput = (userInput: string) => {
		if (userInput.length) {
			const filteredSuggestions = [...suggestions].filter((s: any) =>
				s.type.toLowerCase().includes(userInput.toLowerCase()),
			)
			setState({
				...state,
				showSuggestions: true,
				filteredSuggestions: filteredSuggestions,
				userInput: userInput,
			})
			setClientState({
				clients: filteredSuggestions,
				cursor: 0,
				showOptions: true,
			})
		} else {
			setState({
				...state,
				showSuggestions: false,
				filteredSuggestions: suggestions,
				userInput: userInput,
			})
		}
	}

	const selectSuggestion = (value: any) => {
		selectFn(value)
		setState({ ...state, showSuggestions: false })
		setClientState({
			clients: [],
			cursor: 0,
			showOptions: false,
		})
	}

	const onBlur = () => {
		setState({ ...state, showSuggestions: false, userInput: "" })
	}

	function handleKeyPress(e) {
		if (!clientState.showOptions || !clientState.clients.length) return

		if (e.key === "ArrowUp") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			let updatedCursor = clientState.cursor - 1
			if (updatedCursor > clientState.clients.length - 1) updatedCursor = 0
			let scrollTo = 0
			for (let i = 0; i < updatedCursor; i++) {
				scrollTo += liRefMap[clientState.clients[i].type].offsetHeight
			}
			optionsRef.current.scrollTo(0, scrollTo)
			setClientState({ ...clientState, cursor: updatedCursor })
		}

		if (e.key === "ArrowDown") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			let updatedCursor = clientState.cursor + 1
			if (updatedCursor > clientState.clients.length - 1) updatedCursor = 0
			let scrollTo = 0
			for (let i = 0; i < updatedCursor; i++) {
				scrollTo += liRefMap[clientState.clients[i].type].offsetHeight
			}
			optionsRef.current.scrollTo(0, scrollTo)
			setClientState({ ...clientState, cursor: updatedCursor })
		}

		if (e.key === "Enter") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			const value = clientState.clients[clientState.cursor]
			if (value) selectSuggestion(value.value)
			setState({ ...state, showSuggestions: false, filteredSuggestions: [], userInput: "" })
		}

		if (e.key === "Escape") {
			setClientState({
				clients: [],
				cursor: 0,
				showOptions: false,
			})
			setState({ ...state, showSuggestions: false, filteredSuggestions: [], userInput: "" })
		}
	}

	const suggestionsView = () => {
		return (
			<div className="port-search-wrapper">
				{clientState.clients.length > 0 && (
					<ul ref={optionsRef}>
						{clientState.clients.map((o, i) => (
							<li
								ref={(el) => (liRefMap[o.type] = el)}
								key={o.type}
								className={clientState.cursor === i ? "highlighted" : ""}
								onMouseDown={() => selectSuggestion(o.value)}
							>
								{o.type}
							</li>
						))}
					</ul>
				)}
			</div>
		)
	}

	return (
		<div className="client-lookup-filter-select-wrapper">
			{label ? <label className="filter-select-label">{label}</label> : null}
			<div className="filter-select-input-wrapper">
				<input
					type="text"
					className="form-control"
					onBlur={() => onBlur()}
					onKeyDown={(e) => handleKeyPress(e)}
					placeholder={placholder ? placholder : ""}
					value={state.userInput}
					onChange={(e) => handleUserInput(e.target.value)}
					autoComplete="off"
					disabled={disabled !== undefined ? disabled : false}
				></input>
			</div>
			{state.showSuggestions ? suggestionsView() : null}
		</div>
	)
}

export default FilterSelect
