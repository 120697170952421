import React, { Fragment, useState, useEffect, useRef } from "react"
import {
	IInclClientID,
	IExclClientID,
	IInclClientName,
	IExclClientName,
} from "../GRI-GRD/CreateGRIGRD"
import "./ClientLookup.scss"
import useControlTowerService from "Services/ControlTowerService"

export interface IProps {
	inclClientID: IInclClientID
	exclClientID: IExclClientID
	inclClientName: IInclClientName
	exclClientName: IExclClientName
	setClientName: Function
	updateClient: Function
	disable
	index
	type
}

const defaultState: { userInput: string; showSuggestions: boolean; filteredSuggestions: any[] } = {
	userInput: "",
	showSuggestions: false,
	filteredSuggestions: [],
}

const ClientLookup: React.FC<IProps> = ({
	inclClientID,
	exclClientID,
	inclClientName,
	exclClientName,
	setClientName,
	updateClient,
	disable,
	index,
	type,
}) => {
	const liRefMap = {}
	const optionsRef = useRef(null)
	const [state, setState] = useState(defaultState)
	const [clients, setClients] = useState([])
	const [clientState, setClientState] = useState({
		clients: [],
		cursor: 0,
		showOptions: false,
	})

	const controlTowerService = useControlTowerService()

	useEffect(() => {
		getClientList()
		// eslint-disable-next-line
	}, [])

	const getClientList = async () => {
		const clientList = await controlTowerService.getClients()
		setClients(clientList)
	}

	const handleInput = async (event: any) => {
		const value = event.target.value
		value.toLowerCase()
		if (value.length) {
			const filteredSuggestions = [...clients].filter((s: any) =>
				s.type.toLowerCase().includes(value),
			)
			setState({ ...state, filteredSuggestions: filteredSuggestions })
			setClientState({
				clients: filteredSuggestions,
				cursor: 0,
				showOptions: true,
			})
			if (type === "inclusion") {
				const status = Object.assign({}, inclClientName)
				status.inclusionClients[index].show = true
				setClientName(status, type)
				const status1 = Object.assign({}, inclClientName)
				status1.inclusionClients[index].client = value
				setClientName(status1, type)
			} else {
				const status = Object.assign({}, exclClientName)
				status.exclusionClients[index].show = true
				setClientName(status, type)
				const status1 = Object.assign({}, exclClientName)
				status1.exclusionClients[index].client = value
				setClientName(status1, type)
			}
		} else {
			setState({ ...state, filteredSuggestions: clients })
			if (type === "inclusion") {
				const status = Object.assign({}, inclClientName)
				status.inclusionClients[index].show = false
				setClientName(status, type)
				const status1 = Object.assign({}, inclClientName)
				status1.inclusionClients[index].client = value
				setClientName(status1, type)
				const emptyStatusId = Object.assign({}, inclClientID)
				emptyStatusId.inclusions[index].id = value
				updateClient(emptyStatusId, type)
				const emptyStatusName = Object.assign({}, inclClientName)
				emptyStatusName.inclusionClients[index].client = value
				setClientName(emptyStatusName, type)
			} else {
				const status = Object.assign({}, exclClientName)
				status.exclusionClients[index].show = false
				setClientName(status, type)
				const status1 = Object.assign({}, exclClientName)
				status1.exclusionClients[index].client = value
				setClientName(status1, type)
			}
		}
	}
	function handleToggle(event: any) {
		const value = event.target.value
		if (type === "inclusion") {
			const status = Object.assign({}, inclClientID)
			status.inclusions[index].rateType = value
			updateClient(status, type)
		} else {
			const status = Object.assign({}, exclClientID)
			status.exclusions[index].rateType = value
			updateClient(status, type)
		}
	}

	function onBlur() {
		setClientState({
			clients: [],
			cursor: 0,
			showOptions: false,
		})
	}

	function handleKeyPress(e) {
		if (!clientState.showOptions || !clientState.clients.length) return

		if (e.key === "ArrowUp") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			let updatedCursor = clientState.cursor - 1
			if (updatedCursor > clientState.clients.length - 1) updatedCursor = 0
			let scrollTo = 0
			for (let i = 0; i < updatedCursor; i++) {
				scrollTo += liRefMap[clientState.clients[i].type].offsetHeight
			}
			optionsRef.current.scrollTo(0, scrollTo)
			setClientState({ ...clientState, cursor: updatedCursor })
		}

		if (e.key === "ArrowDown") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			let updatedCursor = clientState.cursor + 1
			if (updatedCursor > clientState.clients.length - 1) updatedCursor = 0
			let scrollTo = 0
			for (let i = 0; i < updatedCursor; i++) {
				scrollTo += liRefMap[clientState.clients[i].type].offsetHeight
			}
			optionsRef.current.scrollTo(0, scrollTo)
			setClientState({ ...clientState, cursor: updatedCursor })
		}

		if (e.key === "Enter") {
			e.preventDefault()
			e.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
			const value = clientState.clients[clientState.cursor]
			if (value) {
				updateCustomer(value.value)
			}
		}

		if (e.key === "Escape") {
			setClientState({
				clients: [],
				cursor: 0,
				showOptions: false,
			})
		}
	}

	function updateCustomer(customer: any) {
		if (type === "inclusion") {
			const status = Object.assign({}, inclClientID)
			status.inclusions[index].id = customer.id
			updateClient(status, type)
			const clientName = Object.assign({}, inclClientName)
			clientName.inclusionClients[index].client = customer.name
			setClientName(clientName, type)
		} else {
			const status = Object.assign({}, exclClientID)
			status.exclusions[index].id = customer.id
			updateClient(status, type)
			const clientName = Object.assign({}, exclClientName)
			clientName.exclusionClients[index].client = customer.name
			setClientName(clientName, type)
		}
		setClientState({
			clients: [],
			cursor: 0,
			showOptions: false,
		})
	}
	const suggestionsView = () => {
		return (
			<div className="port-search-wrapper">
				{clientState.clients.length > 0 && (
					<ul ref={optionsRef}>
						{clientState.clients.map((o, i) => (
							<li
								ref={(el) => (liRefMap[o.type] = el)}
								key={o.type}
								className={clientState.cursor === i ? "highlighted" : ""}
								onMouseDown={() => updateCustomer(o.value)}
							>
								{o.type}
							</li>
						))}
					</ul>
				)}
			</div>
		)
	}

	return (
		<Fragment>
			{type === "inclusion" ? (
				<Fragment>
					<div className="client-lookup-filter-select-wrapper">
						<div className="filter-select-input-wrapper">
							<input
								autoComplete="off"
								className="form-control control-inputs"
								name="inclusionCustomer"
								onBlur={() => onBlur()}
								onChange={(event) => handleInput(event)}
								onKeyDown={handleKeyPress}
								placeholder="Customer Inclusion"
								value={inclClientName.inclusionClients[index].client}
							/>
						</div>
						{inclClientName.inclusionClients[index].show ? suggestionsView() : null}
					</div>
					<div className="form-check-inline">
						<input
							checked={inclClientID.inclusions[index].rateType === "Fixed"}
							className="form-check-input"
							name={"inclusionRateStructure" + index}
							onChange={(event) => handleToggle(event)}
							type="radio"
							value="Fixed"
						/>
						<label className="form-check-label">Fixed</label>
					</div>
					<div className="form-check-inline">
						<input
							checked={inclClientID.inclusions[index].rateType === "Variable"}
							className="form-check-input"
							name={"inclusionRateStructure" + index}
							onChange={(event) => handleToggle(event)}
							type="radio"
							value="Variable"
						/>
						<label className="form-check-label">Variable</label>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<div className="client-lookup-filter-select-wrapper">
						<div className="filter-select-input-wrapper">
							<input
								autoComplete="off"
								className="form-control control-inputs"
								name="exclusionCustomer"
								onBlur={() => onBlur()}
								onChange={(event) => handleInput(event)}
								onKeyDown={handleKeyPress}
								placeholder="Customer Exclusion"
								value={exclClientName.exclusionClients[index].client}
							/>
						</div>
						{exclClientName.exclusionClients[index].show ? suggestionsView() : null}
					</div>
					<fieldset disabled={disable}>
						<div className="form-check-inline">
							<input
								checked={exclClientID.exclusions[index].rateType === "Fixed"}
								className="form-check-input"
								name={"exclusionRateStructure" + index}
								onChange={(event) => handleToggle(event)}
								type="radio"
								value="Fixed"
							/>
							<label className="form-check-label">Fixed</label>
						</div>
						<div className="form-check-inline">
							<input
								checked={exclClientID.exclusions[index].rateType === "Variable"}
								className="form-check-input"
								name={"exclusionRateStructure" + index}
								onChange={(event) => handleToggle(event)}
								type="radio"
								value="Variable"
							/>
							<label className="form-check-label">Variable</label>
						</div>
					</fieldset>
				</Fragment>
			)}
		</Fragment>
	)
}

export default ClientLookup
