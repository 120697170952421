import React, { useContext, useEffect } from "react"
import Helmet from "react-helmet"
import AdminConsoleContext from "./AdminConsoleContext"
import AdminConsoleProvider from "./AdminConsoleProvider"
import AdminAccessorialsTable from "./AdminAccessoraials/AdminAccessorialsTable"
import AdminTable from "./AdminTables/AdminTable"
import AdminCWTable from "./AdminTables/AdminCWTable"
import UsersTable from "../../Pages/Admin/Users/UsersTable"
import RateSheetContent from "./RateSheet/RateSheet"
import LoadingIndicator from "Shared/LoadingIndicator/LoadingIndicator"
import PortGroups from "./PortGroups/PortGroups"
import BuyRatePorts from "./BuyRatePorts/BuyRatePortsTable"
import FuelSurcharge from "./DrayBuyRates/FuelSurcharge"
import ProspectiveMessage from "./ProspectiveMessage/ProspectiveMessage"
import UserContext from "../../Contexts/User/Context"
import InternalContactsTable from "./InternalContacts/InternalContactsTable"
import { AdminCarriersTable } from "./AdminTables/AdminCarriersTable"
import { AdminCommoditiesTable } from "./AdminTables/AdminCommoditiesTable"

function AdminConsole({ setHeaderTitle }) {
	const user = useContext(UserContext)

	let adminTables = []
	if (user && user.details && user.details.role === "Admin") {
		adminTables = [
			"Accessorials",
			"Agents",
			"Buy Rate Ports",
			"Carriers",
			"Commodities",
			"Contact Positions",
			"Countries",
			"Dray Buy Rate Fuel Surcharge",
			"Internal Contacts",
			"Ports",
			"Prospective Email Message",
			"Rate Sheet Content",
			"Unloco Override",
			"Users",
			"Inco Terms",
			"Trades",
			"Service Type",
			"Routing",
		]
	}
	if (user && user.details && user.details.role === "User") {
		adminTables = ["Users"]
	}
	const specialTables = [
		"Agents",
		"Countries",
		"Accessorials",
		"Users",
		"Rate Sheet Content",
		"Ports",
		"Buy Rate Ports",
		"Dray Buy Rate Fuel Surcharge",
		"Prospective Email Message",
		"Internal Contacts",
		"Carriers",
		"Inco Terms",
		"Commodities",
		"Trades",
		"Service Type",
		"Routing",
	]

	useEffect(() => {
		setHeaderTitle("Admin Console")
	}, [setHeaderTitle])

	return (
		<AdminConsoleProvider>
			<AdminConsoleContext.Consumer>
				{(context) => (
					<div className={"card"}>
						<div className={"card-body"}>
							<Helmet>
								<title>Admin Console | UWL Pricing Tool</title>
							</Helmet>
							<LoadingIndicator />
							<select
								name="adminTable"
								className="form-control table-select"
								onChange={context.selectedTableFn}
								defaultValue="-- Select Option --"
							>
								<option disabled>-- Select Option --</option>
								{adminTables.map((opt, index) => (
									<option key={index} value={opt}>
										{opt}
									</option>
								))}
							</select>
							{context.tableName !== "" && (
								<div>
									{context.tableName === "Dray Buy Rate Fuel Surcharge" && (
										<FuelSurcharge context={context} />
									)}
									{context.tableName === "Prospective Email Message" && (
										<ProspectiveMessage context={context} />
									)}
									{context.tableName === "Buy Rate Ports" && <BuyRatePorts context={context} />}
									{context.tableName === "Ports" && <PortGroups context={context} />}
									{context.tableName === "Rate Sheet Content" && (
										<RateSheetContent context={context} />
									)}
									{context.tableName === "Users" && <UsersTable context={context} />}
									{context.tableName === "Accessorials" && (
										<AdminAccessorialsTable context={context} />
									)}
									{context.tableName === "Internal Contacts" && (
										<InternalContactsTable context={context} />
									)}
									{(context.tableName === "Agents" || context.tableName === "Countries") && (
										<AdminCWTable context={context} />
									)}
									{context.tableName === "Carriers" && <AdminCarriersTable context={context} />}
									{context.tableName === "Inco Terms" && (
										<AdminTable
											context={context}
											textColumnName="Description"
											valueColumnName="Inco Term"
										/>
									)}
									{context.tableName === "Commodities" && (
										<AdminCommoditiesTable context={context} />
									)}
									{context.tableName === "Trades" && (
										<AdminTable
											context={context}
											textColumnName="Trade Description"
											valueColumnName="Trade Name"
										/>
									)}
									{context.tableName === "Service Type" && (
										<AdminTable
											context={context}
											textColumnName="Description"
											valueColumnName="CW Name"
										/>
									)}
									{context.tableName === "Routing" && (
										<AdminTable
											context={context}
											textColumnName="Description"
											valueColumnName="CW Name"
										/>
									)}
									{!specialTables.includes(context.tableName) && <AdminTable context={context} />}
								</div>
							)}
						</div>
					</div>
				)}
			</AdminConsoleContext.Consumer>
		</AdminConsoleProvider>
	)
}

export default AdminConsole
