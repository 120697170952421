import React, { HTMLProps } from "react"
import { Column, Table, sortingFns, FilterFn, SortingFn, ColumnDef } from "@tanstack/react-table"
import { rankItem, compareItems, RankingInfo } from "@tanstack/match-sorter-utils"

declare module "@tanstack/table-core" {
	interface FilterMeta {
		itemRank: RankingInfo
	}
}

export const IndeterminateCheckbox = ({
	indeterminate,
	className = "",
	...rest
}: {
	indeterminate?: boolean
} & HTMLProps<HTMLInputElement>): JSX.Element => {
	const ref = React.useRef<HTMLInputElement>(null)

	React.useEffect(() => {
		if (typeof indeterminate === "boolean") {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate])

	return <input type="checkbox" ref={ref} className={className + " cursor-pointer"} {...rest} />
}

export function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
	const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

	const columnFilterValue = column.getFilterValue()

	const sortedUniqueValues = React.useMemo(
		() =>
			typeof firstValue === "number"
				? []
				: Array.from(column.getFacetedUniqueValues().keys()).sort(),
		[column.getFacetedUniqueValues()],
	)

	return typeof firstValue === "number" ? (
		<div>
			<div className="flex space-x-2">
				<DebouncedInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
					value={(columnFilterValue as [number, number])?.[0] ?? ""}
					onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
					placeholder={`Min ${
						column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ""
					}`}
					className="w-24 border"
				/>
				<DebouncedInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
					value={(columnFilterValue as [number, number])?.[1] ?? ""}
					onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
					placeholder={`Max ${
						column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ""
					}`}
					className="w-24 border"
				/>
			</div>
			<div className="h-1" />
		</div>
	) : (
		<>
			<datalist id={column.id + "list"}>
				{sortedUniqueValues.slice(0, 5000).map((value: any) => (
					<option value={value} key={value} />
				))}
			</datalist>
			<DebouncedInput
				type="text"
				value={(columnFilterValue ?? "") as string}
				onChange={(value) => column.setFilterValue(value)}
				placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
				className="w-36 border"
				list={column.id + "list"}
			/>
			<div className="h-1" />
		</>
	)
}

// A debounced input react component
export function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}: {
	value: string | number
	onChange: (value: string | number) => void
	debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
	const [value, setValue] = React.useState(initialValue)

	React.useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value)
		}, debounce)

		return () => clearTimeout(timeout)
	}, [value])

	return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value)

	// Store the ranking info
	addMeta({ itemRank })

	// Return if the item should be filtered in/out
	return itemRank.passed
}

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
	let dir = 0

	// Only sort by rank if the column has ranking information
	if (rowA.columnFiltersMeta[columnId]) {
		dir = compareItems(
			rowA.columnFiltersMeta[columnId]?.itemRank,
			rowB.columnFiltersMeta[columnId]?.itemRank,
		)
	}

	// Provide an alphanumeric fallback for when the item ranks are equal
	return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

export const editableColumn: Partial<ColumnDef<any>> = {
	cell: ({ getValue, row: { index, getIsSelected }, column: { id }, table }) => {
		const initialValue = getValue()
		const inputType = typeof initialValue === "boolean" ? "checkbox" : "text"
		const [value, setValue] = React.useState(initialValue)
		const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (inputType === "checkbox") {
				setValue(e.target.checked)
				table.options.meta?.updateData(index, id, e.target.checked)
			} else {
				setValue(e.target.value)
				table.options.meta?.updateData(index, id, e.target.value)
			}
		}

		React.useEffect(() => {
			setValue(initialValue)
		}, [initialValue])

		if (getIsSelected()) {
			return (
				<input
					type={inputType}
					value={value ? (value as string) : ""}
					onChange={onChange}
					checked={inputType === "checkbox" ? (value as boolean) : false}
				/>
			)
		}
		return (
			<span className="text-truncate">{inputType === "text" ? value : value ? "Yes" : "No"}</span>
		)
	},
}
